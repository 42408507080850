import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chart"]

  connect() {
    Chartkick.eachChart( function(chart) {
      let options = chart.getOptions()
      options.library = {
        ...options.library,
        onClick: function(e) {
          let chart = e.chart
          let firstPoint = chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false)[0]
          if (firstPoint) {
            Turbo.visit(chart.data.labels[firstPoint.index])
          }
        },
        hover: {
          onHover: function(e, a) {
            let canvas = e.target.parentNode.querySelector('canvas')
            canvas.style.cursor = a[0] ? "pointer" : "default"
          }
        }
      }
      chart.setOptions(options)
    })
  }
}
