import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "swatch"]

  connect() {
    this.highlightSelectedColor()
  }

  highlightSelectedColor() {
    for (let swatchTarget of this.swatchTargets) {
      if (this.fieldTarget.value == swatchTarget.dataset.colorPickerColorName) {
        swatchTarget.classList.add("active")
      } else {
        swatchTarget.classList.remove("active")
      }
    }
  }

  select(event) {
    this.fieldTarget.value = event.target.dataset.colorPickerColorName
    this.highlightSelectedColor()
  }
}
