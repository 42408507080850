import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["disabled", "disabledReason"]

  connect() {
    this.toggleReasons()
  }

  toggleReasons() {
    if (this.disabledTarget.checked) {
      this.disabledReasonTarget.classList.remove('hidden');
    } else {
      this.disabledReasonTarget.classList.add('hidden');
    }
  }
}
