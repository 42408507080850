import moment from 'moment'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["duration"]
  static values = {
    epoch: Number
  }

  connect() {
    const eventTime = moment(this.epochValue * 1000);
    const duration = moment.duration(moment().diff(eventTime))
    const durationText = `(${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s)`
    this.durationTarget.textContent = durationText
  }
}
