import { Controller } from "@hotwired/stimulus";
import interact from 'interactjs'

export default class extends Controller {
  static targets = [ "resizer" ]

  connect() {
    this.installResizer()
  }

  installResizer() {
    interact(this.element).resizable({
      edges: { bottom: true },
    })
    .on('resizemove', event => {
      let { x, y } = event.target.dataset

      x = parseFloat(x) || 0
      y = parseFloat(y) || 0

      Object.assign(event.target.style, {
        height: `${event.rect.height}px`,
        transform: `translate(${event.deltaRect.left}px, ${event.deltaRect.top}px)`
      })

      Object.assign(event.target.dataset, { x, y })
    })
  }
}
