import throttle from "lodash/throttle";
import escape from "lodash/escape";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "ownersList", "ownerItem", "submitButton", "ownerField"];
  static values = {
    assignedOwnerId: Number,
    ownersUrl: String,
  }

  selectedOwnerID = "";
  initialOwners = []

  initialize() {
    this.selectedOwnerID = this.assignedOwnerIdValue
    this.initialOwners = this.ownerItemTargets.map((elem, i) => {
      return {
        owner_id: escape(elem.dataset.ownerId),
        name: escape(elem.dataset.ownerName),
        email: escape(elem.dataset.ownerEmail),
        avatar_url: escape(elem.dataset.ownerAvatarUrl),
      };
    });
  }

  onInput = throttle((event) => {
    if (this.inputTarget.value.length > 0) {
      this.searchOwners(event);
    } else {
      this.clearResults()
      this.appendResults(this.initialOwners);
    }
  }, 500)

  searchOwners(event) {
    let searchValue = encodeURIComponent(this.inputTarget.value);
    let baseURL = this.ownersUrlValue
    let url = `${baseURL}.json?q=${searchValue}&limit=5`;
    fetch(url)
      .then(response => response.json())
      .then(searchResults => {
        this.clearResults()
        if (searchResults.length) {
          this.appendResults(searchResults);
        }
      });
  }

  appendResults(results) {
    // need to reverse since forEach traverses in ascending order
    results = results.sort().reverse();
    results.forEach((res) => {
      let image = `<img class="device-assignment__owner-avatar avatar" src=${res.avatar_url} />`;
      let currentBadge = "";
      let selectedClass = "";
      if (res.owner_id == this.selectedOwnerID) {
        currentBadge =
          `<span class="js-current-badge device-assignment__current-badge">(current)</span>`;

        selectedClass = 'device-assignment__owner-item--selected';
      }

      let li =
        `<li class="device-assignment__owner-item ${selectedClass}"
             data-owner-id="${res.owner_id}"
             data-owner-name="${res.name}"
             data-owner-email="${res.email}"
             data-owner-avatar-url="${res.avatar_url}"
             data-inventory--device-assignment-target="ownerItem"
             data-action="click->inventory--device-assignment#setSelected">
             ${image}${res.name}<span class="device-assignment__owner-item--subtext">${res.email}</span>
           ${currentBadge}
         </li>`
      this.ownersListTarget.insertAdjacentHTML('beforeend', li)
    });
  }

  setSelected(event) {
    this.selectedOwnerID = event.currentTarget.dataset.ownerId;
    this.selectedOwnerName = event.currentTarget.dataset.ownerName;

    this.ownerFieldTarget.value = this.selectedOwnerID
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.value = `Assign ${this.selectedOwnerName}`

    this.ownerItemTargets.forEach(el => {
      el.classList.remove('device-assignment__owner-item--selected');
      el.querySelectorAll('span.js-current-badge').forEach(span => span.remove());
    });

    event.currentTarget.classList.add('device-assignment__owner-item--selected');
    let currentBadge = '<span class="js-current-badge device-assignment__current-badge">(current)</span>'
    event.currentTarget.insertAdjacentHTML('beforeend', currentBadge)
  }

  clearResults(){
    this.ownersListTarget.querySelectorAll("li").forEach(el => el.remove());
  }
}
