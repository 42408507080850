import { Controller } from "@hotwired/stimulus"

const LEAVING_PAGE_MESSAGE = "You have attempted to leave this page.  If you have made any changes to the fields without clicking the Save button, your changes will be lost.  Are you sure you want to exit this page?"

export default class extends Controller {
  static targets = ["submitButton", "input"];
  static values = {
    changed: Boolean
  };

  connect() {
    this.inputTargets.forEach(elm => {
      elm.dataset.origValue = this.getElementValue(elm);
    });
  }

  getElementValue(elm) {
    if (elm.type === "checkbox" || elm.type === "radio") {
      return elm.checked.toString();
    } else if (elm.value == "[]") {
      return "";
    } else {
      return elm.value;
    }
  }

  formIsChanged(event) {
    let unsavedChanges = 0;

    // loop through all elements on the form any time something is changed to
    // support radio buttons which have dependent relationships between each other
    this.inputTargets.forEach(elm => {
      if (elm.dataset.origValue == "undefined") {
        // Nothing to do, this is not a valid target
      } else if (this.getElementValue(elm) != elm.dataset.origValue) {
        unsavedChanges++
        event.target.parentNode.classList.add("changed")
      } else {
        event.target.parentNode.classList.remove("changed")
      }
    });

    if (unsavedChanges != 0) {
      this.changedValue = true
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = false
      }
    } else {
      this.changedValue = false
      if (this.hasSubmitButtonTarget) {
        this.submitButtonTarget.disabled = true
      }
    }
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbo:before-visit" || event.type == "beforeSidebarHide") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    }
  }

  allowFormSubmission() {
    this.changedValue = false
  }

  isFormChanged() {
    return this.changedValue
  }
}
