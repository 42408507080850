import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = {
    uploadUrl: String,
  }

  connect(){
    this.element.addEventListener("dragenter", this.dragEnter.bind(this));
    this.element.addEventListener("dragleave", this.dragLeave.bind(this));
    this.element.addEventListener("drop", this.drop.bind(this));
    this.element.addEventListener("paste", this.paste.bind(this));
  }

  dragEnter(e) {
    this.element.classList.add("drag-active")
  }

  dragLeave(e) {
    this.element.classList.remove("drag-active")
  }

  paste(e) {
    let files = Array.from(e.clipboardData.files);

    if(files.length > 0) {
      e.preventDefault();
      this.upload(files[0])
    }
  }

  drop(e) {
    e.preventDefault()
    this.dragLeave();
    let files = Array.from(e.dataTransfer.files);

    this.upload(files[0])
  }

  upload(file) {
    if(!this.fileIsValid(file)) {
      this.insertErrorMessage();
      return false
    }

    this.insertAtCursor("![uploading…]()");

    const headers = {
      "X-CSRF-Token": Rails.csrfToken(),
    }

    let data = new FormData();
    data.append('image', file)

    fetch(this.uploadUrlValue, {
      method: "POST",
      headers: headers,
      body: data,
    }).then((res) => res.json())
      .then(json => {
        this.insertImageAtCursor(json);
        this.triggerKeyup();
      })
  }

  insertImageAtCursor(json) {
    const url = json.url;
    const textArea = this.element;
    textArea.value = textArea.value.replace("![uploading…]()", `![](${url})`);
  }

  triggerKeyup() {
    this.element.dispatchEvent(new Event('keyup', { bubbles: true }));
  }

  insertAtCursor(text) {
    const textArea = this.element;

    const start = textArea.selectionStart;
    const end = textArea.selectionEnd;

    let value = textArea.value;
    textArea.value = `${value.substring(0, start)} ${text} ${value.substring(end, value.length)}`
  }

  fileIsValid(file) {
    return !!file.type.match(/image\/*/)
  }

  reset(e) {
    const textArea = this.element;
    if(textArea.parentElement.classList.contains("field_with_errors")) {
      textArea.parentElement.classList.add("field_without_errors");
      textArea.parentElement.classList.remove("field_with_errors");
      textArea.parentElement.querySelector(".error").remove();
    }
  }

  insertErrorMessage(){
    const textArea = this.element;

    let errorDiv = document.createElement("div");
    errorDiv.classList.add("error");
    errorDiv.innerHTML = "We don't support that file type. Only images are allowed";
    textArea.insertAdjacentElement('afterend', errorDiv);
    textArea.parentElement.classList.remove("field_without_errors");
    textArea.parentElement.classList.add("field_with_errors");
  }
}
