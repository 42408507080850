import { Controller } from "@hotwired/stimulus";
import { UAParser } from "ua-parser-js";

const ports = [12519, 40978, 52115, 22287, 60685, 22322];
const baseUrl = "http://127.0.0.1";
const waitTime = 10 * 1000; // 10 seconds
let retryCount = 0;

function blobFromImage(img) {
  let width = img.naturalWidth;
  let height = img.naturalHeight;

  const canvas = document.createElement("canvas");
  canvas.setAttribute("width", width);
  canvas.setAttribute("height", height);

  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  return new Promise((res) => {
    canvas.toBlob((img) => {
      res(img);
    });
  });
}

function handleTurboStreamRedirect(response) {
  if (response.redirected === true) {
    // Do not use a Turbo.visit here or it could cause an infinite loop of
    // reloading this controller in some situations.
    window.location.href = response.url;
    return Promise.reject("redirecting to " + response.url);
  } else {
    return response.text();
  }
}

export default class extends Controller {
  static targets = ["waiting", "brave"];
  static values = {
    samlRequestId: String,
    command: String,
    additionalCommand: String,
    commandPath: String,
    callbackPath: String,
    mobilePath: String,
    waitForever: { default: false, type: Boolean },
    fastRetry: { default: false, type: Boolean },
    skipBlobCallback: { default: false, type: Boolean }
  };

  // We can't detect iPads from the user agent alone, so we have to use feature
  // detection on the client side and then redirect.
  get isIpad() {
    const parser = new UAParser();
    return parser.getDevice().model === "iPad";
  }

  get isSafari() {
    const parser = new UAParser();
    return parser.getBrowser().name === "Safari";
  }
  // Brave browser detection.
  get isBraveUA() {
    if (!window.Promise) {
      return false;
    }
    if (
      typeof navigator.brave == "undefined" ||
      typeof navigator.brave.isBrave != "function"
    ) {
      return new Promise(function (resolve) {
        resolve(false);
      });
    }
    return navigator.brave
      .isBrave()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return false;
      });
  }

  // Get a new launcher command with the current device timestamp
  get updatedLauncherCommands() {
    const timestamp = Math.floor(new Date().getTime() / 1000);
    return fetch(`${this.commandPathValue}?timestamp=${timestamp}`)
      .then((response) => {
        return response.json();
      })
      .then((commands) => {
        this.commandValue = commands.launcher_command;
        this.additionalCommandValue = commands.acceleration_command;
        return console.debug("Retrieved updated launcher commands");
      })
      .catch((_err) => {
        return console.debug("Error retrieving launcher commands");
      });
  }

  get urlParams() {
    return new URLSearchParams(window.location.search)
  }

  disconnect() {
    clearTimeout(this.waitingRepeater);
  }

  connect() {
    console.info("Beginning agent detection for", this.samlRequestIdValue);

    this.waitingRepeater = false; // Make sure the waiting repeater is reset.

    // Early return if this is an iPad.
    if (this.isIpad) {
      return Turbo.visit(this.mobilePathValue);
    }

    if (this.isSafari) {
      // Number of tries to find launcher...if it's not there, it will default to 0;
      retryCount = Number(this.urlParams.get("safariRetry"));

      setTimeout(() => {
        this.postToAgentDetectionCallback(null, null);
      }, 5000)
    }

    // Check if the browser is Brave.
    this.isBraveUA.then((result) => {
      if (result) {
        this.braveBrowser = { retries: 0 };
      } else {
        this.braveBrowser = undefined;
      }
    });

    this.updatedLauncherCommands.then(() => {
      this.locateAgentLoop();
    });
  }

  async locateAgentLoop() {
    let found = undefined;
    let blob = null;

    // Check each port from the list and attempt to
    // decode a blob from the canvas.
    // For Callback-based detection, we don't need to do
    // anything else or worry about the blob at all.
    for (const port of ports) {
      try {
        found = await this.loadImageFromPort(port);
        blob = await blobFromImage(found.image);

        if (blob !== null) {
          break;
        }
      } catch (_err) {
        // The browser is already really noisy about these errors.
      }
    }

    // If we don't have a blob from the canvas then no launcher
    // instance responded on localhost with a valid image.
    if (blob === null) {
      console.info("Kolide launcher not found.");

      if (this.braveBrowser && retryCount == 0) {
        this.waitingTarget.classList.add("hidden");
        this.braveTarget.classList.remove("hidden");
        return console.info("Brave browser detected");
      }
      return this.waitThenTryAgain();
    }

    console.info(`Found Kolide launcher on port ${found.port}`);

    // Post the blob back to Kolide unless it should be skipped
    if (this.skipBlobCallbackValue) {
        return console.debug("Skipping blob callback");
    } else {
        try {
            this.postToAgentDetectionCallback(blob, found.port);
        } catch (err) {
            console.error("Error communicating with Kolide.", err);
            return this.waitThenTryAgain();
        }
    }
  }

  async loadImageFromPort(port) {
    const encodedCmd = encodeURIComponent(this.commandValue);
    const src = `${baseUrl}:${port}/v1/cmd?box=${encodedCmd}`;

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve({ image: img, port: port });
      img.onerror = (err) => reject();
      img.crossOrigin = "anonymous";
      img.src = src;
    });
  }

  waitThenTryAgain() {
    if (this.fastRetryValue) {
      this.waitingRepeater = setTimeout(this.tryAgain.bind(this), 2000);
    } else {
      console.info(`Waiting ${waitTime / 1000}s before rechecking...`);
      this.waitingRepeater = setTimeout(this.tryAgain.bind(this), waitTime);
    }
  }

  tryAgain() {
    retryCount += 1;
    clearTimeout(this.waitingRepeater);
    this.postToAgentDetectionCallback(null, null);
  }

  // POST blob and port to Rails callback for device lookup
  async postToAgentDetectionCallback(blob, port) {
    const csrfToken = document.getElementsByName("csrf-token")[0].content;
    let formData = new FormData();
    let timestamp = Math.floor(new Date().getTime() / 1000);

    formData.append("data", blob);
    formData.append("retry_count", retryCount);
    formData.append("wait", this.waitForeverValue);
    formData.append("timestamp", timestamp);

    await fetch(this.callbackPathValue, {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "text/html, text/vnd.turbo-stream.html",
      },
    })
      .then((response) => this.handleCallbackResponse(response))
      .catch((msg) => console.debug(msg));
  }

  handleCallbackResponse(response) {
    if (response.status === 200) {
      // no op
    } else if (response.status === 422) {
      // Browser may have `Advanced tracking and fingerprinting protection`
      // or the canvas was corrupted. Wait for callback detection.
      console.error("Agent Detection Failed", {
        message: "Waiting for Kolide agent to check-in",
      });
      this.waitThenTryAgain();
    } else if (response.status === 404) {
      // Launcher may not yet be fully enrolled.
      console.error("Agent Detection Failed", {
        message: "Kolide agent may not be fully enrolled yet",
      });
      this.waitThenTryAgain();
    } else if (response.status === 403) {
      // Crypto service key error.
      console.error("Agent Detection Failed", {
        message: "Crypto service key error",
      });
      this.waitThenTryAgain();
    } else {
      // Something really went wrong
      console.error("Agent Detection Failed", {
        message: "Unknown error",
        status: response.status,
      });
      this.waitThenTryAgain();
    }

    return handleTurboStreamRedirect(response);
  }

  // Brave recheck action
  braveCheckAgain(event) {
    console.debug("Brave Retry");

    retryCount += 1;
    this.locateAgentLoop();
  }
}
