import copy from 'copy-to-clipboard';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    text: String,
  }

  copyToClipboard() {
    copy(this.textValue)
  }
}
