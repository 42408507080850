import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hideable", "parent"];

  connect() {
    if (this.element.classList.contains("open")) {
      this.show();
    } else {
      this.hide();
    }
  }

  toggle() {
    if (this.element.classList.contains("open")) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.hideableTargets.forEach((target) =>
      target.classList.remove("collapsed")
    );
    this.element.classList.add("open");
  }

  hide() {
    this.hideableTargets.forEach((target) => target.classList.add("collapsed"));
    this.element.classList.remove("open");
  }
}
