import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    exitUrl: String,
  }

  closeModal(event) {
    event.preventDefault()

    if (kModal.config.ujs) {
      kModal.hide()
    } else {
      Turbo.visit(this.exitUrlValue)
    }
  }
}
