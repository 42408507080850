import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["switch"];
  static values = {
    enableUrl: String,
    disableUrl: String,
  }

  toggleWebhook = debounce(() => {
    if (this.switchTarget.checked) {
      this.patch(this.enableUrlValue)
    } else {
      this.patch(this.disableUrlValue)
    }
  }, 250)

  patch(endpoint) {
    fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
  }
}
