import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import { current } from "./../../../global/helpers"
import jQuery from 'jquery'

export default class extends Controller {
  static targets = ["table", "actions"]

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatbales
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    // need to check for the button existence because the 'connect' for this
    // controller is being called multiple times on turbo:load, despite
    // being called only once on a full page load/refresh.
    let onboardButton = table.button('onboard:name').nodes()
    if(onboardButton.length == 0) {
      table.button().add(null, this.onboardButton())
    }
  }

  onboardButton() {
    return {
      text: "<svg class=\"kolidecon kolidecon-slack\" viewBox=\"0 0 24 24\" version=\"1.1\" width=\"24\" height=\"24\" aria-hidden=\"true\"><path d=\"M8.474 5.842a2.421 2.421 0 1 1 2.42-2.42v2.42h-2.42zm9.684 3.632a2.421 2.421 0 1 1 2.42 2.42h-2.42v-2.42zm-3.632 9.684a2.421 2.421 0 1 1-2.42 2.42v-2.42h2.42zm-9.684-3.632a2.421 2.421 0 1 1-2.42-2.42h2.42v2.42zm3.632-8.473a2.421 2.421 0 1 1 0 4.842H2.42a2.42 2.42 0 0 1 0-4.842h6.053zm12.105 6.052a2.421 2.421 0 1 1 0 4.842h-6.053a2.42 2.42 0 0 1 0-4.842h6.053zm-9.684 8.474a2.421 2.421 0 1 1-4.842 0v-6.053a2.42 2.42 0 0 1 4.842 0v6.053zm6.052-12.105a2.421 2.421 0 1 1-4.842 0V3.42a2.42 2.42 0 0 1 4.842 0v6.053z\"/></svg> Onboard Via Slack",
      name: 'onboard',
      className: 'table-button table-button--strong table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let ids = dt.rows({selected: true}).indexes()
        let params = ids.map(idx => jQuery(dt.column('name:name').nodes()[idx]).find('a').attr("data-id")).join(',')
        let url = `/${current.organization.id}/onboarding/users/onboard?ids=${params}`
        this.post(url).then(() => location.reload())
      }
    }
  }

  post(endpoint) {
    return fetch(endpoint, {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
  }
}
