import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'animationVideo' ]

  static values = {
    startInterval: { type: Number, default: 9000 },
    resetInterval: { type: Number, default: 4200 },
  }

  static animating;

  connect() {
    setTimeout(() => {
      this.animate()
    }, this.startIntervalValue)
  }

  animate() {
    if (!this.animating && this.animationVideoTarget.readyState == 4) {
      this.animationVideoTarget.play()
      this.animating = true
      setTimeout(() => {
        this.stopAnimation()
      }, this.resetIntervalValue)
    }
  }

  stopAnimation() {
    this.animating = false;
  }
}
