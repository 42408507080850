import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['hubspotutk'];

  connect() {
    this.hubspotutkTarget.value = this.parseHubspotCookie()
  }


  parseHubspotCookie() {
    for (const token of document.cookie.split(' ')) {
      if (token.includes('hubspotutk')) {
        return token.replace('hubspotutk=', '').replace(';', '')
      }
    }
    return ''
  }
}
