import $ from "jquery"
import dataTable from "datatables.net-dt"
dataTable(window, $)

import buttons from "datatables.net-buttons-dt"
buttons(window, $)

import buttonsHtml5 from "datatables.net-buttons/js/buttons.html5.js"
buttonsHtml5(window, $)

import colvis from "datatables.net-buttons/js/buttons.colVis.js"
colvis(window, $)

import colReorder from "datatables.net-colresizewithreorder/ColReorderWithResize.js"
colReorder(window, $)

import scroller from "datatables.net-scroller-dt"
scroller(window, $)

import select from "datatables.net-select"
select(window, $)

import selectDt from "datatables.net-select-dt"
selectDt(window, $)

import fixedColumns from "datatables.net-fixedcolumns-dt"
fixedColumns(window, $)
