import { Controller } from "@hotwired/stimulus";
import { Diff2HtmlUI, Diff2HtmlUIConfig } from 'diff2html/lib/ui/js/diff2html-ui-slim.js';

export default class extends Controller {
  static values = {
    unified: String
  }

  connect() {
    const diff2htmlUi = new Diff2HtmlUI(this.element, this.unifiedValue, this.diffConfiguration);
    diff2htmlUi.draw();
  }

  get diffConfiguration() {
    return {
      outputFormat: "side-by-side",
      drawFileList: false,
      fileContentToggle: false,
    };
  }
}
