import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = [
    "searchable",
    "searchList",
  ];

  connect() {
    // Prevents the filter sidebar from jumping around as elements
    // are added/removed via search.
    if (this.hasSearchListTarget) {
      let currentHeight = this.searchListTarget.clientHeight + 'px';
      this.searchListTarget.style.minHeight = currentHeight;
    }
  }

  search = debounce((event) => {
    const searchValue = event.target.value
    const inputMatcher = new RegExp(`${searchValue}.*`, 'i');

    this.searchableTargets.forEach(el => el.classList.remove("hidden-by-search"))
    this.searchableTargets.forEach(el => el.classList.remove("found"))

    if (searchValue != "") {
      this.searchableTargets.forEach(searchable => {
        if (searchable.dataset.searchText.match(inputMatcher)) {
          searchable.classList.add("found")
        }
      })

      this.searchableTargets.forEach(searchable => {
        if (!searchable.classList.contains("found")) {
          searchable.classList.add("hidden-by-search")
        }
      })
    }
  }, 250)
}
