import { Controller } from "@hotwired/stimulus";
import { format } from 'sql-formatter';

export default class extends Controller {

  static values = {
    sqlVariant: { default: 'sqlite' }
  }

  connect() {
    if (this.element.textContent.length > 80) {
      try {
        let formattedSql = format(this.element.textContent, { language: this.sqlVariantValue })
        this.element.outerHTML = `<pre>${formattedSql}</pre>`
      }
      catch {
        this.element.outerHTML = `<pre>${this.element.textContent}</pre>`
      }
    } else {
      this.element.outerHTML = `<pre>${this.element.textContent}</pre>`
    }
  }
}
