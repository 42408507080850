import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { endpoint: String }

  connect() {
    this.checkStatus()
  }

  checkStatus() {
    fetch(this.endpointValue, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(result => {
        if (result.valid === true) {
          this.show()
        } else {
          this.close()
        }
      })
  }

  show() {
    this.element.classList.add("issues-table__overlay--visible")
  }

  close() {
    this.element.remove()
  }
}
