import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "choice" ];

  setChoice(event){
    let providerType = event.currentTarget.dataset.providerType;
    this.choiceTargets.forEach((el) => el.classList.remove('selected'))
    event.currentTarget.classList.add('selected')
    let link = new URL(this.linkTarget.href)

    link.search = ""
    link.search = new URLSearchParams({type: providerType})
    this.linkTarget.href = link
    this.linkTarget.classList.remove('button--disabled')
  }
}
