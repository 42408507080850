import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "formInput", "downloadResultsLink"];
  static values = {
    newUrl: String,
    revertUrl: String,
  }

  handleSubmitClick(event) {
    event.preventDefault();
    switch (this.formInputTargets.filter(input => input.checked)[0].value) {
      case 'clone':
        let searchParams = new URLSearchParams()
        let targetsInputEl = document.querySelector('[data-live-query-target~="targetsInput"')
        searchParams.append('sql', document.querySelector('[data-live-query-target~="sqlInput"').value)
        searchParams.append('device_ids', targetsInputEl.dataset.targetIds)
        searchParams.append('all_devices', targetsInputEl.querySelector("#live_query_campaign_targeted_target_all_devices").value)
        searchParams.append('all_macs', targetsInputEl.querySelector("#live_query_campaign_targeted_target_macs").value)
        searchParams.append('all_linux_devices', targetsInputEl.querySelector("#live_query_campaign_targeted_target_linux_devices").value)
        searchParams.append('all_windows_devices', targetsInputEl.querySelector("#live_query_campaign_targeted_target_windows_devices").value)

        let url = new URL(this.newUrlValue, location.origin)
        url.search = searchParams.toString()
        location.assign(url.toString())
        break;
      case 'rerun':
        document.querySelectorAll('[data-live-query-target~="intervalButton"]').forEach(el => el.classList.remove("hidden"))
        document.querySelectorAll('[data-live-query-target~=".confirmButton"]').forEach(el => el.classList.add("hidden"))
        document.querySelectorAll('[data-live-query-target="submitButton"]')[0].click()
        kModal.hide()
        break
      default:
        location.assign(this.revertUrlValue)
        break
    }
  }

  handleInputChange(event) {
    if (event.target.value === 'rerun') {
      this.downloadResultsLinkTarget.classList.remove("hidden")
    } else {
      this.downloadResultsLinkTarget.classList.add("hidden")
    }
  }
}
