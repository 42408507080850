import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import { fadeOut } from '../../../global/utils'

export default class extends Controller {
  static targets = ["toggle", "disabledNotice", "label"]
  static values = {
    state: Boolean,
    enableUrl: String,
    disableUrl: String,
  }

  connect() {
    this.updateStatus()
  }

  updateStatus() {
    if (this.stateValue) {
      this.labelTarget.textContent = "Enabled"
      fadeOut(this.disabledNoticeTarget, 'slow')
    } else {
      this.labelTarget.textContent = "Disabled"
    }
  }

  toggle() {
    if (this.toggleTarget.checked) {
      this.enable()
    } else {
      this.disable()
    }

    this.updateStatus()
  }

  enable() {
    this.patch(this.enableUrlValue)
    this.stateValue = true
  }

  disable() {
    this.patch(this.disableUrlValue)
    this.stateValue = false
  }

  patch(endpoint) {
    fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
  }
}
