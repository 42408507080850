import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    eventName: String,
    listenerEvent: String
  }

  static targets = [
    "dropdownLabel",
    "dropdown",
    "option",
    "gracePeriodInput",
    "fauxGracePeriodInput",
    "menu"
  ]

  connect() {
    this.setSelectedItemState();
    this.setDropdownState();
    this.subscribeToListenerEvent();
    // bootstrap the parent with the right value
    const event = new CustomEvent(this.eventNameValue, { detail: this.getDays() });
    document.body.dispatchEvent(event)
  }

  disconnect() {
    if (this.listenerEventValue != "") {
      document.body.removeEventListener(this.listenerEventValue, this.updateMinimum)
    }
  }

  toggle() {
    if (this.menuTarget.classList.contains("open")) {
      this.hide();
    } else {
      this.show();
    }
  }

  // allows us to listen to one of the events dispatched by a sibling grace period controller and update the minimum value of this grace period controller's faux input...
  subscribeToListenerEvent() {
    if (this.listenerEventValue != "") {
      document.body.addEventListener(this.listenerEventValue, this.updateMinimum)
    }
  }

  updateMinimum = (event) => {
    if (event.detail == 0) {
      this.fauxGracePeriodInputTarget.min = 1;
    } else {
      this.fauxGracePeriodInputTarget.min = event.detail;
    }
    if (this.getDays() < event.detail) {
      this.setDays(event.detail);
      this.fauxGracePeriodInputTarget.value = event.detail;
      this.setDropdownState();
    }
  }

  setSelectedItemState() {
    let value = this.getDays();
    if (value == 0) {
      let immediateOption = this.optionTargets.find(o => o.dataset.optionId == "immediate")
      if (immediateOption) {
        immediateOption.classList.add("selected");
        this.fauxGracePeriodInputTarget.value = 7;
      } else {
        this.setDays(7);
        this.optionTargets.find(o => o.dataset.optionId == "days").classList.add("selected");
      }
    } else {
      this.fauxGracePeriodInputTarget.value = value;
      this.optionTargets.find(o => o.dataset.optionId == "days").classList.add("selected");
    }
  }

  getDays() {
    return parseInt(this.gracePeriodInputTarget.value);
  }

  setDays(num) {
    num = parseInt(num);
    // If the number has changed, save it and send event
    if (this.gracePeriodInputTarget.value != num) {
      this.gracePeriodInputTarget.value = num;

      const event = new CustomEvent(this.eventNameValue, { detail: num });
      document.body.dispatchEvent(event)
    }
  }

  handleFauxSetDays() {
    this.setDays(this.fauxGracePeriodInputTarget.value);
    this.setDropdownState();
  }

  setDropdownState() {
    let selectedOption = this.optionTargets.find(o => o.classList.contains("selected"))
    if (selectedOption?.dataset.optionId == "immediate") {
      this.dropdownLabelTarget.innerHTML = "immediately";
      this.setDays(0)
    } else if (selectedOption?.dataset.optionId == "days") {
      if (this.getDays() == 0) {
        this.setDays(7);
      } else {
        this.setDays(this.getDays())
      }
      this.dropdownLabelTarget.innerHTML = `after ${this.getDays()} ${this.getDays() > 1 ? "days" : "day"}`;
    } else {
      this.dropdownLabelTarget.innerHTML = "unknown";
    }
  }

  show() {
    this.dropdownTarget.classList.add("active");
    this.menuTarget.classList.add("open");
  }

  hide() {
    this.dropdownTarget.classList.remove("active");
    this.menuTarget.classList.remove("open");
  }

  maybeHide(event) {
    if (!this.dropdownTarget.contains(event.target) && !this.menuTarget.contains(event.target)) {
      this.hide();
    }
  }

  selectOption(event) {
    this.optionTargets.forEach(element => element.classList.remove("selected"));
    event.currentTarget.classList.add("selected");
    this.setDropdownState();
  }
}
