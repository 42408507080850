import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["scrollable"];

  connect() {
    addEventListener("turbo:frame-render", this.scroll());
  }

  scroll() {
    this.scrollableTarget.scrollTo({
      top: this.scrollableTarget.scrollHeight,
      behavior: "smooth",
    });
  }
}
