import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "eventOption"];
  static total;

  connect() {
    this.total = this.eventOptionTargets.length;
    this.calculateSelected();
  }

  calculateSelected() {
    let count = 0;
    this.eventOptionTargets.forEach((eventOption) => {
      if (eventOption.checked) {
        count++;
      }
    });
    this.labelTarget.innerHTML = `${count} of ${this.total} Events Subscribed`;
  }
}
