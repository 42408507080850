import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "fileInput", "uploadButton", "cancelButton", "clearButton", "form", "loader", "errorMessage" ]
  static values = {
    // the sizeLimit is the max file size in MB
    sizeLimit: Number
  }

  show(event) {
    event.preventDefault()
    this.fileInputTarget.style.display = 'block'
    this.cancelButtonTarget.style.display = 'block'
    this.uploadButtonTarget.style.display = 'none'
    this.clearButtonTarget.style.display = 'none'
  }

  cancel(event) {
    event.preventDefault()
    this.fileInputTarget.style.display = 'none'
    this.cancelButtonTarget.style.display = 'none'
    this.uploadButtonTarget.style.display = 'block'
    this.clearButtonTarget.style.display = 'block'
  }

  submit() {
    let file = this.fileInputTarget.files[0]
    if (file.size > this.sizeLimitValue) {
      this.errorMessageTarget.style.display = 'block'
    } else {
      this.fileInputTarget.style.display = 'none'
      this.cancelButtonTarget.style.display = 'none'
      this.uploadButtonTarget.style.display = 'none'
      this.clearButtonTarget.style.display = 'none'
      this.loaderTarget.style.display = 'block'
      this.formTarget.submit()
    }
  }
}
