import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "impersonationPurpose", "helperSelect"]

  submit() {
    this.formTarget.requestSubmit()
  }

  helperChanged() {
    if (this.helperSelectTarget.value === "") {
      this.impersonationPurposeTarget.style.display = "inherit"
    } else {
      this.impersonationPurposeTarget.style.display = "none"
    }
  }

  impersonationPurposeTargetConnected(el) {
    this.impersonationPurposeTarget.style.display = "inherit"
  }
}
