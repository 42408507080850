import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["searchInput", "searchForm", "results", "original", "searchCheckbox"]
  static originalFormValues;

  connect() {
    if (this.hasSearchFormTarget) {
      this.originalFormValues = this.constructComparableFormData(this.searchFormTarget)
    }
  }

  searchList = debounce((event) => {
    let currentFormValues = this.constructComparableFormData(this.searchFormTarget)
    if(currentFormValues != this.originalFormValues){
      this.searchFormTarget.requestSubmit();
    } else {
      this.originalTarget.style.display = "";
      this.resultsTarget.style.display = "none";
      this.resultsTarget.innerHTML = "";
    }
  }, 250)

  searchFinish() {
    this.originalTarget.style.display = "none";
    this.resultsTarget.style.display = "";
  }

  constructComparableFormData(form) {
    let formData = new FormData(form)
    formData.delete("authenticity_token")

    return JSON.stringify([...formData.entries()])
  }
}
