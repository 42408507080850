import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['context', 'output'];
  static values = { url: String }

  test(event){
    let templateContext = JSON.parse(this.contextTarget.value)
    fetch(`${this.urlValue}?context=${JSON.stringify(templateContext)}`)
      .then(resp => resp.json())
      .then((resp) => {
        this.outputTarget.innerHTML = resp.output
      })
    event.preventDefault()
  }
}
