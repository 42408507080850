import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tableSelector: String,
    contentSelector: String,
    headingsOffset: Number,
    headingSelector: String,
    enableReportingTableParsing: Boolean,
  }

  connect() {
    tocbot.init({
      // Where to render the table of contents.
      tocSelector: this.tableSelectorValue,
      // Where to grab the headings to build the table of contents.
      contentSelector: this.contentSelectorValue,
      scrollSmooth: false,
      headingsOffset: this.headingsOffsetValue || 1,
      // Which headings to grab inside of the contentSelector element.
      headingSelector: this.headingSelectorValue || 'h1, h2, h3',
      // For headings inside relative or absolute positioned containers within content.
      hasInnerContainers: true,
      headingObjectCallback: (el) => {
        if (this.enableReportingTableParsingValue && (el.headingLevel == 3)) {
          el.textContent = document.getElementById(el.id).querySelector(".reporting-tables__table-header-title").textContent
        }

        return el
      }
    });
  }
}
