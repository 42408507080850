const kSidebar = {
  class: 'kolide-sidebar',

  SidebarOverlay: null,

  content: null,

  config: {},
  defaultConfig: {
    href: '',
    launchingUrl: '',
    namespace: '',
    content: '',
    ajax: false,
    ujs: false,
    disableEscKeyClose: false
  },

  toggle: () => kSidebar.SidebarOverlay.classList.toggle(`${kSidebar.class}--active`),

  show: () => kSidebar.SidebarOverlay.classList.add(`${kSidebar.class}--active`),

  hide: () => {
    if (window.dispatchEvent(new CustomEvent('beforeSidebarHide', { cancelable: true }))) {
      document.querySelector('.kolide-sidebar__wrapper').classList.add('animate__slideOutRight', 'animate__animated')
      kSidebar.SidebarOverlay.classList.add('animate__fadeOut', 'animate__animated')

      setTimeout(function () {
        kSidebar.SidebarOverlay.remove()
      }, 500)
    }
  },

  hideAll: () => {
    kSidebar.SidebarOverlay.classList.remove(`${kSidebar.class}--active`);
  },

  remove: () => {
    kSidebar.SidebarOverlay.remove();
  },

  getContent: (href) => {
    fetch(href, {
      headers: { 'Kolide-Sidebar': true, 'X-Requested-With': 'XMLHttpRequest' },
    }).finally((data) => {
      kSidebar.buildContent(data);
    }).catch(() => {
      kSidebar.remove();
    });
  },

  buildContent: (content) => {
    const { class: klass, config: { namespace } } = kSidebar;
    const template = document.createElement('template')
    template.innerHTML = `<div class="${kSidebar.class}__content"></div>`
    const Content = template.content.firstElementChild

    Content.insertAdjacentHTML('beforeend', content);

    kSidebar.content = Content;
    const classes = [`${klass}--active`, `${namespace}`].filter(el => el !== '')
    kSidebar.SidebarOverlay.classList.add(classes)
    kSidebar.SidebarOverlay.insertAdjacentHTML('beforeend', content);
  },

  buildSidebar: () => {
    const { class: klass } = kSidebar;
    let klasses;
    if (kSidebar.config.ujs) {
      klasses = `${klass} animate__animated animate__fadeIn`
    } else {
      klasses = `${klass} animate__animated animate__empty-transform`
    }
    const htmlString = `
      <div
        class="${klasses}"
        data-sidebar-auto-close-exit-url-value="${kSidebar.config.launchingUrl}"
        data-controller="sidebar-auto-close"
        data-sidebar-auto-close-exit-url-value="${kSidebar.config.launchingUrl}"
        data-action="mousedown->sidebar-auto-close#closeSidebar keyup@window->sidebar-auto-close#keyUpHandler"
        data-sidebar-auto-close-target="container"
      ></div>
    `
    const template = document.createElement('template')
    template.innerHTML = htmlString
    kSidebar.SidebarOverlay = template.content.firstElementChild

    document.body.appendChild(kSidebar.SidebarOverlay);
  },

  init: (options) => {
    kSidebar.config = {}; // we need to clear this out between inits
    kSidebar.config = Object.assign({}, kSidebar.defaultConfig, options);

    if (document.contains(kSidebar.SidebarOverlay)) {
      kSidebar.style.display = 'block'
    } else {
      kSidebar.buildSidebar();
      if (kSidebar.config.ajax) {
        kSidebar.getContent(kSidebar.config.href);
      } else {
        kSidebar.buildContent(kSidebar.config.content);
      }
    }
  },
};

module.exports = kSidebar;
