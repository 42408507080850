import { Controller } from "@hotwired/stimulus"
import consumer from './../channels';
import $ from 'jquery'

export default class extends Controller {
  static targets = [
    "form",
    "submitButton",
    "queries",
    "results",
    "refreshButton",
    "sqlInput",
    "targetsInput",
    "intervalButton",
    "confirmButton",
    "resultsTab",
    "statusTab",
  ];

  static values = {
    campaignId: String,
    dtEnabled: Boolean
  };

  loader = `<div class="render-async"><div class="spinner"></div></div>`
  stateClean = true;

  connect() {
    if (this.hasCampaignIdValue) {
      this.liveQueryQueriesSubscription = consumer.subscriptions.create(
        { channel: "LiveQueryCampaignQueriesChannel", campaign_id: this.campaignIdValue, dt_enabled: this.dtEnabledValue },
        {
          received(data) {
            let tr = `tr[data-live-query-id='${data.query_id}']`
            let tableClass = ".live-query-editor__queries .live-queries__data-table"
            if (parseInt($(`.live-query-editor__queries tr[data-live-query-id='${data.query_id}']`).attr("data-live-query-status")) <= data.status) {
              $(tableClass).DataTable().rows(tr).remove()
              $(`.live-query-editor__queries tr[data-live-query-id='${data.query_id}']`).prop('outerHTML', data.row)
            }
          },
        },
      );

      this.liveQueryResultsSubscription = consumer.subscriptions.create(
        { channel: "LiveQueryCampaignResultsChannel", campaign_id: this.campaignIdValue, dt_enabled: this.dtEnabledValue  },
        {
          received: (data) => {
            // Update if no results are on screen
            if ($('.live-queries__with-results:visible').length > 0) {
              $(this.refreshButtonTarget).show()
            } else {
              // Otherwise just show the refresh button
              $(this.resultsTarget).html(data)
            }
          },
        },
      );

      this.bindFormListeners();
    }
  }

  clear() {
    $(this.queriesTarget).html(this.loader)
    $(this.resultsTarget).html(this.loader)
    $(this.formTarget).find("span.error").remove()
    this.stateClean = true;
  }

  clearResults() {
    $(this.resultsTarget).html(this.loader)
  }

  viewResult() {
    window.addEventListener("turbo:before-stream-render", () => {
      this.resultsTabTarget.click();
    }, { once: true });
  }

  reloadResultsTable() {
    let tableClass = ".live-query-editor__results .live-queries__data-table"
    $(tableClass).DataTable().ajax.reload()
    event.preventDefault()
  }

  bindFormListeners() {
    if (this.hasSqlInputTarget) {
      this.sqlInputTarget.addEventListener('change', this.handleFormChange)
    }

    if (this.hasTargetsInputTarget) {
      this.targetsInputTarget.addEventListener('change', this.handleFormChange)
    }
  }

  handleFormChange = () => {
    if (this.stateClean && this.hasIntervalButtonTarget && this.hasConfirmButtonTarget) {
      $(this.intervalButtonTarget).toggleClass("hidden");
      $(this.confirmButtonTarget).toggleClass("hidden");
      this.stateClean = false;
    }
  }

  disconnect() {
    if (typeof this.liveQueryQueriesSubscription !== 'undefined') {
      this.liveQueryQueriesSubscription.unsubscribe();
    }

    if (typeof this.liveQueryResultsSubscription !== 'undefined') {
      this.liveQueryResultsSubscription.unsubscribe();
    }
  }

  submitForm(event) {
    // command-enter or ctrl-enter submits the form
    if ((event.ctrlKey || event.metaKey) && event.which == 13) {
      event.preventDefault();
      this.submitButtonTarget.click()
    }
  }
}
