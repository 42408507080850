import { Controller } from "@hotwired/stimulus";

// to use this as a replacement for generic checkboxes,
// you can use a `<kolide-toggle>` element with this as the controller. See example below. 
// Include a checkbox input element inside the
// <kolide-toggle
//   data-controller="kolide-toggle"
//   data-action="click->kolide-toggle#toggle">
//   <input name="foo" type="hidden" value="<0 or 1, set programmatically based on form state>"
//   <label for="foo"></label>
// </kolide-toggle>
export default class extends Controller {
  static targets = ["checkbox", "hiddenInput"];

  toggle(){
    if(this.checkboxTarget.checked) {
      this.checkboxTarget.removeAttribute("checked")
      this.hiddenInputTarget.setAttribute("value", 0)
    } else {
      this.checkboxTarget.setAttribute("checked", "checked")
      this.hiddenInputTarget.setAttribute("value", 1)
    }
  }
}
