import { Controller } from "@hotwired/stimulus";
import { current } from "./../../../global/helpers"
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {
  static values = {
    endpoint: String
  };

  static targets = ["table", "actions"]

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatbales
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    // need to check for the button existence because the 'connect' for this
    // controller is being called multiple times on turbo:load, despite
    // being called only once on a full page load/refresh.
    let ignoreButton = table.button('ignore:name').nodes()
    let recheckButton = table.button('recheck:name').nodes()
    let deEscalateButton = table.button('deescalate:name').nodes()

    if(ignoreButton.length == 0 && !current.flags.deviceTrustEnabled) {
      table.button().add(null, this.ignoreButton())
    }
    if(recheckButton.length == 0 && !current.flags.deviceTrustEnabled) {
      table.button().add(null, this.recheckButton())
    }
    if(deEscalateButton.length == 0 && !current.flags.deviceTrustEnabled) {
      table.button().add(null, this.deEscalateButton())
    }
  }

  recheckButton(){
    return {
      text: 'Recheck',
      name: 'recheck',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let selectedRows = dt.rows({ selected: true }).indexes()
        let issueIds = []
        selectedRows.each( (idx)=> {
          issueIds.push(dt.column('issue:name').nodes()[idx].querySelector('a').dataset.issueId);
        })
        this.recheck(issueIds);
      }
    }
  }

  deEscalateButton(){
    return {
      text: 'De-escalate',
      name: 'deescalate',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        ids.each( (idx)=> {
          let issueURL = jQuery(dt.column('issue:name').nodes()[idx]).find('a').attr('href')
          this.deescalate(issueURL)
        })
      }
    }
  }

  ignoreButton() {
    return {
      text: "Ignore",
      name: 'ignore',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        ids.each((idx)=> {
          let issueURL = jQuery(dt.column('issue:name').nodes()[idx]).find('a').attr('href')
          this.ignore(issueURL)
        })
      }
    }
  }

  recheck(issueIds) {
    fetch(this.endpointValue, {
      method: 'POST',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({ issues: issueIds }),
    }).then(() => {
      location.reload();
    })
  }

  deescalate(issueURL) {
    let endpoint = issueURL.split('?')[0] + "/deescalate.json"
    fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }

  ignore(issueURL) {
    let endpoint = issueURL.split('?')[0] + '/ignore'

    fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }
}
