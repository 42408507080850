import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  static values = { customMessage: String, enabled: Boolean }

  initialize() {
    this.defaultMessage = "👋 Hi there, I'm the Kolide App!\n\nKolide is used by your organization to inform you when your devices have issues that affect system stability or security."
    this.autosizeEvent = new Event("input");
  }

  connect() {
    if (!this.enabledValue) {
      this.inputTarget.value = this.defaultMessage;
      this.inputTarget.dispatchEvent(this.autosizeEvent);
    }
  }

  toggle(event) {
    if (event.target.value === "true") {
      this.inputTarget.readOnly = false

      if (this.customMessageValue !== "") {
        this.inputTarget.value = this.customMessageValue;
      } else {
        this.inputTarget.value = this.defaultMessage;
      }
    } else {
      this.inputTarget.value = this.defaultMessage;
      this.inputTarget.readOnly = true
    }

    this.inputTarget.dispatchEvent(this.autosizeEvent);
  }

  update() {
    this.customMessageValue = this.inputTarget.value;
  }
}
