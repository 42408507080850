import { Controller } from '@hotwired/stimulus';
import consumer from './../../channels';

export default class extends Controller {
  static targets = ["cancelLink"];
  static values = {
    deviceId: String
  };

  connect() {
    this.deviceRemovalsSub = consumer.subscriptions.create(
      { channel: "DeviceRemovalsChannel", device_id: this.deviceIdValue },
      {
        received: (data) => {
          if (data === "deletion_started") {
            if (this.hasCancelLinkTarget) {
              this.cancelLinkTarget.remove();
            }
          } else if (data === "deletion_completed") {
            window.location.reload();
          }
        },
      },
    );
  }

  disconnect() {
    this.deviceRemovalsSub.unsubscribe();
  }
}
