import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "list"]

  toggle() {
    this.listTarget.classList.toggle("open")
  }

  // Don't cache menu in open state with Turbo Drive
  teardown() {
    this.listTarget.classList.remove("open")
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.listTarget.classList.remove("open")
    }
  }
}
