import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["switch", "label"]
  static values = {
    disableMethod: String,
    disableText: String,
    disableUrl: String,
    enableMethod: String,
    enableText: String,
    enableUrl: String,
    state: Boolean
  }

  toggle() {
    if (this.switchTarget.checked) {
      this.enable()
      if (this.hasLabelTarget) {
        this.labelTarget.textContent = this.enableTextValue || "Enabled"
      }
    } else {
      this.disable()
      if (this.hasLabelTarget) {
        this.labelTarget.textContent = this.disableTextValue || "Disabled"
      }
    }
  }

  enable() {
    this.submit(this.enableUrlValue, this.enableMethodValue)
    this.stateValue = true
  }

  disable() {
    this.submit(this.disableUrlValue, this.disableMethodValue)
    this.stateValue = false
  }

  submit(endpoint, method) {
    fetch(endpoint, {
      method: method || "PATCH",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
  }
}
