import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"]
  static values = { enabled: String }

  add(event) {
    if (event.target.value === "everyone") {
      if (this.enabledValue !== "everyone") {
        this.submitTarget.dataset.confirm = "This will complete onboarding for the rest of your users (based on your settings), and going forward, automatically onboard your new Slack users. Do you wish to proceed?"
      }
    } else {
      this.submitTarget.dataset.confirm = ""
    }
  }
}
