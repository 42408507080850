import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatables
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())

      let lastImport = this.element.dataset.integrationLastImport
      // skip the long polling setup if there is data in the table OR if an import has been run.
      if(!table.DataTable().data().any() && !lastImport) {
        this.longPoll = setInterval(()=>{
          let url = `${this.element.dataset.baseUrl}/status`
          fetch(url, {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            },
          }).then(response => response.json())
            .then(result => {
              if(result["is_configured"] === true
                 && result["last_import"] != null
                ) {
                clearInterval(this.longPoll)
                location.reload();
              }
            })

          // check with the server to see if there are any identities or errors
        },1000)
      }
    }, 250);
  }

  disconnect() {
    clearInterval(this.longPoll)
  }

  addActionButtons(table) {
    let archiveButton = table.button('archive:name').nodes()
    if(archiveButton.length == 0) {
      table.button().add(null, this.archiveButton())
    }
  }

  archiveButton() {
    return {
      text: "Archive selected",
      name: 'archive',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: async (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        let urlPrefix = "/x/inventory/identities/"
        let promises = []
        ids.each((idx)=> {
          let identityId = jQuery(dt.column('name:name').nodes()[idx]).find(':first-child').attr('data-id')
          let identityURL = `${urlPrefix}${identityId}/archive`
          promises.push(this.patch(identityURL))
        })
        await Promise.all(promises)
        location.reload()
      }
    }
  }

  patch(endpoint) {
    return fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
  }
}
