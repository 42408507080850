import { Controller } from "@hotwired/stimulus";
import consumer from './../../channels';

export default class extends Controller {
  static targets = ["logs", "logTypeSelector", "deviceSelector", "autoscroll", "loader"]
  values = {
    disabled: Boolean,
  }
  loader = `<div class="log-viewer__logs-loader animate__animated animate__zoomIn animate__fast" data-log-pipeline--viewer-target="loader"><div class="spinner"></div> Waiting For Logs...</div>`
  disabledMessage = `<div class="log-viewer__logs-loader animate__animated animate__zoomIn animate__fast" data-log-pipeline--viewer-target="loader">Pipeline Disabled</div>`

  connect() {
    this.subscribe()
  }

  subscribe() {
    this.clear()
    this.unsubscribe()

    let deviceIDs
    let deviceGroups
    let logType

    setTimeout(() => {
      deviceIDs = JSON.parse(this.deviceSelectorTarget.getAttribute("data-target-ids"))
      deviceGroups = JSON.parse(this.deviceSelectorTarget.getAttribute("data-target-groups"))

      logType = this.logTypeSelectorTarget.value
      this.autoscrollTargetchecked = true

      this.viewerSubscription = consumer.subscriptions.create(
        {
          channel: "LogPipelineViewerChannel",
          log_type: logType,
          targeted_device_ids: deviceIDs,
          target_all_devices: deviceGroups.target_all_devices,
          target_macs: deviceGroups.target_macs,
          target_linux_devices: deviceGroups.target_linux_devices,
          target_windows_devices: deviceGroups.target_windows_devices
        },
        {
          received: (data) => {
            if (this.hasLoaderTarget) {
              this.loaderTarget.remove()
            }
            this.logsTarget.insertAdjacentHTML('beforeend', data)
            if (this.autoscrollTarget.checked) {
              this.logsTarget.scrollTop = this.logsTarget.scrollHeight
            }
          },
        },
      );
    }, 300)
  }

  handleClear(event) {
    event.preventDefault()
    this.clear()
  }

  clear() {
    if (this.disabledValue) {
      this.logsTarget.innerHTML = this.disabledMessage
    } else {
      this.logsTarget.innerHTML = this.loader
    }
  }

  download(event) {
    event.preventDefault()
    let content = this.logsTarget.textContent
    let link = document.createElement('a');
    let mimeType = 'text/plain';

    link.setAttribute('download', `${Date.now()}-${this.logTypeSelectorTarget.value}-logs.txt`);
    link.setAttribute('href', 'data:' + mimeType + ';charset=utf-8,' + encodeURIComponent(content));
    link.click();
  }

  lastScrollTop = 0;
  disableAutoScroll(event) {
    let st = this.logsTarget.scrollTop
    if (st < this.lastScrollTop) {
      this.autoscrollTarget.checked = false
    } else if ((this.logsTarget.scrollTop + this.logsTarget.innerHeight) >= this.logsTarget.scrollHeight) {
      this.autoscrollTarget.checked = true
    }
    this.lastScrollTop = st;
  }

  unsubscribe() {
    if (typeof this.viewerSubscription !== 'undefined') {
      this.viewerSubscription.unsubscribe();
    }
  }

  disconnect() {
    this.unsubscribe()
  }
}
