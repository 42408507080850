import bugsnag from '@bugsnag/js'

let bugsnagStage = 'development';
let productionHosts = ['k2.kolide.com', 'app.kolide.com', 'auth.kolide.com']
if(productionHosts.includes(window.location.host)) {
  bugsnagStage = 'production';
} else if(/.*herokuapp.com/.test(window.location.host)) {
  bugsnagStage = 'review_app';
}

let params = new URLSearchParams(window.location.search)
if (params.get("nobugsnag") != null){
  // skip loading bugsnag
} else  {
  // Bugsnag says it is ok for this API key to be in clear text.
  window.bugsnagClient = bugsnag({
    apiKey: '383e8ae25d86067526a79b5af8d81a76',
    notifyReleaseStages: ['production', 'review_app'],
    releaseStage: bugsnagStage,
    logger: null
  });
}
