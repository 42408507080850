import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentForm", "paymentDisplay", "contactForm"];
  static values = {
    showUpdateForm: Boolean
  }

  connect() {
    if (this.showUpdateFormValue) {
      this.showPaymentUpdate();
    } else {
      this.hidePaymentUpdate();
    }

    if (this.contactFormTarget.dataset['hasErrors'] === 'true') {
      this.showContactUpdate();
    }
  }

  showPaymentUpdate() {
    if (this.hasPaymentDisplayTarget) {
      this.paymentDisplayTarget.classList.add("hidden");
    }

    if (this.hasPaymentFormTarget) {
      this.paymentFormTarget.classList.remove("hidden");
    }
  }

  hidePaymentUpdate() {
    if (this.hasPaymentDisplayTarget) {
      this.paymentDisplayTarget.classList.remove("hidden");
    }

    if (this.hasPaymentFormTarget) {
      this.paymentFormTarget.classList.add("hidden");
    }
  }

  showContactUpdate() {
    this.contactFormTarget.querySelectorAll('button').forEach(e => e.classList.toggle('hidden'));
    let emailInput = this.contactFormTarget.querySelector('input[type="email"]');
    emailInput.disabled = false;
    emailInput.focus();
  }

  hideContactUpdate() {
    this.contactFormTarget.querySelectorAll('button').forEach(e => e.classList.toggle('hidden'))
    let emailInput = this.contactFormTarget.querySelector('input[type="email"]');
    emailInput.disabled = true;
    // reset the value back to the current value in stripe
    emailInput.value = emailInput.dataset['currentValue'];
  }
}
