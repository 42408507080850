import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title", "message", "qrcode", "token"];

  showQrcode(event) {
    event.preventDefault();

    this.qrcodeTarget.classList.remove("hidden");
    this.tokenTarget.classList.add("hidden");
  }

  showToken(event) {
    event.preventDefault();

    this.qrcodeTarget.classList.add("hidden");
    this.tokenTarget.classList.remove("hidden");
  }
}
