// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";

const application = Application.start();

import controllers from "./**/*_controller.js";
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default);
});

// In some stimulus controllers we want to get the DOM back into a non-mutated
// state before Turbo caches the page for quick restoration. Things like
// checkboxes being checked, or menus being open. This happens often enough
// where we can create a custom lifecycle method called teardown
document.addEventListener("turbo:before-cache", () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.teardown === "function") {
      controller.teardown();
    }
  });
});
