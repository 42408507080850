import { Controller } from "@hotwired/stimulus"
import { toggle } from '../../global/utils'

export default class extends Controller {
  static targets = ["elementToToggle"]
  static values = {
    toggleText: String
  }

  toggle(event) {
    event.preventDefault();
    let originalText = event.target.textContent
    let replacementText = this.toggleTextValue

    toggle(this.elementToToggleTarget)
    this.toggleTextValue = originalText
    event.target.textContent = replacementText
  }
}
