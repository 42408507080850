import { Controller } from '@hotwired/stimulus';
import consumer from './../../../channels';

export default class extends Controller {
  connect() {
    this.vantaSyncSubscription = consumer.subscriptions.create(
      { channel: 'VantaIntegrationSyncChannel' },
      {
        received: (_data) => {
          window.location.reload();
        },
      },
    )
  }

  disconnect() {
    if (typeof this.vantaSyncSubscription !== 'undefined') {
      this.vantaSyncSubscription.unsubscribe();
    }
  }
}
