import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["headerInput", "bodyInput", "signInRadio", "signInMethods", "customResourceSectionCheckbox", "customResourceSectionFields", "customizationSection", "customizationSectionPreviewLink" ];

  connect() {
    if (this.hasSignInMethodsTarget) {
      this.checkSignInMethodVisibility();
      this.updatePreviewLinkHeader();
      this.updatePreviewLinkBody();
    }

    this.checkCustomResourceSectionVisibility();
  }

  updatePreviewLinkHeader() {
    let value = this.headerInputTarget.value
    let href = new URL(this.customizationSectionPreviewLinkTarget.href)
    href.searchParams.set('custom_resource_section_header', value)
    this.customizationSectionPreviewLinkTarget.href = href.toString()
  }

  updatePreviewLinkBody() {
    let value = this.bodyInputTarget.value
    let href = new URL(this.customizationSectionPreviewLinkTarget.href)
    href.searchParams.set('custom_resource_section_body', value)
    this.customizationSectionPreviewLinkTarget.href = href.toString()
  }

  checkSignInMethodVisibility() {
    let value = this.signInRadioTargets.find(r => r.checked).value

    if (value == "true") {
      this.signInMethodsTarget.style.display = "block"
    } else {
      this.signInMethodsTarget.style.display = "none"
    }
  }

  checkCustomResourceSectionVisibility() {
    if (this.customResourceSectionCheckboxTarget.checked) {
      this.customizationSectionTarget.classList.add("no-border")
      this.customResourceSectionFieldsTarget.style.display = ""
      this.customizationSectionPreviewLinkTarget.style.display = ""
    } else {
      this.customizationSectionTarget.classList.remove("no-border")
      this.customResourceSectionFieldsTarget.style.display = "none"
      this.customizationSectionPreviewLinkTarget.style.display = "none"
    }
  }
}
