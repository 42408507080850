import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['input', 'dropzone', 'warning', 'form'];
  static values = {
    certificateValidationUrl: String,
  }
  static initialContent = ''

  connect() {
    this.initialContent = this.dropzoneTarget.innerHTML
  }


  validate() {
    let reader = new FileReader()
    this.dropzoneTarget.innerHTML = `Reading File...`
    reader.onloadend = () => {
      this.dropzoneTarget.innerHTML = `Verifying Certificate...`
      let certContent = reader.result
      fetch(this.certificateValidationUrlValue, {
        method: 'post',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
        body: JSON.stringify({ certificate: certContent }),
      })
        .then((response) => {
          if (response.ok) {
            this.dropzoneTarget.classList.remove("invalid")
            this.dropzoneTarget.classList.add("success")
            let file = this.inputTarget.files[0]
            let filename = file.name
            let svg = `<svg height="15" class="kolidecon kolidecon-check" viewBox="0 0 24 24" version="1.1" width="15" aria-hidden="true"><path fill-rule="evenodd" d="M4.48 12.301a1.342 1.342 0 0 1 1.79.066L9 14.996l9.145-9.641c.38-.4.99-.47 1.45-.169a.896.896 0 0 1 .228 1.284l-9.614 12.925a1.5 1.5 0 0 1-2.45-.06l-3.533-5.269a1.33 1.33 0 0 1 .254-1.765z"></path></svg>`
            this.dropzoneTarget.innerHTML = `<span class="icon">${svg}</span>${filename}`
            this.enableSubmit()
          } else {
            this.dropzoneTarget.innerHTML = this.initialContent;
            this.dropzoneTarget.classList.add("invalid")
            this.dropzoneTarget.classList.remove("success")

            let svg = `<svg class="kolidecon kolidecon-alert" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path d="M11.367 3.17a1.26 1.26 0 0 1 1.726.463l8.703 15.084A1.518 1.518 0 0 1 20.485 21H3.515a1.524 1.524 0 0 1-1.311-2.283l8.703-15.084c.11-.192.27-.351.46-.462zM12 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm0-7.333a.94.94 0 0 0-.938.998l.23 3.67a.71.71 0 0 0 1.416 0l.23-3.67.001-.059a.94.94 0 0 0-.939-.94z"></path></svg>`
            let warning = document.createElement("div")
            warning.insertAdjacentHTML('beforeend', svg)
            warning.appendChild(document.createTextNode("Not a valid certificate file!"))
            this.dropzoneTarget.insertAdjacentElement("afterbegin", warning)
            this.disableSubmit()
          }
        })
    }

    let file = this.inputTarget.files[0]
    reader.readAsBinaryString(file)
  }

  disableSubmit() {
    let submit = this.formTarget.querySelector("input[type='submit']")
    submit.disabled = true
  }

  enableSubmit() {
    let submit = this.formTarget.querySelector("input[type='submit']")
    submit.disabled = false
  }
}
