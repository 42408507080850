import { Controller } from "@hotwired/stimulus";
import copy from 'copy-to-clipboard';
import { fadeInAndOut } from '../../../global/utils'

export default class extends Controller {
  static targets = ["copyValue", "copyMessage"];

  copy(event) {
    event.preventDefault();
    copy(this.copyValueTarget.textContent.trim());
    fadeInAndOut(this.copyMessageTarget, 'fast');
  }
}
