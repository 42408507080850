import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["callout", "trigger"]

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.triggerTarget.checked) {
      this.calloutTarget.style.display = "block"
    } else {
      this.calloutTarget.style.display = "none"
    }
  }
}
