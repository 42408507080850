import { Controller } from "@hotwired/stimulus"
import VanillaTilt from 'vanilla-tilt';

export default class extends Controller {
  static values = {
    glare: { type: Boolean, default: true },
    maxGlare: { type: Number, default: 0.4 },
    max: { type: Number, default: 8 },
    perspective: { type: Number, default: 2000 },
    speed: { type: Number, default: 450 },
    transition: { type: Boolean, default: true },
    startY: { type: Number, default: 0 },
    startX: { type: Number, default: 0 },
    reset: { type: Boolean, default: true },
    scale: { type: Number, default: 1 },
  }

  connect() {
    const options = {
      glare: this.glareValue,
      "max-glare": this.maxGlareValue,
      max: this.maxValue,
      perspective: this.perspectiveValue,
      speed: this.speedValue,
      transition: this.transitionValue,
      startY: this.startYValue,
      startX: this.startXValue,
      reset: this.resetValue,
      scale: this.scaleValue,
    }
    VanillaTilt.init(this.element, options)
  }
}
