import { Controller } from '@hotwired/stimulus';

// Whichever element comes first in the dom is hidden by default...
// Subsequent calls to `toggle` will swap the visibility of the two swappable targets.
// If more than two swappable targets exist, only the first two are recognized.
export default class extends Controller {
  static targets = ['swappable'];

  toggle() {
    const [first, second, ] = this.swappableTargets;
    first.classList.toggle('hidden');
    second.classList.toggle('hidden');
  }
}
