var scrollTops = new WeakMap();

let findElements = () => {
  return document.querySelectorAll("[data-turbo-permanent]");
}

document.addEventListener("turbo:before-render", function() {
  findElements().forEach(function(element) {
    scrollTops.set(element, element.scrollTop);
  })
});

document.addEventListener("turbo:render", function() {
  findElements().forEach(function(element) {
    if (scrollTops.has(element)) {
      element.scrollTop = scrollTops.get(element);
    }
  });
});
