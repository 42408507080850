import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["templateText", "confirmationInput", "actionButton", "optionalCheckbox"];

  checkMatch() {
    let expectedValue =
      this.templateTextTarget.textContent.trim().replace(/\n/g, '').replace(/  +/g, ' ');
    let actualValue = this.confirmationInputTarget.value.trim();
    let optionalsChecked = true;

    if (this.hasOptionalCheckboxTarget) {
      this.optionalCheckboxTargets.forEach(el => {
        if (!el.checked) {
          optionalsChecked = false;
          return;
        }
      });
    }
    this.actionButtonTarget.disabled = !((actualValue == expectedValue) && optionalsChecked);
  }
}
