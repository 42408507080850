import $ from 'jquery'

const kModal = {
  class: 'kolide-modal',

  $ModalOverlay: null,
  $ModalWrapper: null,

  content: null,

  config: {},
  defaultConfig: {
    href: '',
    launchingUrl: '',
    title: '',
    namespace: '',
    content: '',
    ujs: false,
    ajax: false,
    size: 'small',
    appendTo: 'body',
    autoClose: false,
  },

  modalSelect: (not) => {
    const { namespace } = kModal.config;

    if (namespace) {
      $(`${kModal.class}.${namespace}`);
    }

    return $(`.${kModal.class}`).not(not);
  },

  toggle: () => kModal.modalSelect().toggleClass(`${kModal.class}--active`),

  show: () => {
    kModal.modalSelect().removeClass('animate__fadeOut animate__animated hidden')
    kModal.modalSelect().addClass(`${kModal.class}--active`)
  },

  hide: () => {
    kModal.modalSelect('.hidden').last().addClass('animate__fadeOut animate__animated')
    setTimeout(function(){
      kModal.modalSelect('.hidden').last().removeClass(`${kModal.class}--active`);
      kModal.modalSelect('.hidden').last().addClass(`hidden`);
    }, 500)
  },

  hideAll: () => {
    kModal.$ModalOverlay.removeClass(`${kModal.class}--active`);
  },

  remove: () => {
    kModal.$ModalOverlay.remove();
  },

  getContent: (href) => {
    $.ajax({
      url: href,
      headers: { 'Kolide-Modal': true, 'X-Requested-With': 'XMLHttpRequest' },
      type: 'GET',
    }).done((data) => {
      kModal.buildContent(data);
    }).fail(() => {
      kModal.remove();
    });
  },

  buildContent: (content) => {
    const { title } = kModal.config;
    const { class: klass, config: { namespace, size } } = kModal;

    const Header = $(`<header class="${kModal.class}__header k-header"><h1></h1></header>`);
    const Content = $(`<div class="${kModal.class}__content"></div>`);

    if (title) {
      Header.find('h1').append(title);
    }

    Content.append(content);

    kModal.content = Content;
    kModal.$ModalOverlay.addClass(`${klass}--active ${namespace}`);
    kModal.$ModalOverlay.append(content);
  },

  buildModal: () => {
    const { class: klass } = kModal;
    const { appendTo } = kModal.config;

    let klasses;
    if (kModal.config.ujs) {
      klasses = `${klass} animate__animated animate__fadeIn animate__faster`
    } else {
      klasses = `${klass}`
    }

    let controllers;
    let actions;
    if (kModal.config.autoClose) {
      controllers = `modal-close modal-auto-close`
      actions = `mousedown->modal-auto-close#closeModal keyup@window->modal-auto-close#keyUpHandler`
    } else {
      controllers = `modal-close`
    }

    kModal.$ModalOverlay = $('<div />', { class: klasses, 'data-controller': controllers, 'data-action': actions, 'data-modal-auto-close-exit-url-value': kModal.config.launchingUrl, 'data-modal-close-exit-url-value': kModal.config.launchingUrl, 'data-modal-auto-close-target': 'container' });
    $(appendTo).append(kModal.$ModalOverlay);
  },

  createBtn: (options) => {
    const { style, content, action } = options;
    const $btn = $('<button>', { class: `button button--${style}` });

    $btn.html(content);
    $btn.off('.kModal').on('click.kModal', (evt) => {
      evt.preventDefault();
      action(evt, $(evt.currentTarget));
    });

    return $btn[0];
  },

  init: (options) => {
    kModal.config = {}; // we need to clear this out between inits
    kModal.config = $.extend({}, kModal.defaultConfig, options);

    if ($.contains(document, kModal.modalSelect())) {
      kModal.show();
    } else {
      kModal.buildModal();

      if (typeof kSidebar !== "undefined" && kSidebar.config.ajax) {
        kModal.getContent(kModal.config.href);
      } else {
        kModal.buildContent(kModal.config.content);
      }
    }
  },
};

module.exports = kModal;
