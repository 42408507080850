import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.style.display = 'none';

    // Holding alt/option and clicking links causes browsers to
    // download them. So let's modify that default behavior with JS
    this.element.querySelectorAll('a').forEach(a => {
      a.addEventListener('click', function (e) {
        if (e.altKey) {
          e.preventDefault(); // no download
          this.click(); // fake a normal click
        }
      });
    });
  }

  show(event) {
    // option key on Mac or alt key on Windows
    if (event.which == 18) {
      this.element.style.display = "";
    }
  }

  hide(event) {
    if (event.which == 18) {
      this.element.style.display = "none";
    }
  }
}
