import { Controller } from "@hotwired/stimulus"
import { fadeIn, fadeOut } from '../../global/utils'

export default class extends Controller {
  static values = {
    html: String,
  }

  replace(event) {
    event.preventDefault()
    let element = this.element
    fadeOut(element).then(() => {
      element.outerHTML = this.htmlValue
      fadeIn(element)
    })
  }
}
