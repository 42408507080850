import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selector", "stringFields", "sqlFields"]

  connect(event) {
    this.revealRelevantFields()
  }

  revealRelevantFields() {
    let value = this.selectorTarget.value

    if ( value == "string_based") {
      this.stringFieldsTarget.style.display = ''
      this.sqlFieldsTarget.style.display = 'none'
    } else if (value == "sql_based") {
      this.sqlFieldsTarget.style.display = ''
      this.stringFieldsTarget.style.display = 'none'
    }
  }
}
