import { Controller } from "@hotwired/stimulus"
import confetti from "canvas-confetti";

export default class extends Controller {
  connect() {
    confetti({
      particleCount: 200,
      spread: 170,
      origin: { y: 0.6 }
    });
  }
}
