import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import { throttle } from "lodash";

export default class extends Controller {
  static values = { disable: String };
  static targets = [
    "form",
    "input",
    "blockingSubmitHiddenInput"
  ];

  // Throttling prevents too many saves from going through at once
  // it also helps simulate the latency of prod in development in certain
  // situations
  submit = throttle((e) => {
    // if we have an item to be disabled during a save, do it
    if (this.disableValue) {
      // find the element by id
      const element = document.getElementById(this.disableValue);
      if (element) {
        element.setAttribute('disabled', 'true');
        element.innerHTML = "Saving Changes...";
      }
    }

    Rails.fire(this.formTarget, 'submit');

  }, 400)

  blockingSubmit() {
    this.inputTargets.forEach(element => {
      element.readOnly = true;
    });
    this.blockingSubmitHiddenInputTarget.disabled = false;
    Rails.fire(this.formTarget, 'submit');
    this.blockingSubmitHiddenInputTarget.disabled = true;
  }
}
