import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  toggle(event) {
    let value = event.target.value == "true"
    this.fieldTargets.forEach(field => field.disabled = value)
  }
}
