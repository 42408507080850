import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ["form"]

  submit() {
    this.formTarget.submit();
  }

  // legacy method for UJS actions. Do not use for new forms.
  submitForm() {
    Rails.fire(this.formTarget, 'submit');
  }
}
