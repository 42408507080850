import { Controller } from "@hotwired/stimulus";
import isEqual from "lodash/isEqual";

export default class extends Controller {
  static targets = [
    "itemCheckbox",
    "globalCheckbox",
    "globalSearch",
    "count",
    "check",
    "checkIds",
    "statusMenu",
    "actions",
    "hiddenFields",
    "form",
    "loader",
    "tagsMenuContainer",
    "tagsMenu",
    "tagSearch",
    "checkTagItem",
    "notificationStrategyMenuContainer",
    "notificationStrategyMenu",
    "notificationStrategy",
    "blockingStrategyMenuContainer",
    "blockingStrategyMenu",
    "notifyOnlyStrategyGracePeriod",
    "warnThenBlockStrategyGracePeriod",
    "blockingStrategyGracePeriod",
    "blockingStrategy",
    "notificationAdminEscalationDayCheckbox",
    "autoEscalateUnassignedDevicesCheckbox",
    "personNotificationStrategySettings",
    "results",
    "original"
  ];

  selectedChecks = []
  selectedTags = []
  selectedTagStates = {}
  selectedBlockingStrategies = []
  selectedBlockingStrategyStates = {}
  selectedBlockingGracePeriodDays = []
  selectedNotifyGracePeriodDays = []
  selectedNotificationStrategies = []
  selectedNotificationStrategyStates = {}
  selectedNotificationAdminEscalationDays = []
  selectedNotificationAdminEscalationDayStates = {}
  selectedAutoEscalateUnassignedValues = []
  selectedAutoEscalateUnassignedState = {}

  connect() {
    this.setVisibility()

    this.afterSearchEvent = (event) => {
      this.maintainCheckboxState();
      this.setVisibility()
    }

    window.addEventListener("checks:search-results-loaded", this.afterSearchEvent);
    window.addEventListener("checks:search-cleared", this.afterSearchEvent)
  }

  afterSearchEvent() {
  }

  maintainCheckboxState() {
    this.visibleTotalChecks().forEach((check) => {
      let checkbox = check.querySelector("input[type=checkbox]");
      if (this.selectedChecks.hasOwnProperty(check.dataset.checkId)) {
        checkbox.checked = true
      } else {
        checkbox.checked = false
      }
    })
  }

  checksContainer() {
    return this.globalSearchTarget.value ? this.resultsTarget : this.originalTarget
  }

  setVisibility() {
    let visibleChecksCount = this.visibleSelectedChecks().length
    let visibleTotalCheckCount = this.visibleTotalChecks().length

    if (visibleChecksCount == 0) {
      this.actionsTarget.style.display = "none"
      this.countTarget.style.display = "none"
      this.statusMenuTarget.style.display = "flex"
      this.globalCheckboxTarget.checked = false
      this.globalCheckboxTarget.indeterminate = false
    } else {
      this.actionsTarget.style.display = "flex"
      this.countTarget.innerHTML = `${visibleChecksCount} Selected`
      this.countTarget.style.display = "block"
      this.statusMenuTarget.style.display = "none"
      if (visibleChecksCount == visibleTotalCheckCount) {
        this.globalCheckboxTarget.checked = true
        this.globalCheckboxTarget.indeterminate = false
      } else {
        this.globalCheckboxTarget.checked = true
        this.globalCheckboxTarget.indeterminate = true
      }
    }
    this.calculateCountFrequencies()
    this.populateChecksIntoHiddenFields()
  }

  calculateCountFrequencies() {
    let selectedTagCounts = this.selectedTags.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    this.selectedTagStates = Object.fromEntries(
      Object.entries(selectedTagCounts)
        .map(([key, val]) => [key, val == this.visibleSelectedChecks().length ? "checked" : "indeterminate"])
    );

    let selectedNotificationStrategyCounts = this.selectedNotificationStrategies.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    this.selectedNotificationStrategyStates = Object.fromEntries(
      Object.entries(selectedNotificationStrategyCounts)
        .map(([key, val]) => [key, val == this.visibleSelectedChecks().length ? "checked" : "indeterminate"])
    );

    let selectedBlockingStrategyCounts = this.selectedBlockingStrategies.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    this.selectedBlockingStrategyStates = Object.fromEntries(
      Object.entries(selectedBlockingStrategyCounts)
        .map(([key, val]) => [key, val == this.visibleSelectedChecks().length ? "checked" : "indeterminate"])
    );

    let selectedNotificationAdminEscalationDayCounts = this.selectedNotificationAdminEscalationDays.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    this.selectedNotificationAdminEscalationDayStates = Object.fromEntries(
      Object.entries(selectedNotificationAdminEscalationDayCounts)
        .map(([key, val]) => [key, val == this.visibleSelectedChecks().length ? "checked" : "indeterminate"])
    );

    let selectedAutoEscalateUnassignedCounts = this.selectedAutoEscalateUnassignedValues.reduce(function (c, p) {
      c[p] = (c[p] || 0) + 1;
      return c;
    }, Object.create(null));

    if (Object.keys(selectedAutoEscalateUnassignedCounts).length > 1) {
      this.selectedAutoEscalateUnassignedState = "indeterminate"
    } else if (Object.keys(selectedAutoEscalateUnassignedCounts)[0] == 'true') {
      this.selectedAutoEscalateUnassignedState = "checked"
    } else {
      this.selectedAutoEscalateUnassignedState = null
    }
  }

  populateChecksIntoHiddenFields() {
    this.hiddenFieldsTarget.innerHTML = "";
    this.checkIdsTarget.innerHTML = "";
    this.visibleSelectedChecks().forEach((check) => {
      let template = `<input multiple="multiple" value="${check.dataset.checkId}" type="hidden" name="check_ids[]">`;
      this.hiddenFieldsTarget.innerHTML += template
      this.checkIdsTarget.innerHTML += template
    })
  }

  visibleSelectedChecks() {
    return this.visibleTotalChecks().filter(check => this.selectedChecks.hasOwnProperty(check.dataset.checkId))
  }

  visibleTotalChecks() {
    return Array.from(this.checksContainer().children)
  }

  toggleGlobalCheckbox(event) {
    if (event.target.checked) {
      // remove all existing items first
      this.visibleSelectedChecks().forEach((check) => {
        this.removeItem(check)
      })

      this.visibleTotalChecks().forEach((check) => {
        this.addItem(check)
        check.querySelector('input[data-checks--mass-actions-target="itemCheckbox"]').checked = true
      })
    } else {
      this.visibleTotalChecks().forEach((check) => {
        this.removeItem(check)
        check.querySelector('input[data-checks--mass-actions-target="itemCheckbox"]').checked = false
      })
    }

    this.setVisibility()
  }

  toggleItem(event) {
    let check = event.target.parentElement.parentElement
    if (event.target.checked) {
      this.addItem(check)
    } else {
      this.removeItem(check)
    }
    this.setVisibility()
  }

  addItem(check) {
    let {
      checkId: id,
      checkNotificationStrategy: notificationStrategy,
      checkNotificationAdminEscalationDays: notificationAdminEscalationDays,
      checkAutoEscalateUnassigned: autoEscalateUnassigned,
      blockingGracePeriod = parseInt(check.dataset.checkBlockingGracePeriodDays),
      notifyGracePeriod = parseInt(check.dataset.checkNotifyGracePeriodDays),
    } = check.dataset;

    let checkTags = JSON.parse(check.dataset.checkTags);

    this.selectedChecks[id] = {
      id,
      notificationStrategy,
      autoEscalateUnassigned,
      checkTags
    };

    this.selectedNotificationStrategies.push(check.dataset.checkNotificationStrategy)
    this.selectedBlockingStrategies.push(check.dataset.checkBlockingStrategy)
    if (blockingGracePeriod !== NaN) {
      this.selectedBlockingGracePeriodDays.push(blockingGracePeriod)
    }
    if (notifyGracePeriod !== NaN) {
      this.selectedNotifyGracePeriodDays.push(notifyGracePeriod)
    }
    this.selectedNotificationAdminEscalationDays.push(check.dataset.checkNotificationAdminEscalationDays)
    this.selectedAutoEscalateUnassignedValues.push(check.dataset.checkAutoEscalateUnassigned)
    this.selectedTags = this.selectedTags.concat(checkTags)
  }

  removeItem(check) {
    delete this.selectedChecks[check.dataset.checkId];

    let blockingStrategyIndex = this.selectedBlockingStrategies.findIndex(v => v == check.dataset.checkBlockingStrategy)
    delete this.selectedBlockingStrategies[blockingStrategyIndex]

    let blockingStrategyGPIndex = this.selectedBlockingGracePeriodDays.findIndex(v => v == check.dataset.checkBlockingStrategy)
    delete this.selectedBlockingStrategies[blockingStrategyGPIndex]

    let notifyStrategyGPIndex = this.selectedNotifyGracePeriodDays.findIndex(v => v == check.dataset.checkNotifyStrategy)
    delete this.selectedNotifyGracePeriodDays[notifyStrategyGPIndex]

    let notificationStrategyIndex = this.selectedNotificationStrategies.findIndex(v => v == check.dataset.checkNotificationStrategy)
    delete this.selectedNotificationStrategies[notificationStrategyIndex]

    let notificationAdminEscalationDaysIndex = this.selectedNotificationAdminEscalationDays.findIndex(v => v == check.dataset.checkNotificationAdminEscalationDays)
    delete this.selectedNotificationAdminEscalationDays[notificationAdminEscalationDaysIndex]

    let adminEscalateUnassingedIndex = this.selectedAutoEscalateUnassignedValues.findIndex(v => v == check.dataset.checkAutoEscalateUnassigned)
    delete this.selectedAutoEscalateUnassignedValues[adminEscalateUnassingedIndex]

    let checkTags = JSON.parse(check.dataset.checkTags)
    checkTags.forEach((tag) => {
      let index = this.selectedTags.findIndex(v => v == tag)
      delete this.selectedTags[index]
    })
  }

  toggleTagsMenu() {
    if (this.tagsMenuTarget.classList.contains("open")) {
      this.closeTagsMenu()
    } else {
      this.openTagsMenu()
    }
  }

  openTagsMenu() {
    this.tagsMenuTarget.classList.add('open');
    this.tagSearchTarget.focus()
    this.checkTagItemTargets.forEach(tag => {
      let tagId = tag.dataset.checkTagId
      tag.classList.remove("indeterminate")
      tag.classList.remove("checked")
      if (this.selectedTagStates.hasOwnProperty(tagId)) {
        tag.classList.add(this.selectedTagStates[tagId])
      }
    })
  }

  closeTagsMenu() {
    this.tagsMenuTarget.classList.remove('open');
    this.submitFormIfTagsChanged()
  }

  hideTagsMenu(event) {
    if (!this.tagsMenuContainerTarget.contains(event.target) && this.tagsMenuTarget.classList.contains("open")) {
      this.closeTagsMenu()
    }
  }

  submitFormIfTagsChanged() {
    let state = {}
    this.checkTagItemTargets.forEach(tag => {
      if (tag.classList.contains("checked")) {
        state[tag.dataset.checkTagId] = "checked"
      } else if (tag.classList.contains("indeterminate")) {
        state[tag.dataset.checkTagId] = "indeterminate"
      } else if (tag.classList.contains("unchecked")) {
        state[tag.dataset.checkTagId] = "unchecked"
      }
    })

    if (!isEqual(state, this.selectedTagStates)) {
      Object.entries(state).forEach(tag => {
        const [id, state] = tag;
        if (state == "checked") {
          this.hiddenFieldsTarget.innerHTML += `<input multiple="multiple" value="1" type="hidden" name="triage_tags[${id}]">`
        } else if (state == "unchecked") {
          this.hiddenFieldsTarget.innerHTML += `<input multiple="multiple" value="0" type="hidden" name="triage_tags[${id}]">`
        }
      })
      this.submitForm()
    }
  }

  searchTags = this.throttle(() => {
    const searchValueArray = this.tagSearchTarget.value.trim().split("");
    const searchRegex = new RegExp(`${searchValueArray.join("(.*)")}`, "i");

    this.checkTagItemTargets.forEach(option => {
      if (
        option.dataset.filterSearch &&
        !option.dataset.filterSearch.match(searchRegex)
      ) {
        option.style.display = "none"
      } else {
        option.style.display = "block"
      }
    });
  }, 250);

  toggleTag(event) {
    if (event.currentTarget.classList.contains("indeterminate")) {
      event.currentTarget.classList.remove("indeterminate")
      event.currentTarget.classList.add("unchecked")
    } else {
      event.currentTarget.classList.toggle("checked")
      event.currentTarget.classList.toggle("unchecked")
    }
  }

  throttle(func, wait) {
    return () => {
      let boundFunc = func.bind(this, arguments);
      setTimeout(boundFunc, wait);
    };
  }

  toggleBlockingStrategyMenu() {
    if (this.blockingStrategyMenuTarget.classList.contains("open")) {
      this.closeBlockingStrategyMenu()
    } else {
      this.openBlockingStrategyMenu()
    }
  }

  openBlockingStrategyMenu() {
    this.blockingStrategyMenuTarget.classList.add('open');

    this.notifyOnlyStrategyGracePeriodTarget.value = Math.max(...this.selectedNotifyGracePeriodDays);
    this.warnThenBlockStrategyGracePeriodTarget.value = this.notifyOnlyStrategyGracePeriodTarget.value;
    const blockingGracePeriodOptions = [
      Math.max(...this.selectedBlockingGracePeriodDays),
      this.notifyOnlyStrategyGracePeriodTarget.value
    ];
    this.blockingStrategyGracePeriodTarget.value = Math.max(...blockingGracePeriodOptions);
    this.blockingStrategyGracePeriodTarget.max = Math.min(...blockingGracePeriodOptions);
    this.blockingStrategyTargets.forEach(strategy => {
      let strategyEnum = strategy.dataset.blockingStrategyEnum;
      strategy.classList.remove("indeterminate");
      strategy.classList.remove("checked");
      if (this.selectedBlockingStrategyStates.hasOwnProperty(strategyEnum)) {
        strategy.classList.add(this.selectedBlockingStrategyStates[strategyEnum]);
      }
    })
  }

  updateBlockingStrategyMinimum(e) {
    const { value } = e.target;
    this.blockingStrategyGracePeriodTarget.min = value;

    if (this.blockingStrategyGracePeriodTarget.value < value) {
      this.blockingStrategyGracePeriodTarget.value = value;
    }
  }

  closeBlockingStrategyMenu() {
    this.blockingStrategyMenuTarget.classList.remove('open');
    this.submitBlockingStrategyIfChanged();
  }

  hideBlockingStrategyMenu(event) {
    if (!this.blockingStrategyMenuContainerTarget.contains(event.target) && this.blockingStrategyMenuTarget.classList.contains("open")) {
      this.closeBlockingStrategyMenu()
    }
  }

  toggleBlockingStrategy(event) {
    this.blockingStrategyTargets.forEach(strategy => {
      strategy.classList.remove("indeterminate")
      strategy.classList.remove("checked")
    })
    this.currentSelectedBlockingStrategy = event.currentTarget.blockingStrategyEnum;
    event.currentTarget.classList.add("checked")
  }

  submitBlockingStrategyIfChanged() {
    let shouldSubmitForm = false;

    let blockingStrategyState = {};
    this.blockingStrategyTargets.forEach(strategy => {
      if (strategy.classList.contains("checked")) {
        blockingStrategyState[strategy.dataset.blockingStrategyEnum] = "checked"
      } else if (strategy.classList.contains("indeterminate")) {
        blockingStrategyState[strategy.dataset.blockingStrategyEnum] = "indeterminate"
      }
    })

    let newStrategy = Object.keys(blockingStrategyState)[0];

    let selectedBlockingGracePeriod = this.blockingStrategyGracePeriodTarget.value,
      uniqueSelectedBlockingGracePeriods = new Set(this.selectedBlockingGracePeriodDays),
      selectedWarnThenBlockGracePeriod = this.warnThenBlockStrategyGracePeriodTarget.value,
      selectedNotifyOnlyGracePeriod = this.notifyOnlyStrategyGracePeriodTarget.value,
      uniqueSelectedNotifyGracePeriods = new Set(this.selectedNotifyGracePeriodDays);

    if (!isEqual(blockingStrategyState, this.selectedBlockingStrategyStates)) {
      // Only one strategy can be selected at a time, so if the state doesn't
      // match, that means there is only one strategy to grab here.
      shouldSubmitForm = true
    }

    // We always need to tell the backend what strategy to use, even if it's
    // the same as the current strategy. This is because the grace period may
    // have changed.
    this.hiddenFieldsTarget.innerHTML += `<input value="${newStrategy}" type="hidden" name="triage_remediation_strategy">`

    // This was tough to sort out, so here's a comment on this logic...
    // If there is more than one grace period amongst the checks we've selected, then the we are trying to change the grace period.
    // If there is only one, and it differs from the checks we've chosen we're trying to change the grace period.
    let blockingGracePeriodChanged = (uniqueSelectedBlockingGracePeriods.size > 1 || !uniqueSelectedBlockingGracePeriods.has(selectedBlockingGracePeriod)) && selectedBlockingGracePeriod != null
    let warnThenBlockGracePeriodChanged = (uniqueSelectedNotifyGracePeriods.size > 1 || !uniqueSelectedNotifyGracePeriods.has(selectedWarnThenBlockGracePeriod)) && selectedWarnThenBlockGracePeriod != null

    if (newStrategy == "warn_then_block" && (blockingGracePeriodChanged || notifyGracePeriodChanged)) {
      shouldSubmitForm = true

      if (selectedBlockingGracePeriod != null) {
        this.hiddenFieldsTarget.innerHTML += `<input value="${selectedBlockingGracePeriod}" type="hidden" name="triage_blocking_strategy_grace_period">`
      }

      if (selectedWarnThenBlockGracePeriod != null) {
        this.hiddenFieldsTarget.innerHTML += `<input value="${selectedWarnThenBlockGracePeriod}" type="hidden" name="triage_warn_then_block_strategy_grace_period">`
      }
    }

    let notifyGracePeriodChanged = (uniqueSelectedNotifyGracePeriods.size > 1 || !uniqueSelectedNotifyGracePeriods.has(selectedNotifyOnlyGracePeriod)) && selectedNotifyOnlyGracePeriod != null
    if (newStrategy == 'notify_only' && notifyGracePeriodChanged) {
      shouldSubmitForm = true

      this.hiddenFieldsTarget.innerHTML += `<input value="${selectedNotifyOnlyGracePeriod}" type="hidden" name="triage_notify_only_block_strategy_grace_period">`
    }

    if (shouldSubmitForm) {
      this.submitForm()
    }
  }

  toggleNotificationStrategyMenu() {
    if (this.notificationStrategyMenuTarget.classList.contains("open")) {
      this.closeNotificationStrategyMenu()
    } else {
      this.openNotificationStrategyMenu()
    }
  }

  toggleNotificationStrategy(event) {
    this.notificationStrategyTargets.forEach(strategy => {
      strategy.classList.remove("indeterminate")
      strategy.classList.remove("checked")
    })

    if (event.currentTarget.classList.contains("indeterminate")) {
      event.currentTarget.classList.remove("indeterminate")
    } else {
      event.currentTarget.classList.toggle("checked")
    }

    if (event.currentTarget.dataset.notificationStrategyEnum == "person" && event.currentTarget.classList.contains("checked")) {
      this.personNotificationStrategySettingsTarget.style.display = "block"
    } else {
      this.personNotificationStrategySettingsTarget.style.display = "none"
    }
  }

  openNotificationStrategyMenu() {
    this.notificationStrategyMenuTarget.classList.add('open');
    this.notificationStrategyTargets.forEach(strategy => {
      let strategyEnum = strategy.dataset.notificationStrategyEnum
      strategy.classList.remove("indeterminate")
      strategy.classList.remove("checked")
      if (this.selectedNotificationStrategyStates.hasOwnProperty(strategyEnum)) {
        strategy.classList.add(this.selectedNotificationStrategyStates[strategyEnum])
      }

      if (strategyEnum == "person" && ["checked", "indeterminate"].includes(this.selectedNotificationStrategyStates["person"])) {
        this.personNotificationStrategySettingsTarget.style.display = "block"
      } else {
        this.personNotificationStrategySettingsTarget.style.display = "none"
      }
    })

    this.notificationAdminEscalationDayCheckboxTargets.forEach(day => {
      let dayLabel = day.dataset.dayLabel
      day.checked = false
      day.indeterminate = false
      if (this.selectedNotificationAdminEscalationDayStates[dayLabel] == "checked") {
        day.checked = true
      } else if (this.selectedNotificationAdminEscalationDayStates[dayLabel] == "indeterminate") {
        day.indeterminate = true
      }
    })

    this.autoEscalateUnassignedDevicesCheckboxTarget.checked = false
    this.autoEscalateUnassignedDevicesCheckboxTarget.indeterminate = false
    if (this.selectedAutoEscalateUnassignedState == "indeterminate") {
      // We also set checked true here so that when the user clicks the
      // indeterminate state, the checkbox clears.
      this.autoEscalateUnassignedDevicesCheckboxTarget.checked = true
      this.autoEscalateUnassignedDevicesCheckboxTarget.indeterminate = true
    } else if (this.selectedAutoEscalateUnassignedState == "checked") {
      this.autoEscalateUnassignedDevicesCheckboxTarget.checked = true
    }
  }

  closeNotificationStrategyMenu() {
    this.notificationStrategyMenuTarget.classList.remove('open');
    this.submitFormIfNotificationStrategyChanged()
  }

  hideNotificationStrategyMenu(event) {
    if (!this.notificationStrategyMenuContainerTarget.contains(event.target) && this.notificationStrategyMenuTarget.classList.contains("open")) {
      this.closeNotificationStrategyMenu()
    }
  }

  submitFormIfNotificationStrategyChanged() {
    let shouldSubmitForm = false

    let notificationStrategyState = {}
    this.notificationStrategyTargets.forEach(strategy => {
      if (strategy.classList.contains("checked")) {
        notificationStrategyState[strategy.dataset.notificationStrategyEnum] = "checked"
      } else if (strategy.classList.contains("indeterminate")) {
        notificationStrategyState[strategy.dataset.notificationStrategyEnum] = "indeterminate"
      }
    })

    if (!isEqual(notificationStrategyState, this.selectedNotificationStrategyStates)) {
      // Only one strategy can be selected at a time, so if the state doesn't
      // match, that means there is only one strategy to grab here.
      let newStrategy = Object.keys(notificationStrategyState)[0];
      this.hiddenFieldsTarget.innerHTML += `<input value="${newStrategy}" type="hidden" name="triage_notification_strategy">`
      shouldSubmitForm = true
    }

    let notificationAdminEscalationDayState = {}
    this.notificationAdminEscalationDayCheckboxTargets.forEach(checkbox => {
      if (checkbox.indeterminate) {
        notificationAdminEscalationDayState[checkbox.dataset.dayLabel] = "indeterminate"
      } else if (checkbox.checked) {
        notificationAdminEscalationDayState[checkbox.dataset.dayLabel] = "checked"
      }
    })

    if (!isEqual(notificationAdminEscalationDayState, this.selectedNotificationAdminEscalationDayStates)) {
      // Only one strategy day can be selected at a time, so if the state doesn't
      // match, that means there is only one strategy to grab here.
      let newDay = Object.keys(notificationAdminEscalationDayState)[0];
      this.hiddenFieldsTarget.innerHTML += `<input value="${newDay}" type="hidden" name="triage_notification_admin_escalation_days">`
      shouldSubmitForm = true
    }

    let autoEscalateUnassignedState;
    if (this.autoEscalateUnassignedDevicesCheckboxTarget.indeterminate) {
      autoEscalateUnassignedState = "indeterminate"
    } else if (this.autoEscalateUnassignedDevicesCheckboxTarget.checked) {
      autoEscalateUnassignedState = "checked"
    }

    if (autoEscalateUnassignedState != this.selectedAutoEscalateUnassignedState) {
      let value = autoEscalateUnassignedState == "checked" ? "true" : "false"
      this.hiddenFieldsTarget.innerHTML += `<input value="${value}" type="hidden" name="triage_auto_escalate_unassigned">`
      shouldSubmitForm = true
    }

    if (shouldSubmitForm) {
      this.submitForm()
    }
  }

  toggleNotificationAdminEscalationDay(event) {
    this.notificationAdminEscalationDayCheckboxTargets.forEach(checkbox => {
      checkbox.indeterminate = false
      checkbox.checked = false
    })

    event.target.checked = true
  }

  // Do not cache the mutated state of the DOM
  teardown() {
    if (this.globalCheckboxTarget.checked) {
      this.globalCheckboxTarget.click();
    }
  }

  performSimpleAction(event) {
    this.hiddenFieldsTarget.innerHTML += `<input value="1" type="hidden" name="triage_${event.target.dataset.simpleAction}">`
    this.submitForm()
  }

  submitForm() {
    this.loaderTarget.style.display = "block"
    this.actionsTarget.setAttribute("disabled", "disabled")
    this.globalSearchTarget.setAttribute("disabled", "disabled")
    this.globalCheckboxTargets.forEach(c => c.setAttribute("disabled", "disabled"))
    this.itemCheckboxTargets.forEach(c => c.setAttribute("disabled", "disabled"))
    this.formTarget.submit()
  }

  disconnect() {
    window.removeEventListener("checks:search-results-loaded", this.afterSearchEvent);
    window.removeEventListener("checks:search-cleared", this.afterSearchEvent)
  }
}
