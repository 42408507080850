import { Controller } from "@hotwired/stimulus"
import { isVisible } from '../../../global/utils'

export default class extends Controller {
  static targets = [ "menu", "expander", "retractor" ]
  open() {
    this.menuTarget.addEventListener('animationend', e => e.target.classList.remove("animate__animated", "animate__slideInLeft", "animate__faster"), { once: true })
    this.menuTarget.classList.add("mobile-visible", "animate__animated", "animate__slideInLeft", "animate__faster")

  }

  maybeClose(event) {
    if (this.canClose(event)) {
      this.close()
    }
  }

  close() {
    this.menuTarget.addEventListener('animationend', e => e.target.classList.remove("animate__animated", "mobile-visible", "animate__slideOutLeft", "animate__faster"), { once: true })
    this.menuTarget.classList.add("animate__animated", "animate__slideOutLeft", "animate__faster")

  }

  canClose(event) {
    return isVisible(this.retractorTarget) && !this.menuTarget.contains(event.target) && !this.expanderTarget.contains(event.target)
  }
}
