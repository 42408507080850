import { Controller } from "@hotwired/stimulus"
import consumer from './../channels';

export default class extends Controller {
  static targets = ['statusMessage'];
  static values = {
    pipelineId: String,
    overviewPath: String,
  }

  connect() {
    if (this.hasPipelineIdValue) {
      let statusMessageTarget = this.statusMessageTarget;

      this.logPipelineSubscription = consumer.subscriptions.create(
        { channel: "LogPipelineSeedChannel", log_pipeline_id: this.pipelineIdValue },
        {
          received: (data) => {

            if (data.parenthetical_change) {
              statusMessageTarget.textContent = data.message
            } else {
              const animations = [
                'webkitAnimationEnd',
                'mozAnimationEnd',
                'MSAnimationEnd',
                'oanimationend',
                'animationend'
              ]
              statusMessageTarget.style.display = 'block'
              statusMessageTarget.classList.remove("animate__slideInLeft")
              statusMessageTarget.classList.add("animate__slideOutRight")

              animations.forEach(animation => {
                statusMessageTarget.addEventListener(animation, () => {
                  statusMessageTarget.textContent = data.message
                  statusMessageTarget.classList.remove("animate__slideOutRight")
                  statusMessageTarget.classList.add("animate__slideInLeft")
                }, { once: true })
              })
            }

            if (data.done_seeding) {
              setTimeout(function () {
                Turbo.visit(this.overviewPathValue)
              }, 5000)
            }
          },
        },
      );
    }
  }

  disconnect() {
    if (typeof this.logPipelineSubscription !== 'undefined') {
      this.logPipelineSubscription.unsubscribe();
    }
  }
}
