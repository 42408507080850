import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'addToSlackContainer', 'authorizationContainer', 'backToCheckSelection', 'backToAddToSlack'];
  static originalTitle;

  addToSlack() {
    this.originalTitle = document.title;
    document.title = "Need Help With Kolide Slack Approval?"
    this.backToCheckSelectionTarget.style.display = 'none';
    this.backToAddToSlackTarget.style.display = '';
    this.addToSlackContainerTarget.style.display = 'none';
    this.authorizationContainerTarget.style.display = '';
  }
}
