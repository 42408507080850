// From https://github.com/animate-css/animate.css/blob/main/docsSource/sections/04-javascript.md

export const animateCSS = (el, animation, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise((resolve) => {
    const animationName = `${prefix}${animation}`;

    el.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      el.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    }

    el.addEventListener('animationend', handleAnimationEnd, { once: true });
  });
