import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "user", "secretOption", "replacedBySecretOption" ]

  showSecretOptions(event) {
    if(event.which == 18) {
      this.secretOptionTarget.classList.add('visible')
      this.replacedBySecretOptionTarget.style.display = 'none'
    }
  }

  hideSecretOptions(event) {
    if(event.which == 18) {
      this.secretOptionTarget.classList.remove('visible')
      this.replacedBySecretOptionTarget.style.display = 'block'
    }
  }

  toggle() {
    this.menuTarget.classList.toggle("open")
    this.userTarget.classList.toggle("active")
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.remove("open")
      this.userTarget.classList.remove("active")
    }
  }
}
