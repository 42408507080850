import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String,
  }

  connect() {
    if (window.opener) {
      window.opener.location.reload();
      window.close();
    } else {
      Turbo.visit(this.urlValue)
    }
  }
}
