import { Controller } from "@hotwired/stimulus"
import consumer from './../channels';
import throttle from "lodash/throttle";

export default class extends Controller {

  static values = {
    channelId: Number
  }

  connect(){
    this.subscription = consumer.subscriptions.create(
      {
        channel: "PeopleMergingChannel",
        id: this.channelIdValue,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {
    // noop
  }

  _disconnected() {
    // noop
  }

  _received(data) {
    window.location.reload()
  }
}
