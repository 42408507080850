import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'statusWaiting', 'statusComplete', 'statusMissingPerson', 'button' ];
  static interval;
  static values = {
    statusUrl: String,
    currentStatus: String,
  };

  connect() {
    this.interval = setInterval(() => {
      this.longPoll();
    }, 1000);
    this.setStatus();
  }

  longPoll() {
    fetch(this.statusUrlValue, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then(response => response.json())
      .then(result => {
        this.currentStatusValue = result.status;
        this.setStatus();
    })
  }

  setStatus() {
    if (this.currentStatusValue == "waiting") {
      this.setStatusWaiting();
    } else if (this.currentStatusValue == "success") {
      this.setStatusComplete();
    } else if (this.currentStatusValue == "missing_person") {
      this.setStatusMissingPerson();
    }
  }

  setStatusWaiting() {
    this.statusCompleteTarget.style.display = "none";
    this.statusMissingPersonTarget.style.display = "none";
    this.statusWaitingTarget.style.display = "";
  }

  setStatusComplete() {
    clearInterval(this.interval);
    this.statusWaitingTarget.style.display = "none";
    this.statusMissingPersonTarget.style.display = "none";
    this.statusCompleteTarget.style.display = "";
    this.buttonTarget.removeAttribute("disabled");
  }

  setStatusMissingPerson() {
    clearInterval(this.interval);
    this.statusWaitingTarget.style.display = "none";
    this.statusCompleteTarget.style.display = "none";
    this.statusWaitingTarget.style.display = "none";
    this.statusMissingPersonTarget.style.display = "";
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
