import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { getParents } from '../../../global/utils'

export default class extends Controller {
  static targets = [
    'modalContent',
    'checksList',
    'requiredCheck',
    'checkPreview',
    'checkItem',
    'counter',
    "search",
    'counterWrapper',
    'viewSelectedLink',
    'backToChecksLink',
  ];

  static values = {
    platform: String,
    index: Number,
    namespace: String,
    optionsUrl: String,
  }

  checks = []
  checksLoaded = false;
  modalContent = ""

  connect() {
    this.modalContent = this.modalContentTarget.innerHTML.replaceAll(/zzz/g, '')

    if (this.requiredCheckTargets.length > 0) {
      this.showAdditionalRequirementLink()
    } else {
      this.hideAdditionalRequirementLink()
    }

    this.loadChecks()
      .then(() => { this.checksLoaded = true })
      .then(() => {
        let initialPopulated = this.selectedCheckIds()
        this.checks.forEach((c) => {
          if (initialPopulated.includes(c.id)) {
            c.selected = true;
          }
        })
      })
  }

  showModal(event) {
    event.preventDefault();
    kModal.init({
      content: this.modalContent,
      launchingUrl: "",
      ujs: true,
      namespace: this.namespaceValue,
      appendTo: this.element,
    });

    this.renderChecks()
    this.syncSelected()
  }

  closeModal() {
    kModal.remove()
  }

  loadChecks() {
    let url = this.optionsUrlValue + `?platform=${this.platformValue}`
    return fetch(url)
      .then(res => res.json())
      .then(checks => this.checks = checks)
  }

  renderChecks() {
    this.checkItemTargets.forEach(el => el.remove())
    this.checks.forEach(c => this.renderCheckItem(c))
  }

  renderCheckItem(check) {
    let checkbox = `<input type="checkbox" data-action="change->goals--requirement#selectCheck" value="${check.id}">`
    if (!!check.selected) {
      checkbox = `<input type="checkbox" data-action="change->goals--requirement#selectCheck" checked="${check.selected}" value="${check.id}">`
    }
    let html = `<div class="check-selector__check-list-item" data-goals--requirement-target="checkItem" data-id="${check.id}" data-search-text="${check.name}">
                        ${checkbox}
                        <div data-check-id="${check.id}" data-action="click->goals--requirement#previewCheck">
                          ${check.icon}
                          <span title="${check.name}">${check.name}</span>
                        </div>
                      </div>`
    this.checksListTarget.insertAdjacentHTML('beforeend', html)
  }

  syncSelected() {
    let selected = this.selectedCheckIds()
    this.checkItemTargets.forEach((target) => {
      let id = parseInt(target.dataset.id)
      if (selected.includes(id)) {
        this.checks.find(c => c['id'] == id).selected = true
        target.querySelector('input[type=checkbox]').checked = true;
      } else {
        target.querySelector('input[type=checkbox]').checked = false;
      }
    })
    this.recountSelected()
  }

  selectedCheckIds() {
    return this.requiredCheckTargets
      .map((element) => element.querySelectorAll('input[type=hidden]')[0].value)
      .map(v => parseInt(v))
  }

  search = debounce((event) => {
    let searchTerm = event.target.value
    const inputMatcher = new RegExp(`.*${searchTerm}.*`, 'i');

    if (searchTerm == "") {
      this.checkItemTargets.forEach(el => el.style.display = 'block')
    } else {
      this.checkItemTargets.forEach((checkItem) => {
        if (checkItem.dataset.searchText.match(inputMatcher)) {
          checkItem.style.display = 'block'
        } else {
          checkItem.style.display = 'none'
        }
      })
    }
  }, 250)

  previewCheck(event) {
    this.element.querySelectorAll('.check-selector__check-list-item')
      .forEach(el => el.classList.remove('check-selector__check-list-item--active'))
    event.currentTarget.parentNode.closest('.check-selector__check-list-item')
      .classList.add('check-selector__check-list-item--active')

    let checkId = event.currentTarget.dataset.checkId
    this.fetchAndRenderPreview(checkId)
  }

  fetchAndRenderPreview(checkId) {
    let url = `${this.optionsUrlValue}/${checkId}`
    fetch(url)
      .then(resp => resp.json())
      .then(resp => {
        this.checkPreviewTarget.innerHTML = resp['html']
      })
  }

  selectCheck(event) {
    let target = event.currentTarget
    let check = this.checks.find(c => c['id'] == target.value)
    if (target.checked) {
      check.selected = true
    } else {
      check.selected = false
    }
    this.recountSelected()
  }

  removeCheck(event) {
    let target = event.currentTarget;
    let matchingChecks = this.checks.filter(c => c.id == target.dataset.checkId)
    matchingChecks.forEach(c => c.selected = false)
    // remove any that check items that may be rendered
    this.element.querySelectorAll(`.check-selector__check-list-item[data-id="${target.dataset.checkId}"]`)
      .forEach((e) => e.remove())
    this.setChecks()
    this.notifyChange()
  }

  saveChanges() {
    this.setChecks()
    this.notifyChange()
    this.closeModal()
  }

  setChecks() {
    this.requiredCheckTargets.forEach(el => el.remove())
    let selected = this.checks.filter(c => c.selected == true)
    if (selected.length > 0) {
      this.element.querySelectorAll('.goal__add-first-check')
        .forEach((e) => e.remove())
      this.showAdditionalRequirementLink();
      // make sure a button exists to open the modal again
      if (this.element.querySelectorAll('.goal__add-new-check').length < 1) {
        let plusIcon = `<svg class="kolidecon kolidecon-plus" viewBox="0 0 24 24" version="1.1" width="12" height="12" aria-hidden="true"><path d="M13 11h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5V6a1 1 0 0 1 2 0v5z"></path></svg>`
        let finalSeparator = `
         <div class="goal__add-new-check" data-action="click->goals--requirement#showModal">${plusIcon}<span>AND</span></div>
        `
        this.element.insertAdjacentHTML('afterbegin', finalSeparator)
      }
    } else {
      this.emptyRequirementFields()
    }
    selected.forEach((check) => {
      let hiddenInput = `<input type="hidden" name="goal[requirements_attributes][${this.indexValue}][check_ids][]" value="${check.id}" /> `

      let removeIcon =
        `<svg data-action="click->goals--requirement#removeCheck"
           data-check-id="${check.id}"
           class="kolidecon kolidecon-x-circle"
           viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true">
           <path fill-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10C6.478 22 2 17.523 2 12S6.478 2 12 2zM8.71 7.293a1 1 0 0 0-1.414 1.414l3.29 3.292-3.293 3.294a1 1 0 0 0 1.414 1.414l3.294-3.293 3.292 3.293a1 1 0 1 0 1.414-1.414l-3.292-3.294 3.292-3.292a1 1 0 1 0-1.414-1.414L12 10.585z"></path></svg>
      `

      let requiredCheck =
        `
        <requirement-check class="goal__requirement-check-wrapper" data-goals--requirement-target="requiredCheck">
           <div class="goal__requirement-check">
             ${check.icon}
             <div class="goal__requirement-check--details">
               <span>${check.checklist_name}</span> <span title="${check.name}">${check.name}</span>
             </div>
             ${hiddenInput}
             ${removeIcon}
           </div>
           <div class="goal__check-separator"><span>AND</span></div>
         </requirement-check>
      `
      this.element.insertAdjacentHTML('afterbegin', requiredCheck);
    });
  }

  emptyRequirementFields() {
    this.hideAdditionalRequirementLink()
    // if all checks are removed, we need to add an empty check_ids input, or
    // else the old checks will stick around
    let input = `<input type="hidden" name="goal[requirements_attributes][${this.indexValue}][check_ids][]" value="" /> `
    this.element.insertAdjacentElement('beforeend', input)
    this.element.querySelectorAll('.goal__add-new-check')
      .forEach(el => el.remove())

    if (this.element.querySelector('.goal__add-first-check')) {
    } else {
      this.insertAddInitialCheckButton()
    }
  }

  showAdditionalRequirementLink() {
    let links = getParents(this.element, '.goal__requirement-platform').map(parent =>
      Array.from(parent.querySelectorAll('requirement-separator-wrapper[data-nested-form-target="links"]'))
    ).flat()

    links.forEach(el => el.style.display = 'block')
  }

  hideAdditionalRequirementLink() {
    let links = getParents(this.element, '.goal__requirement-platform').map(parent =>
      Array.from(parent.querySelectorAll('requirement-separator-wrapper[data-nested-form-target="links"]'))
    ).flat()

    links.forEach(el => el.style.display = 'none')
  }

  notifyChange() {
    document.querySelector('form[data-controller*="unsaved-changes"]').dataset.unsavedChangesChangedValue = true;
    const event = new Event('goal.changed')
    document.dispatchEvent(event)
  }

  recountSelected() {
    // if there is no modal built, noop
    if (this.hasCounterWrapperTarget && this.hasViewSelectedLinkTarget && this.hasCounterTarget) {
    } else { return }

    let selected = this.checkItemTargets.filter(c => {
      return !!c.querySelector('input[type=checkbox]:checked')
    })
    let count = selected.length
    if (count > 0) {
      this.viewSelectedLinkTarget.style.display = 'block';
      this.counterWrapperTarget.style.display = 'block';
      this.counterTarget.innerHTML = count;
    } else {
      this.viewSelectedLinkTarget.style.display = 'none';
      this.counterWrapperTarget.style.display = 'none';
      this.counterTarget.innerHTML = null;
    }
  }

  insertAddInitialCheckButton() {
    // if we're inserting the 'add initial check', remove the 'add new check' buttons
    this.element.querySelectorAll('.goal__add-new-check').forEach(e => e.remove())
    let button = `
    <div class="goal__add-first-check" data-action="click->goals--requirement#showModal">
      <svg height="24" class="kolidecon kolidecon-plus-circle" viewBox="0 0 24 24" version="1.1" aria-hidden="true">
         <path fill-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 3a1 1 0 0 0-1 1v5H6a1 1 0 0 0 0 2h5v5a1 1 0 0 0 2 0v-5h5a1 1 0 0 0 0-2h-5V6a1 1 0 0 0-1-1z"></path>
      </svg>
      <span> Add a Check to Measure </span>
    </div>
    `
    this.element.insertAdjacentHTML('beforeend', button)
  }

  insertAdditionalCheckButton() {
    let button = `
      <div class="goal__add-new-check" data-action="click->goals--requirement#showModal">
        <svg class="kolidecon kolidecon-plus" viewBox="0 0 24 24" version="1.1" width="12" height="12" aria-hidden="true"><path d="M13 11h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5H6a1 1 0 0 1 0-2h5V6a1 1 0 0 1 2 0v5z"></path></svg>
        <span>AND</span>
      </div>
      `
    this.element.insertAdjacentHTML('beforeend', button)
  }

  viewSelected(event) {
    event.preventDefault();
    this.checkItemTargets.forEach(target => target.remove())
    this.checks.filter((c) => c.selected).forEach((check) => {
      let removeIcon =
        `<svg data-action="click->goals--requirement#removeCheck"
           data-check-id="${check.id}"
           class="kolidecon kolidecon-x-circle"
           viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true">
i          <path fill-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10C6.478 22 2 17.523 2 12S6.478 2 12 2zM8.71 7.293a1 1 0 0 0-1.414 1.414l3.29 3.292-3.293 3.294a1 1 0 0 0 1.414 1.414l3.294-3.293 3.292 3.293a1 1 0 1 0 1.414-1.414l-3.292-3.294 3.292-3.292a1 1 0 1 0-1.414-1.414L12 10.585z"></path></svg>
      `
      let html = `<div class="check-selector__check-list-item" data-goals--requirement-target="checkItem" data-id="${check.id}" data-search-text="${check.name}">
                    <div data-check-id="${check.id}" data-action="click->goals--requirement#previewCheck">
                      ${check.icon}
                      <span title="${check.name}">${check.name}</span>
                    </div>
                    ${removeIcon}
                 </div>`
      this.checksListTarget.insertAdjacentHTML('beforeend', html)

    })
    this.viewSelectedLinkTarget.style.display = 'none';
    this.backToChecksLinkTarget.style.display = 'flex'
    this.counterWrapperTarget.style.display = 'none';
  }

  renderCheckList(event) {
    event.preventDefault();
    this.renderChecks();
    this.recountSelected()

    this.backToChecksLinkTarget.style.display = 'none'
  }
}
