import debounce from "lodash/debounce";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "input", "results"];
  static classes = [ "hidden", "selected" ];

  // Lifecycle hooks
  connect() {
    this.inputTarget.addEventListener("keyup", this.onKeyup);
    window.addEventListener("keydown", this.onKeydown);
    window.addEventListener("click", this.onWindowClick);
  }

  disconnect() {
    this.inputTarget.removeEventListener("keyup", this.onKeyup)
    window.removeEventListener("keydown", this.onKeydown);
    window.removeEventListener("click", this.onWindowClick);
  }

  // Functions
  search() {
    this.formTarget.requestSubmit();
  }

  showSearchResults() {
    if (this.inputTarget.value.length > 0) {
      if (!this.hasResultsTarget) return;
      this.resultsTarget.classList.remove(this.hiddenClass);
    };
  }

  hideSearchResults() {
    if (!this.hasResultsTarget) return;
    this.resultsTarget.classList.add(this.hiddenClass);
  }

  browseToSelectedResult() {
    const selectedResult = this.results[this.selectedResultIndex]
    if (selectedResult == null) return;

    const location = selectedResult.querySelector("a").getAttribute("href");
    window.location.href = location;
    this.hideSearchResults();
  }

  clearSelection() {
    this.results.forEach(item => {
      item.classList.remove(this.selectedClass);
    });
  }

  navigate(indexDiff = 1) {
    if (!this.hasResultsTarget) return;
    const selectedIndex = this.selectedResultIndex;
    const resultLength = this.results.length;
    const newIndex = selectedIndex === -1 ? 0 : selectedIndex + indexDiff;

    this.clearSelection();

    // Handle out of bounds by setting the focus back on the search bar
    if ((selectedIndex === resultLength -1 && indexDiff === 1) || (selectedIndex === 0 && indexDiff === -1)) {
      this.inputTarget.focus();
      return;
    }

    this.results[newIndex].classList.add(this.selectedClass);
  }

  // Getters
  get results() {
    if (!this.hasResultsTarget) return [];
    let items = this.resultsTarget.getElementsByClassName("option");
    return Array.from(items);
  }

  get selectedResultIndex() {
    return this.results.findIndex(item => item.classList.contains(this.selectedClass));
  }

  // Actions
  submit = debounce(() => {
    this.search();
  }, 400, { leading: true, trailing: true });

  focus() {
    if (this.results.length > 0) {
      this.showSearchResults();
    }
  }

  // Event handlers
  onResultHover(event) {
    this.clearSelection();
    event.currentTarget.classList.add(this.selectedClass);
  }

  onResultClick(event) {
    event.preventDefault();
    this.browseToSelectedResult();
  }

  onWindowClick = (event) => {
    if (!this.element.contains(event.target)) {
      this.hideSearchResults();
    }
  }

  onKeydown= (event) => {
    if ((event.ctrlKey || event.metaKey) && event.which == 74) {
      event.preventDefault();
      this.inputTarget.focus();
    }
  }

  onKeyup = (event) => {
    switch (event.key) {
      case "Up": // support Edge/IE
      case "ArrowUp":
        this.navigate(-1);
        event.preventDefault();
        break;
      case "Down": // support Edge/IE
      case "ArrowDown":
        this.navigate(1);
        event.preventDefault();
        break;
      case "Enter":
        this.browseToSelectedResult();
        break;
      case "Esc": // support Edge/IE
      case "Escape":
        this.hideSearchResults();
      default:
        return;
    }
  }
}
