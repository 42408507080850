import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = [ "htmlContainer" ];
  static values = {
    url: String,
  };

  onKeyup = debounce((event) => {
      if (event.target.reportValidity()) {
        this.onQuantityChange(event.target.value);
      }
  }, 200);

  onQuantityChange(quantity) {
    let params = new URLSearchParams(location.search);
    params.append("quantity", quantity);

    fetch(`${this.urlValue}?${params.toString()}`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then(response => response.json())
    .then(json => {
      this.htmlContainerTarget.innerHTML = json.html
    });
  }
}
