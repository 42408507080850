import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropzone"];

  dragover(event) {
    event.preventDefault();
    return true;
  }

  dragenter(event) {
    event.preventDefault();
    this.dropzoneTarget.classList.add("idp-config__certificate--drag-started");
  }

  dragleave(event) {
    this.dropzoneTarget.classList.remove(
      "idp-config__certificate--drag-started"
    );
  }

  drop(event) {
    event.preventDefault();
    let file = event.dataTransfer.files[0],
      reader = new FileReader();

    reader.onload = (resultEvent) => {
      this.dropzoneTarget.value = resultEvent.target.result;
      this.dropzoneTarget.classList.remove(
        "idp-config__certificate--drag-started"
      );
    };
    reader.readAsText(file);
    return false;
  }
}
