import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['row', 'stage', 'unstage'];

  connect() {
    let trElement = this.data.element.closest("tr");
    let targetSelectorContoller = this.application.getControllerForElementAndIdentifier(this.data.element.closest("[data-controller~=target-selector]"), 'target-selector');
    let selectedDeviceTargets = targetSelectorContoller.stagedTargetIds;

    if (selectedDeviceTargets.has(Number(this.data.element.dataset.targetDeviceId))
      || targetSelectorContoller.isGroupSelected(this.data.element.dataset.targetGroupSlug)) {

      if (!targetSelectorContoller.onlyTargets) {
        trElement.classList.add("staged");
        trElement.querySelector('.colvis-check').classList.add("active");
      }
    }

    // Simulate the click event for the first element to prime the preview.
    if (!trElement.previousElementSibling && !trElement.parentElement.hasAttribute("kolide_preview_primed")) {
      trElement.parentElement.setAttribute("kolide_preview_primed", "true");
      setTimeout(() => { this.data.element.click(); }, 500);
    }
  }
}
