import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['spinner', 'link'];
  static values = {
    sessionId: String,
  };

  onAppLinkClick(event) {
    this.linkTarget.classList.add('hidden');
    this.spinnerTarget.classList.remove('hidden');
  }
}
