import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  warn(event) {
    let href = this.element.href
    let decision = true
    if (this.link_is_external(this.element)) {
      decision = confirm(`You are about to leave Kolide and go to "${href}" is that okay?`)
    }

    if (!decision) {
      event.preventDefault()
    }
  }

  link_is_external(link_element) {
    return (link_element.host !== window.location.host);
  }
}
