import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['display'];
  static values = {
    action: String,
    attrname: String,
  }

  initialValue = ""
  initialContent = ""

  connect() {
    this.initialValue = this.displayTarget.textContent.trim()
    this.initialContent = this.element.innerHTML
  }

  edit() {
    const form = `
      <form action="${this.actionValue}" class="simple_form inline-edit-form"
       method="post" data-remote="true">
        <input type="hidden" name="_method" value="patch" />
        <div class="input string optional">
          <input name="${this.attrnameValue}" class="string" type="text" value="${this.initialValue}">
        </div>
        <div class="inline-edit-form__actions">
          <button type="submit" class="button button--inverse">Save</button>
          <button type="button" class="button button--inverse-link" data-action="click->editable-text#cancel">Cancel</a>
        </div>
     </form>
   `
    this.displayTarget.insertAdjacentHTML("afterend", form)
    this.displayTarget.remove()
    event.currentTarget.remove()
  }

  cancel(event) {
    event.preventDefault()
    this.element.innerHTML = this.initialContent
  }
}
