import { Controller } from '@hotwired/stimulus';
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ['document', 'searchResultStats'];

  search = debounce((event) => {
    const inputMatcher = new RegExp(`${event.target.value}.*`);

    for(let i = 0; i < this.documentTargets.length; i++) {
      const document = this.documentTargets[i];

      if (document.dataset.tableName.match(inputMatcher)) {
        document.classList.remove("hidden");
      } else {
        document.classList.add("hidden");
      }
    }

    this.setSearchResultStats();
  }, 250)


  setSearchResultStats() {
    this.searchResultStatsTarget.innerHTML = `<strong>${this.documentTargets.filter(doc => !doc.classList.contains("hidden")).length} of ${this.documentTargets.length} Tables</strong> matching filters`
  }
}
