import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submitButton", "actionSelect"];

  policyChanged() {
    Turbo.navigator.submitForm(this.element);
  }

  ruleChanged(event) {
    this.actionSelectTarget.classList.remove("hidden");
    this.submitButtonTarget.classList.remove("hidden");
  }
}
