import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["autoClickButton", "blockedButton", "submitButton"];
  static values = {
    url: String,
    checkIds: Array,
    snoozableCheckId: Number,
  };

  connect() {
    this.element.addEventListener("submit", this.logSubmit.bind(this));
    if (this.hasAutoClickButtonTarget) {
      this.autoClickButtonTarget.click();
    }
  }

  disconnect() {
    this.element.removeEventListener("submit", this.logSubmit.bind(this));
  }

  async logSubmit(event) {
    event.preventDefault();

    // POST to an internal endpoint to log the sign-in success.
    const csrfToken = document.getElementsByName("csrf-token")[0].content;
    let params = new URLSearchParams({
      snoozable_check_id: this.snoozableCheckIdValue,
    });

    this.checkIdsValue.forEach(element => {
      params.append("check_ids[]", element);
    });

    await fetch(this.urlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        Accept: "text/html",
      },
      body: params,
    }).then(response => {
      if (response.ok) {
        console.info("Sign in success!");
        this.element.submit();
      } else {
        if (this.hasSubmitButtonTarget) {
          this.submitButtonTarget.classList.add("hidden");
          this.blockedButtonTarget.classList.remove("hidden");
        }
        console.error("Error updating auth session.");
      }
    })
  }
}
