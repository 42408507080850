import { Controller } from "@hotwired/stimulus";
// 5 minutes
const ttl = 5 * 60 * 1000;
export default class extends Controller {
  static targets = ["recheckButton"];

  connect() {
    this.lastVisited = Number(localStorage.getItem("update_checker:last_visited"));
    if (this.lastVisited > 0) {
      this.maybeRecheck();
    }
  }

  maybeRecheck() {
    let delta = new Date().getTime() - this.lastVisited
    // if the last check was more than 15 seconds ago and less than 5 minutes ago
    if (delta > 0 && delta < ttl) {
      this.recheck();
    }
  }

  recheck() {
    this.lastVisited = 0;
    localStorage.removeItem("update_checker:last_visited");
    this.recheckButtonTarget.click();
  }

  visibilityChange(e) {
    let delta = new Date().getTime() - this.lastVisited;
    // if theres a last visited time and the delta is less stale
    if(this.lastVisited === 0 || delta > ttl) {
      localStorage.setItem("update_checker:last_visited", new Date().getTime());
    }
  }
}
