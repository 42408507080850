import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatbales
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    let activateButton = table.button('activate:name').nodes()
    if(activateButton.length == 0) {
      table.button().add(null, this.activateButton())
    }
  }

  activateButton() {
    return {
      text: "Activate selected",
      name: 'activate',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: async (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        let promises = []
        ids.each((idx)=> {
          let assigneeUrl = jQuery(dt.column('name:name').nodes()[idx])[0].dataset.url
          let endpoint = assigneeUrl + '/activate'
          promises.push(this.activate(endpoint))
        })
        await Promise.all(promises)
        location.reload()
      }
    }
  }

  activate(endpoint) {
    return fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }
}
