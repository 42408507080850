import { Controller } from "@hotwired/stimulus";
import jQuery from 'jquery'

export default class extends Controller {
  resizeDataTables() {

    jQuery('.live-queries__data-table').DataTable().columns.adjust()
    jQuery('.live-queries__data-table-results').DataTable().columns.adjust()
  }
}
