import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "showable"];
  static values = {
    hide: Boolean
  };

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.checkboxTarget.checked == this.hideValue) {
      this.showableTargets.forEach(target => {
        target.style.display = "block";
        target.style.visibility = "visible";
      });
    } else {
      this.showableTargets.forEach(target => {
        target.style.display = "none";
        target.style.visibility = "hidden";
      });
    }
  }
}
