import { Controller } from "@hotwired/stimulus"

function waitForImage(src) {
  let img = new Image();
  img.crossOrigin = "anonymous";
  let promise = new Promise((resolve, reject) => {
    img.onload = () => {
      let blob = blobFromImage(img);
      resolve(blob)
    }
    img.onerror = () => { reject(new Error("couldn't load image")) }
  });
  img.src = src
  return promise
}

function blobFromImage(img) {
  let canvas = document.createElement("canvas");
  let width = img.naturalWidth;
  let height = img.naturalHeight;
  canvas.setAttribute("width", width)
  canvas.setAttribute("height", height)
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  console.log(canvas.toDataURL());
  return new Promise((res) => { canvas.toBlob((img) => { res(img) }) })
}

export default class extends Controller {
  static targets = ["log", "portInfo"];

  get ports() {
    return [
      12519,
      40978,
      52115,
      22287,
      60685,
      22322
    ];
  }

  get baseUrl() {
    return "http://127.0.0.1"
  }

  async connect() {
    // Fetch the command and signature from K2
    let resp = await this.fetchSignedRequest("id");
    let {box} = await resp.json();
    // run through the ports and see if we get a response back
    await this.locateAgentAndSendMessage(box);
  }

  async locateAgentAndSendMessage(box) {
    for(let i=0; i < this.ports.length; i++) {
      let p = this.ports[i];
      let src = `${this.baseUrl}:${p}/v0/cmd?box=${encodeURIComponent(box)}`
      let blob;
      try {
        blob = await waitForImage(src);
        this.port = p;
        await this.sendBlob(blob);
        this.displayImage(blob);
        break;
      } catch(e) {
        console.log(e);
      }
    };
    if (this.port !== undefined) {
      this.portInfoTarget.innerHTML = `Found launcher at ${this.baseUrl}:${this.port}`;
    } else {
      this.portInfoTarget.innerHTML = "No launcher found"
    }
  }

  fetchSignedRequest(cmd) {
    return fetch(`/staff/launcher_links/${cmd}`)
  }

  async sendBlob(blob) {
    let csrftoken = document.getElementsByName("csrf-token")[0].content
    let formdata = new FormData();
    formdata.append("data", blob)
    await fetch("/staff/launcher_links", {
      method: "POST",
      body: formdata,
      headers: {
        "X-CSRF-Token": csrftoken,
        "Accept": "text/vnd.turbo-stream.html"
      }
    }).then((r) => r.text())
      .then((text) => Turbo.renderStreamMessage(text) )
  }

  displayImage(blob) {
    let fr = new FileReader();
    fr.readAsDataURL(blob);
    fr.onloadend = () => {
      let dataURL = fr.result
      let img = new Image()
      img.src = dataURL;
      document.querySelector("#image").appendChild(img);
    }
  }

}
