import { Controller } from "@hotwired/stimulus"
import consumer from './../../channels';

export default class extends Controller {
  static values = {
    deviceId: String,
    noticeUrl: String,
  }

  connect() {
    consumer.subscriptions.create(
      { channel: "OsqueryDeviceSensitiveActionRequestsChannel", device_id: this.deviceIdValue },
      {
        received: (data) => {
          if (data.fullRefresh) {
            window.location.reload()
          } else {
            let notice = document.querySelector(`[data-sensitive-device-notice-device-id="${data.deviceID}"]`);
            if (notice && this.hasNoticeUrlValue) {
              fetch(this.noticeUrlValue).then((response) => {
                return response.text();
              }).then((html) => {
                notice.outerHTML = html
              })
            }
          }
        },
      },
    );
  }
}
