export const setAnimationSpeed = (el, speed) => {
  switch(speed) {
    case 'slow':
      el.style.setProperty('--animate-duration', '0.6s')
    case 'fast':
      el.style.setProperty('--animate-duration', '0.2s')
    default:
      el.style.setProperty('--animate-duration', '0.4s')
  }
}
