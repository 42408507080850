import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contentWrapper", "button", "secretContent", "overlay", "clickToCopy"];
  static values = {
    id: String,
    secretUrl: String,
    autoReveal: { type: Boolean, default: false },
  }
  static fauxSecret = "51FNVCXQAWHKSAWEP05021689A2189A51FNVCXQAWHKSAWEP05021689A2189AFF";

  connect() {
    if (this.autoRevealValue) {
      this.sharpen()
    }
  }

  sharpen() {
    fetch(this.secretUrlValue)
      .then((response) => {
        // handle the server redirecting to user verification
        if (response.redirected) {
          window.location = response.url
        } else {
          return response.json()
        }
      }).then((value) => {
        let secret = value.secret
        this.clickToCopyTarget.dataset.clipboardTextValue = secret
        this.secretContentTarget.innerHTML = secret
        this.contentWrapperTarget.classList.remove('blurred')
        this.overlayTarget.classList.remove('overlay--active')
        this.buttonTarget.classList.add('hidden')
      })
  }

  blur(event) {
    if (!this.element.contains(event.target)) {
      this.clickToCopyTarget.dataset.clipboardTextValue = this.constructor.fauxSecret
      this.secretContentTarget.innerHTML = this.constructor.fauxSecret
      this.contentWrapperTarget.classList.add('blurred')
      this.overlayTarget.classList.add('overlay--active')
      this.buttonTarget.classList.remove('hidden')
    }
  }
}
