import { Controller } from "@hotwired/stimulus";
import { current } from "./../../../global/helpers"
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {
  static values = {
    endpoint: String
  };

  static targets = ["table", "actions"]

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatbales
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    // need to check for the button existence because the 'connect' for this
    // controller is being called multiple times on turbo:load, despite
    // being called only once on a full page load/refresh.
    let unignoreButton = table.button('unignore:name').nodes()
    if(unignoreButton.length == 0 && !current.flags.deviceTrustEnabled) {
      table.button().add(null, this.unignoreButton())
    }
  }

  unignoreButton() {
    return {
      text: "Unignore",
      name: 'unignore',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        ids.each((idx)=> {
          let issueURL = jQuery(dt.column('issue:name').nodes()[idx]).find('a').attr('href')
          this.unignore(issueURL)
        })
      }
    }
  }

  unignore(issueURL) {
    let endpoint = issueURL.split('?')[0] + '/unignore'

    fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }
}
