import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "submit"];

  connect() {
    this.toggleSubmit();
  }

  toggleSubmit() {
    if (this.checkboxTargets.some(checkbox => checkbox.checked)) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }
}
