import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if(this.isOverflowing(this.element)) {
      this.element.classList.add("overflowing")
    }
  }

  isOverflowing(el) {
    let curOverflow = this.element.style.overflow;
    if ( !curOverflow || curOverflow === "visible" ) {
      el.style.overflow = "hidden";
    }

   let isOverflowing = el.clientWidth < el.scrollWidth  || el.clientHeight < el.scrollHeight;
   el.style.overflow = curOverflow;

   return isOverflowing;
  }
}
