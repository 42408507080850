import tippy from 'tippy.js'

let installToolTipScrollEvent = () => {
  const element = document.querySelectorAll('.js-hide-tooltips-on-scroll')[0]

  if (element) {
    element.addEventListener('scroll', () => {
      resetTooltips()
    });
  }
}

let resetTooltips = () => {
  const tooltips = [...document.querySelectorAll('*[data-tippy]')];
  tooltips.forEach(tooltip => {
    tooltip._tippy && tooltip._tippy.destroy();
    let tippyOptions = {
      allowHTML: tooltip.getAttribute('data-tippy-allow-html') ?? false,
      content: tooltip.getAttribute('data-tippy'),
      popperOptions: {
        strategy: 'fixed',
      }
    }
    if (tooltip.getAttribute('data-tippy-append-to-body')) {
      tippyOptions.appendTo = document.body
    }
    tippy(tooltip, tippyOptions)
  });
}

document.addEventListener('app.tippy.refresh', (event) => {
  resetTooltips()
})

// Turbo Drive Tooltip Handlers
document.addEventListener("turbo:load", () => {
  resetTooltips()
  installToolTipScrollEvent()
});

document.addEventListener("ajax:complete", () => {
  resetTooltips()
});

$(document).on('xhr.dt', () => {
  setTimeout( () => {
    resetTooltips()
  }, 300)
});

(function() {
  var scrollTops = new WeakMap();

  let findElements = () => {
    return document.querySelectorAll("[data-turbo-permanent]");
  }

  document.addEventListener("turbo:before-render", function() {
    findElements().forEach(function(element) {
      scrollTops.set(element, element.scrollTop);
    })
  });

  document.addEventListener("turbo:render", function() {
    findElements().forEach(function(element) {
      if (scrollTops.has(element)) {
        element.scrollTop = scrollTops.get(element);
      }
    });
  });
})()
