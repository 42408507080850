import { Controller } from "@hotwired/stimulus";
import consumer from './../channels';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    "button",
    "input",
    "list",
  ];

  static values = {
    url: String
  };

  connect() {
    this.channelSyncSubscription = consumer.subscriptions.create(
      { channel: "SlackChannelSyncChannel" },
      {
        received: (_data) => {
          if (!this.listTarget.hasAttribute("hidden")) {
            this.inputTarget.dispatchEvent(new Event("input"))
            this.buttonTarget.classList.remove("loading")
          }
        },
      },
    )
  }

  disconnect() {
    if (typeof this.channelSyncSubscription !== 'undefined') {
      this.channelSyncSubscription.unsubscribe();
    }
  }

  resync(event) {
    event.preventDefault();
    this.buttonTarget.classList.add("loading")

    fetch(this.urlValue, {
      method: 'POST',
      headers: { "X-CSRF-Token": Rails.csrfToken() },
    })
  }
}
