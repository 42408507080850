import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "form", "field" ];

  set(event) {
    this.fieldTarget.value = event.detail.dataset.tabId;
    Rails.fire(this.formTarget, 'submit');
  }
}
