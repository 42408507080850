import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.classList.add("zoomable");
  }

  open() {
    kModal.init({
      ujs: true,
      content: `
      <div class="kolide-modal__wrapper kolide-modal__wrapper--large animate__animated animate__fadeInDown animate__faster">
        <header class="kolide-modal__header" style="height: 30px;">
          <svg class="kolidecon kolidecon-x kolide-modal__close-button" height="24" data-action="click->modal-close#closeModal" viewBox="0 0 24 24" version="1.1" width="14" aria-hidden="true"><path d="M12 9.996L6 4a1.415 1.415 0 0 0-2 2l6 5.996-6 5.997a1.413 1.413 0 1 0 2 1.999l6-5.997 6 5.997a1.415 1.415 0 0 0 2-1.999l-6-5.997 6-5.997A1.413 1.413 0 1 0 18 4l-6 5.997z"></path></svg>
        </header>
        <div class=kolide-modal__content>
          <img src=${this.element.src} style="width: 100%;"/>
        </div>
        <footer class="kolide-modal__buttons">
          <span class="kolide-modal__link" data-action="click->modal-close#closeModal">Close</span>
        </footer>
      </div>`
    });
  }
}
