import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import pluralize from 'pluralize';
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = ["menu", "gracePeriodMenu", "gracePeriodInput",
                    "strategyMenu", "strategyOption", "gracePeriodOption",
                    "hiddenFields", "strategyTrigger", "gracePeriodTrigger",
                    "slackEndUserIcon", "slackChannelIcon", "escalationOptions",
                   ];

  static values = {
    initialGracePeriod: Number,
    strategy: String,
  }

  submitForm(f) {
    Rails.fire(f, 'submit')
  }

  // submits as json, avoiding sidebar re-rendering that would close open menus.
  unobtrusivelySubmit() {
    let form = this.gracePeriodInputTarget.form
    let token = Rails.csrfToken();
    let formBody = { check: this.formToJson(form) }

    fetch(form.action, {
      method: 'PATCH',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        'Accept': 'text/html',
      },
      body: JSON.stringify(formBody),
    })
  }

  formToJson(form) {
    let formData = new FormData(form)
    let result = {configuration_attributes: {}};

    for (var entry of formData.entries())
    {
      let config_attr_name = entry[0].match(/(?:\[)([a-zA-Z_0-9]+)(?:\])$/)
      if(config_attr_name) {
        result['configuration_attributes'][config_attr_name[1]] = entry[1];
      }
    }

    return result;
  }

  toggleGracePeriod(event) {
    this.strategyMenuTarget.classList.remove("open")
    this.gracePeriodMenuTarget.classList.toggle("open")
    event.preventDefault();
  }

  toggleStrategy(event) {
    this.gracePeriodMenuTarget.classList.remove("open")
    this.strategyMenuTarget.classList.toggle("open")
    this.strategyMenuTarget.querySelectorAll('[data-tippy]')
      .forEach(el => this.setTooltip(el))
    event.preventDefault();
  }

  setTooltip(element) {
    element._tippy && element._tippy.destroy();
    tippy(element, {
      allowHTML: this.element.getAttribute('data-tippy-allow-html') ?? false,
      content: this.element.getAttribute('data-tippy'),
      popperOptions: {
        strategy: 'fixed',
      },
  });
  }

  selectStrategyOption(event) {
    let selectedOption = event.currentTarget
    if (!selectedOption.classList.contains('selected')) {
      this.strategyOptionTargets.forEach(opt => {
        opt.classList.remove('selected')
      })
      selectedOption.classList.add('selected')
      let strategy = selectedOption.dataset.value
      this.strategyValue = strategy
      this.setStrategyDisplay()
      if ( strategy == "person" ) {
        this.enableGracePeriodSelector()
        this.showEscalationOptions()
        this.restoreGracePeriodValue()
      } else {
        this.hideEscalationOptions()
        this.clearGracePeriod();
        this.disableGracePeriod();
        setInterval(() => {
          this.hideStrategyMenu(new Event("click"));
        }, 100)
      }
      this.populateHiddenFields(strategy)
    }
  }

  selectGracePeriodOption(event) {
    if (event.currentTarget.classList.contains('selected')) {
      return
    }

    this.gracePeriodOptionTargets.forEach(opt => opt.classList.remove('selected') )
    event.currentTarget.classList.add('selected')
    this.restoreGracePeriodValue()
  }

  restoreGracePeriodValue(){
    this.gracePeriodInputTarget.value = this.initialGracePeriodValue
  }

  setGracePeriod(event)  {
    this.initialGracePeriodValue = event.currentTarget.value
    this.setGracePeriodDisplay()
  }

  clearGracePeriod(event) {
    this.gracePeriodOptionTargets.forEach(opt => {
      opt.querySelectorAll('input[type="number"]').forEach((i) => {
        i.value = 0;
      })
    })
  }

  disableGracePeriod(){
    this.gracePeriodTriggerTarget.classList.add('hidden')
    let els = document.querySelectorAll('[data-show-for-person]')
    els.forEach( ( el ) =>{
      el.classList.add('hidden')
      el.classList.remove('visible')
    })
    this.initialGracePeriodValue = 0
    this.setGracePeriodDisplay()
  }

  enableGracePeriodSelector(){
    let els = document.querySelectorAll('[data-show-for-person]')
    els.forEach( ( el ) =>{
      el.classList.remove('hidden')
      el.classList.add('visible')
    })
    this.gracePeriodTriggerTarget.classList.remove('hidden')
  }

  showEscalationOptions(){
    this.escalationOptionsTarget.classList.remove('hidden')
  }

  hideEscalationOptions(){
    this.escalationOptionsTarget.classList.add('hidden')
  }

  populateHiddenFields(strategyValue) {
    this.hiddenFieldsTarget.innerHTML = ""
    let input =  `<input value="${strategyValue}" type="hidden" name="check[configuration_attributes][notification_strategy]">`
    this.hiddenFieldsTarget.insertAdjacentHTML('beforeend', input)
  }

  setGracePeriodDisplay() {
    let gracePeriodText = "First Available Opportunity"
    if (this.initialGracePeriodValue > 0) {
      gracePeriodText = `After waiting ${this.initialGracePeriodValue} ${pluralize("day", this.initialGracePeriodValue)}`
    }
    this.gracePeriodTriggerTarget.querySelector('span').innerHTML = gracePeriodText
  }

  setStrategyDisplay() {
    let label = this.noNotificationLabel()
    if(this.strategyValue == "kolide_user") {
      label = this.kolideUserNotificationLabel()
    } else if (this.strategyValue == "person") {
      label = this.personNotificationLabel()
    }

    let img = this.strategyTriggerTarget.querySelector('.check__sidebar-notification-option-image--smaller')
    img.remove()
    this.strategyTriggerTarget.prepend(label.image)
    let text = this.strategyTriggerTarget.querySelector('span')
    text.innerHTML = label.text
  }

  hideGracePeriodMenu(event) {
    if ( this.gracePeriodTriggerTarget.contains(event.target) ) { return }

    let isOpen = this.gracePeriodMenuTarget.classList.contains("open")
    if (!this.gracePeriodMenuTarget.contains(event.target) && isOpen){
      this.gracePeriodMenuTarget.classList.remove("open")
      this.submitForm(this.gracePeriodInputTarget.form)
    }
  }

  hideStrategyMenu(event) {
    if ( this.strategyTriggerTarget.contains(event.target) ) { return }

    let isOpen = this.strategyMenuTarget.classList.contains("open")
    if (!this.strategyMenuTarget.contains(event.target) && isOpen){
      this.strategyMenuTarget.classList.remove("open")
      this.submitForm(this.gracePeriodInputTarget.form)
    }
  }

  cancelSidebarClosure(event) {
    let strategyMenuOpen = this.strategyMenuTarget.classList.contains("open")
    let gracePeriodMenuOpen = this.gracePeriodMenuTarget.classList.contains("open")
    if (strategyMenuOpen || gracePeriodMenuOpen) {
      event.preventDefault()
    }
  }

  noNotificationLabel() {
    let svg = `<svg class="kolidecon kolidecon-bell-disable check__sidebar-notification-option-image--smaller" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path fill-rule="evenodd" d="M14 20c0 1.105-1.12 2-2.5 2S9 21.105 9 20zM4 3l2.906 2.906.001-.001L20 19l1 1-1 1-2-2H3v-1l2-2v-5.5c0-1.284.372-2.48 1.015-3.487L3.001 3.998 4 3zm7.5-1A1.5 1.5 0 0 1 13 3.5v.68c2.86.68 5 3.25 5 6.32V15L8.017 5.017A6.487 6.487 0 0 1 10 4.18V3.5A1.5 1.5 0 0 1 11.5 2z"></path></svg>`
    let image = document.createRange().createContextualFragment(svg)
    return {image: image, text: "Notify No-one"}

  }

  kolideUserNotificationLabel(){
    let icon = this.slackChannelIconTarget.cloneNode(true)
    icon.style.display = 'block';
    delete icon.dataset["checks-NotificationConfigurationTarget"]

    return {image: icon, text: "Notify Channel"}
  }

  personNotificationLabel(){
    let icon = this.slackEndUserIconTarget.cloneNode(true)
    icon.style.display = 'block';
    delete icon.dataset["checks-NotificationConfigurationTarget"]

    return {image: icon, text: "Notify device-owner"}
  }
}
