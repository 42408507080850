import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static values = { name: String, url: String, mdmName: String, mdmUrl: String };
  static targets = [
    "switch",
    "options",
    "restrictions",
    "mdmRestrictions",
    "notAllowed",
    "selection",
    "checkSelection",
    "message"
  ];

  connect() {
    if (this.mdmUrlValue) {
      console.log("MDM URL: ", this.mdmUrlValue);
      try {
        this.mdmTagify = new Tagify(this.selectionTarget, {
          id: this.mdmNameValue,
          whitelist: [],
          maxTags: 10,
          dropdown: {
            maxItems: 20,
            classname: "tags-look",
            enabled: 0,
            closeOnSelect: false,
          },
          templates: {
            tag: (tagData) => {
              return `<tag title='${tagData.value}' contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" device_management_provider_id="${tagData.device_management_provider_id}" value='${tagData.value}'>
                      <x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x>
                      <div>
                        <img src="${tagData.url}" alt='${tagData.value}' height="24" width="24" />
                        <span class="tagify__tag-text">${tagData.value}</span>
                      </div>
                    </tag>`;
            },
            dropdownItem: (tagData) => {
              return `<div device_management_provider_id="${tagData.device_management_provider_id}" value='${tagData.value}' class="tagify__dropdown__item">
                      <div>
                        <img src="${tagData.url}" height="24" width="24" />
                        <span class="tagify__dropdown__item-text">${tagData.value}</span>
                      </div>
                    </div>`;
            },
          },
        });
      } catch (error) {
        console.error(`Unable to load device management provider options for ${this.mdmNameValue}`);
      }

      this.loadMdmProviders();

    }

    if (this.urlValue) {
      try {
        this.tagify = new Tagify(this.checkSelectionTarget, {
          id: this.nameValue,
          whitelist: [],
          maxTags: 10,
          dropdown: {
            maxItems: 20,
            classname: "tags-look",
            enabled: 0,
            closeOnSelect: false,
          },
          templates: {
            tag: (tagData) => {
              return `<tag title='${tagData.value}' contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" check_id="${tagData.check_id}" value='${tagData.value}'>
                      <x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x>
                      <div>
                        <img src="${tagData.url}" alt='${tagData.value}' height="24" width="24" />
                        <span class="tagify__tag-text">${tagData.value}</span>
                      </div>
                    </tag>`;
            },
            dropdownItem: (tagData) => {
              return `<div check_id="${tagData.check_id}" value='${tagData.value}' class="tagify__dropdown__item">
                      <div>
                        <img src="${tagData.url}" height="24" width="24" />
                        <span class="tagify__dropdown__item-text">${tagData.value}</span>
                      </div>
                    </div>`;
            },
          },
        });
      } catch (error) {
        console.error(`Unable to load check options for ${this.nameValue}`);
      }

      this.loadChecks()
    }

  }

  loadChecks() {
    fetch(`${this.urlValue}?${new URLSearchParams({for: this.nameValue})}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tagify.whitelist = data;
      });
  }

  loadMdmProviders() {
    fetch(`${this.mdmUrlValue}?${new URLSearchParams({for: this.mdmNameValue})}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.mdmTagify.whitelist = data;
      });
  }

  toggle(event) {
    const changeEvent = new Event('change');
    this.switchTarget.checked = !this.switchTarget.checked;
    this.switchTarget.dispatchEvent(changeEvent);
    this.optionsTarget.classList.toggle("hidden");
    this.notAllowedTarget.classList.toggle("hidden");
  }

  toggleMdmRestrictions(event) {
    this.mdmRestrictionsTarget.classList.toggle("hidden");
  }

  toggleRestrictions(event) {
    this.restrictionsTarget.classList.toggle("hidden");
  }

  toggleMessage(event) {
    this.messageTarget.classList.toggle("hidden");
  }
}
