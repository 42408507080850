import "datatables.net-dt"

// Save cached info on SSP datatables so we do not have to re-render data
// on page restoration visits (like hitting the back button)
document.addEventListener("turbo:before-cache", function() {
  let $tableElement = $('[data-datatables-target="table"]')

  if ($tableElement.length > 0) {
    let tableApi = $tableElement.DataTable()
    let table = tableApi.table()
    let counts = table.page.info();

    $('[data-controller*="datatables"]').attr("data-datatables-cache-value",
      JSON.stringify({
        counts: [counts.recordsDisplay, counts.recordsTotal],
        order: table.order(),
        search: table.search().length > 0 ? table.search() : "",
        displayStart: counts.start
      })
    )
  }
});

// This event is useful when you have datatables that get their data
// from a remote JSON URL. This event effectively causes the table
// to refresh its state (preserving pagination/ordering etc). Helpful if you
// remove/add items and want that to display in a table also loaded on
// the same page async.
document.addEventListener("datatables:redraw", function() {
  console.log("datatables:redraw");
  let $tableElement = $('table[id^=DataTables_Table]')
  if ($tableElement.length > 0) {
    $tableElement.first().DataTable().draw(false)
  };
});
