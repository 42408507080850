import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "enablable"];
  static values = {
    disable: Boolean
  };

  connect() {
    this.setState();
  }

  setState() {
    if (this.checkboxTarget.checked == this.disableValue) {
      this.enablableTargets.forEach(target => target.disabled = true);
    } else {
      this.enablableTargets.forEach(target => target.disabled = false);
    }
  }
}
