import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    selected: Boolean
  }

  toggleSelected() {
    if (this.selectedValue) {
      this.selectedValue = false
      this.markForDestruction()
    } else {
      this.selectedValue = true
      this.unmarkForDestruction()
    }
  }

  markForDestruction() {
    this.element.querySelector("input[name*='_destroy']").value = true
  }

  unmarkForDestruction() {
    this.element.querySelector("input[name*='_destroy']").value = false
  }
}
