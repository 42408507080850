// Custom stream actions
// Each is paired with a helper method defined in app/helpers/turbo_stream_actions_helper.rb

import { StreamActions } from "@hotwired/turbo"

// Load Alpine & morph
import Alpine from 'alpinejs'
import morph from '@alpinejs/morph'

window.Alpine = Alpine
Alpine.plugin(morph)

StreamActions.redirect = function() {
  const destination = this.getAttribute("destination");
  window.location.assign(destination);
}

StreamActions.addClass = function() {
  const className = this.getAttribute("classname");
  const selector = this.getAttribute("selector");
  Array.prototype.slice.call(document.querySelectorAll(selector))
   .forEach(function(element) {
      element.classList.add(className);
  });
}

StreamActions.removeClass = function() {
  const className = this.getAttribute("classname");
  const selector = this.getAttribute("selector");
  Array.prototype.slice.call(document.querySelectorAll(selector))
   .forEach(function(element) {
      element.classList.remove(className);
  });
}

StreamActions.setFocus = function() {
  const selector = this.getAttribute("selector");
  document.getElementById(selector).focus();
}

StreamActions.morph = function() {
  const selector = this.getAttribute("selector");
  const content = this.getAttribute("content");
  element = document.getElementById(selector);

  Alpine.morph(element,content, {
    updated(el, _toEl) {
      let event = document.createEvent('Event');
      event.initEvent('morph:after-morph', true, true);
      el.dispatchEvent(event);
    }
  });
}

StreamActions.triggerEvent = function() {
  const selector = this.getAttribute("selector");
  const eventName = this.getAttribute("event");
  const event = new Event(eventName);
  let element;

  if (selector) {
    element = document.getElementById(selector);
  } else {
    element = document;
  }

  element.dispatchEvent(event);
}

StreamActions.reload = function() {
  window.location.reload();
}

StreamActions.autoSubmit = function() {
  const selector = this.getAttribute("selector");
  document.getElementById(selector).requestSubmit();
}
