import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "inScopeFields",
    "outOfScopeFields",
    "inScopeReason",
    "outOfScopeReason"
  ]

  outOfScope = false;

  toggle(e) {
    let target = e.currentTarget;

    this.clearErrors();
    if(target.value == "false") {
      this.outOfScope = false;
      this.inScopeFieldsTarget.classList.add("active")
      this.outOfScopeFieldsTarget.classList.remove("active")
    } else {
      this.outOfScope = true;
      this.outOfScopeFieldsTarget.classList.add("active")
      this.inScopeFieldsTarget.classList.remove("active")
    }
  }

  clearErrors() {
    this.outOfScopeReasonTarget.classList.remove("invalid");
    this.inScopeReasonTarget.classList.remove("invalid");
  }

  validateThenSubmit(e) {
    e.preventDefault();
    let button = e.currentTarget;
    button.disabled = true;

    if(this.outOfScope && this.outOfScopeReasonTarget.value.length == 0) {
      this.outOfScopeReasonTarget.classList.add("invalid");
      button.disabled = false;
    } else if (!this.outOfScope && this.inScopeReasonTarget.value.length == 0) {
      this.inScopeReasonTarget.classList.add("invalid");
      button.disabled = false;
    } else {
      this.element.submit();
    }
  }
}
