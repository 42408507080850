import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {
  static values = {
    removeUrl: String,
  }

  connect() {
    setTimeout( () => {
      let table = jQuery('.dataTables_scrollBody>table.data-table').first();
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    let deleteButton = table.button('delete:name').nodes()
    if (deleteButton.length == 0) {
      table.button().add(null, this.deleteButton())
    }
  }

  deleteButton() {
    return {
      text: '<svg class="kolidecon kolidecon-badge-bad" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path d="M23 12l-2.44 2.78.34 3.68-3.61.82-1.89 3.18L12 21l-3.4 1.47-1.89-3.18-3.61-.82.34-3.69L1 12l2.44-2.79-.34-3.68 3.61-.81L8.6 1.54 12 3l3.4-1.46 1.89 3.18 3.61.82-.34 3.68L23 12zm-12.413-.001l-3.294 3.294a1 1 0 0 0 1.414 1.414l3.294-3.293 3.292 3.293a1 1 0 1 0 1.414-1.414l-3.292-3.294 3.292-3.292a1 1 0 1 0-1.414-1.414L12 10.585 8.71 7.293a1 1 0 0 0-1.415 1.414l3.29 3.292z"></path></svg>Remove From Group',
      name: 'delete',
      className: 'table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: async (e, dt, button, config) => {
        if (window.confirm("Are you sure you want to remove the selected devices?")) {
          let selectedNodes = dt.rows({ selected: true }).indexes()
          let ids = selectedNodes.map(idx => jQuery(dt.column('select:name').nodes()[idx])[0].querySelector(':scope > div').dataset.id);
          this.remove(Array.from(ids));
        }
      }
    }
  }

  remove(ids) {
    // Indicate loading is happening so they don't click things multiple times
    Turbo.navigator.delegate.adapter.showVisitProgressBarAfterDelay();

    fetch(this.removeUrlValue, {
      body: JSON.stringify({member_ids: ids}),
      method: 'DELETE',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }
}
