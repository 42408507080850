import { Controller } from "@hotwired/stimulus";

// User convenience controller to update the Response Hosts input when the ASC URL is updated.
export default class extends Controller {
  static targets = ["acsURL", "responseHosts"];

  get host() {
    const regex = new RegExp(/(?::\/\/)([a-z0-9\-._~%]+)/, "i");

    if (regex.test(this.acsURLTarget.value)) {
      return this.acsURLTarget.value.match(regex)[1];
    } else {
      return null;
    }
  }

  update() {
    this.responseHostsTarget.value = this.host;
  }
}
