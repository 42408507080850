import { Controller } from "@hotwired/stimulus";
import interact from 'interactjs'
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = [ "resizer", "searchable" ]

  connect() {
    this.installResizer()
  }

  installResizer() {
    interact(this.element).resizable({
      edges: { right: this.resizerTarget },
    })
    .on('resizemove', event => {
      let { x, y } = event.target.dataset

      x = parseFloat(x) || 0
      y = parseFloat(y) || 0

      Object.assign(event.target.style, {
        width: `${event.rect.width}px`,
        transform: `translate(${event.deltaRect.left}px, ${event.deltaRect.top}px)`
      })

      Object.assign(event.target.dataset, { x, y })
    })
  }

  search = debounce((event) => {
    const searchValue = event.target.value
    const inputMatcher = new RegExp(`${searchValue}.*`);

    if (searchValue == "") {
      this.searchableTargets.forEach(target => target.classList.remove("found"))
      this.searchableTargets.forEach(target => target.style.display = '')
      document.querySelectorAll(".reporting__browser-table").forEach(el => el.classList.remove("open"))
      document.querySelectorAll(".reporting__browser-table").forEach(el => el.style.display = 'block')
      document.querySelectorAll(".reporting__browser-table-fields").forEach(el => el.style.display = 'none')
    } else {
      this.searchableTargets.forEach(searchable => {
        if (searchable.dataset.searchText.match(inputMatcher)) {
          searchable.classList.add("found")
        }
      })

      this.searchableTargets.forEach(searchable => {
        if (searchable.classList.contains("found") && searchable.classList.contains("reporting__browser-table")) {
          searchable.querySelectorAll('.reporting__browser-table-field').forEach(el => el.classList.add('found'))
          searchable.querySelectorAll(".reporting__browser-table-name-collapse-button").forEach(el => el.click())
        } else if (searchable.classList.contains("found")) {
          searchable.parentNode.parentNode.classList.add("found")
          searchable.parentNode.parentNode.classList.add("open")
          searchable.parentNode.style.display = ''
        }
      })

      this.searchableTargets.forEach(searchable => {
        if (!searchable.classList.contains("found")) {
          searchable.style.display = 'none'
        }
      })
    }
  }, 250)
}
