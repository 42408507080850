import tippy from 'tippy.js'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    content: String
  }

  connect() {
    this.intentionallyHiding = false
    this.tooltipInstance = tippy(this.element, {
      allowHTML: true,
      content: this.contentValue,
      interactive: true,
      trigger: 'manual',
      placement: 'left',
      offset: [0, 80],
      onHide: () => {
        // click events inside the input hide the tooltip, even if the input is
        // active, so return false to cancel the 'hide' if we aren't
        // intentionally hiding
        return this.intentionallyHiding
      }
    })
  }

  show(){
    this.tooltipInstance.show()
    let modalWrapper = this.element.closest('.kolide-modal__wrapper')
    if(modalWrapper){
      // the tooltip renders outside the bounds of the modal
      modalWrapper.style.overflow = 'visible'
    }
  }

  hide(){
    // clicking inside the tooltip will trigger a hide event, so we need to
    // track if we are intentionally hiding in this method
    this.intentionallyHiding = true
    this.tooltipInstance.hide()
    let modalWrapper = this.element.closest('.kolide-modal__wrapper')
    if(modalWrapper){
      modalWrapper.style.overflow = 'auto'
    }
    this.intentionallyHiding = false
  }
}
