import { Controller } from "@hotwired/stimulus";
import { getParents } from '../../global/utils'

export default class extends Controller {
  static targets = ["menu", "menuChoices"];

  toggleOptions() {
    this.menuTarget.classList.toggle("open");
    getParents(this.menuTarget, '.DTFC_RightBodyLiner').forEach(el => el.classList.toggle('forced-visible'))
    getParents(this.menuTarget, '.DTFC_RightBodyWrapper').forEach(el => el.classList.toggle('forced-visible'))

    const menuChoicesBoundingbox = this.menuChoicesTarget.getBoundingClientRect();
    if (
      menuChoicesBoundingbox.top + menuChoicesBoundingbox.height >
      window.innerHeight
    ) {
      this.menuChoicesTarget.classList.add("top");
      this.menuChoicesTarget.classList.remove("bottom");
    } else {
      this.menuChoicesTarget.classList.add("bottom");
      this.menuChoicesTarget.classList.remove("top");
    }
  }

  hideOptions(event) {
    if (!this.element.contains(event.target)) {
      if (this.menuTarget.classList.contains("open")) {
        getParents(this.menuTarget, '.DTFC_RightBodyLiner').forEach(el => el.classList.remove('forced-visible'))
        getParents(this.menuTarget, '.DTFC_RightBodyWrapper').forEach(el => el.classList.remove('forced-visible'))
      }

      this.menuTarget.classList.remove("open");
      this.menuChoicesTarget.classList.remove("top", "bottom");
    }
  }
}
