import { Controller } from "@hotwired/stimulus";
import consumer from './../channels';

export default class extends Controller {
  static values = {
    url: String,
    type: String
  }

  connect() {
    this.updateBadge();

    if (this.typeValue) {
      this.badgeSubscription = consumer.subscriptions.create(
        { channel: 'BadgeChannel', badge_type: this.typeValue },
        {
          received: (data) => {
            this.updateBadge();
          },
        },
      )
    }
  }

  updateBadge() {
    fetch(this.urlValue)
    .then(response => response.json())
    .then(data => {

      if (data.badge === true) {
        this.element.style.display = "";
      } else {
        this.element.style.display = "none";
      }

      if (data.value) {
        this.element.innerHTML = `<span class="value">${data.value}</span>`;
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  disconnect() {
    if (typeof this.badgeSubscription !== 'undefined') {
      this.badgeSubscription.unsubscribe();
    }
  }
}
