import jstz from "jstimezonedetect"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "timezoneInput" ]

  connect() {
    this.timezoneInputTarget.value = this.findTimeZone();
  }

  // Sourced from https://github.com/iansinnott/jstz#use-with-rails
  // See User#jstz= method in the user model for more info.
  findTimeZone() {
    const oldIntl = window.Intl
    try {
      window.Intl = undefined
      const tz = jstz.determine().name()
      window.Intl = oldIntl
      return tz
    } catch (e) {
      // sometimes (on android) you can't override intl
      return jstz.determine().name()
    }
  }
}
