import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    eventNamespace: String
  };

  connect() {
    this.confirmEvent = new Event(`${this.eventNamespaceValue}-confirm`);
    this.cancelEvent = new Event(`${this.eventNamespaceValue}-cancel`);
  }

  confirm() {
    document.dispatchEvent(this.confirmEvent);
  }

  cancel() {
    document.dispatchEvent(this.cancelEvent);
  }
}
