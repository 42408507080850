// See: https://github.com/hotwired/turbo/pull/863#issuecomment-1470184953
document.addEventListener("turbo:frame-missing", function(event) {
  if (typeof bugsnagClient !== 'undefined') {
    bugsnagClient.notify(new Error("Turbo frame missing detected"));
  }
  event.preventDefault()

  // if the event is 500 or a 408 we can skip the visit
  if (event.detail.response && ![500, 408].includes(event.detail.response.status)) {
    event.detail.visit(event.detail.response)
  }
});
