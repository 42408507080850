import "datatables.net-dt"
import "datatables.net-buttons-dt"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.html5.js"
// import "datatables.net-colreorder-dt" ColReorderWithResize has replaced this
import "datatables.net-colresizewithreorder/ColReorderWithResize.js"
import "datatables.net-select"
import "datatables.net-select-dt"
import "datatables.net-scroller-dt"
import "datatables.net-fixedcolumns-dt"
import debounce from "lodash/debounce";
import $ from 'jquery'
import Rails from '@rails/ujs';

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["actions", "search", "secondaryActions", "table", "filteredCount", "totalCount", "column"]
  static values = {
    cache: Object,
    colvis: Boolean,
    csvRemoteUrl: String,
    deferLoading: Number,
    disableColumnReorder: Boolean,
    remoteUrl: String,
    disableCsv: Boolean,
    fixedcols: Boolean,
    fixedcolsLeft: Number,
    fixedcolsRight: Number,
    infiniteScroll: Boolean,
    noInitialSort: Boolean,
    preSearch: Boolean,
    select: Boolean,
    scrollx: Boolean,
    scrolly: String,
    pagelength: Number,
    columns: String,
    simpleajax: Boolean,
    remoteHttpMethod: String,
  }

  connect() {
    // Pagination should only be disabled when infinite scrolling
    // is used. Currently this really only needs to be used in the device
    // target selector in Live Query
    this.usePagination = !this.infiniteScrollValue

    $.fn.dataTable.ext.errMode = 'throw';
    let dataTableOptions = {
      dom: "RBt",
      buttons: [],
      paging: false,
      stateSave: true,
      stateSaveParams: (_, data) => {
        data.start = ""
        data.search.search = "";
      },
      // Use a different prefix for statesaving as we want to discard older
      // state from when the application was using infinite scrolling
      stateSaveCallback: function (settings, data) {
        const key = `DataTables_v2_${settings.sInstance}_${location.pathname}`
        const dataString = JSON.stringify(data)
        try {
          localStorage.setItem(key, dataString)
        }
        catch (e) {
          if (e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED')) {
            // Clear existing storage, then set key
            localStorage.clear()
            localStorage.setItem(key, dataString)
          }
        }
      },
      stateLoadCallback: (settings) => {
        let data = JSON.parse(localStorage.getItem('DataTables_v2_' + settings.sInstance + '_' + location.pathname)) || {}
        if (this.hasCacheValue) {
          data.start = this.cacheValue.dataStart
        } else {
          delete data.start
        }
        if (this.noInitialSortValue) {
          delete data.order
        }

        return data
      },
      colReorder: false,
    }

    if (this.fixedcolsValue) {
      dataTableOptions.fixedColumns = {
        heightMatch: 'none',
        leftColumns: this.fixedcolsLeftValue || 0,
        rightColumns: this.fixedcolsRightValue || 0,
      }
    }

    if (this.colvisValue) {
      let colvisButton = {
        extend: 'colvis',
        background: false,
        className: 'colvis-button',
        fade: 0,
        tag: "span",
        text: `<svg class="kolidecon kolidecon-column-reveal" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path fill-rule="evenodd" d="M10 5.979a7.262 7.262 0 0 0-.928 1.648L8.922 8l.15.373c.239.594.552 1.147.928 1.648V20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v.979zM4 6v3h4V6H4zm0 5v3h4v-3H4zm0 5v3h4v-3H4zM16 4c2.73 0 5.06 1.66 6 4-.94 2.34-3.27 4-6 4s-5.06-1.66-6-4c.94-2.34 3.27-4 6-4zm0 3c.56 0 1 .44 1 1s-.44 1-1 1-1-.44-1-1 .44-1 1-1zm0-1.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z"></path></svg><span>Columns</span><svg class="kolidecon kolidecon-caret-down" viewBox="0 0 16 16" version="1.1" width="16" height="16" aria-hidden="true"><path fill-rule="evenodd" d="M4.625 6.781C4.28 6.35 4.453 6 4.997 6h6.006c.55 0 .722.344.372.781l-3.061 3.827c-.173.216-.456.214-.628 0L4.625 6.78z"></path></svg>`,
      }
      if (this.selectValue) {
        colvisButton.columns = ":gt(0)"
      }
      dataTableOptions.buttons.push(colvisButton)
    }

    if (!this.disableCsvValue) {
      let csvUrl = this.csvRemoteUrlValue || this.remoteUrlValue
      let action = undefined
      if (csvUrl) {
        let url = new URL(csvUrl, location)
        let path = url.pathname.split('.')[0]
        let fullCSVUrl = `${path}.csv${url.search}`

        action = () => {
          window.location.assign(fullCSVUrl);
        }
      }
      dataTableOptions.buttons.push({
        extend: csvUrl ? undefined : 'csv',
        tag: 'span',
        text: `<svg class="kolidecon kolidecon-csv" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path fill-rule="evenodd" d="M6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm7 7h5.5L13 3.5V9zm-1.999 10.012c2.003.001 3.004-1.336 2.999-4.019V12h-4v3h2.144c.149 1.782-.295 3-1.143 3v1.012z"></path></svg><span>CSV</span>`,
        action: action,
      })
    }

    if (this.scrollxValue) {
      dataTableOptions.scrollX = true
      dataTableOptions.colReorder = {
        realtime: false
      }
    }

    if (this.selectValue) {
      if (this.scrollxValue) {
        dataTableOptions.colReorder.fixedColumnsLeft = 1;
      }
      dataTableOptions.select = {
        info: false,
        style: 'multi',
        selector: 'td:first-child',
      }
    }

    if (this.hasScrollyValue) {
      dataTableOptions.paging = true
      dataTableOptions.scroller = true
      dataTableOptions.deferRender = true
      dataTableOptions.scrollY = this.scrollyValue
    }

    dataTableOptions.language = { processing: `<span class="dataTables_loading">Loading...</span>` }

    if (this.usePagination) {
      dataTableOptions.scroller = false
      dataTableOptions.paging = true
      dataTableOptions.pageLength = parseInt(this.pagelengthValue) || 50
      dataTableOptions.pagingType = "simple_numbers"
      dataTableOptions.dom = 'Brtp<"data-table__spacer--small">i'
      dataTableOptions.language.info = "Displaying items <b>_START_-_END_</b> of <b>_TOTAL_</b> in total"
      dataTableOptions.language.infoFiltered = "" //" (filtered from _TOTAL_ items total)"

      dataTableOptions.drawCallback = (_settings) => {
        this.renderPagination()
        this.adjustColumns()
      }
    }

    if (this.hasRemoteUrlValue) {
      dataTableOptions.processing = true
      dataTableOptions.serverSide = true
      dataTableOptions.deferRender = true
      if (this.hasColumnsValue) {
        dataTableOptions.columns = this.columnsValue !== "load-from-th" ?
          JSON.parse(this.columnsValue) :
          this.columnTargets.map(c => ({ data: c.dataset['name'], className: c.getAttribute('class') }))
      }

      if (this.simpleajaxValue) {
        dataTableOptions.serverSide = false
      }

      if (!this.usePagination) {
        dataTableOptions.dom = "Brt"
        dataTableOptions.scroller = {
          displayBuffer: 2
        }
        dataTableOptions.drawCallback = (settings) => {
          this.adjustColumns()
        }
      }

      dataTableOptions.ajax = {
        "url": this.remoteUrlValue,
        "type": this.remoteHttpMethodValue || "GET", // Ajax HTTP method
        'beforeSend': function (request) {
          if (this.remoteHttpMethodValue != "GET") {
            request.setRequestHeader("X-CSRF-Token", Rails.csrfToken());
          }
        }
      }
    }

    // Hook any datatable XHR requests and check the status code on responses.
    // If we get a 401 (Unauthorized) redirect the user to where the server
    // wants us to go to address the issue.
    $(this.data.element).on('xhr.dt', function ( e, settings, json, xhr ) {
      if (xhr.status == 401) {
        window.location.replace(xhr.responseJSON.redirect_to);
      }
    })

    if (this.hasFilteredCountTarget) {
      $(this.data.element).on('draw.dt', (e, settings) => {
        let api = new $.fn.dataTable.Api(settings)
        let counts = api.table().page.info()
        $(this.filteredCountTarget).text(parseInt(counts.recordsDisplay).toLocaleString())

        // Ensure total records is the same or greater than selected records
        if (this.hasTotalCountTarget) {
          let filteredCount = counts.recordsDisplay
          let totalCount = parseInt($(this.totalCountTarget).text().replace(/[.,]/g, ''))
          if (filteredCount > totalCount) {
            let originalText = $(this.totalCountTarget).text().trim()
            let newText = originalText.replace(/[.,]/g, '').replace(/^\d+/, filteredCount.toLocaleString())
            $(this.totalCountTarget).text(newText)
          }
        }
      });
    }

    if (this.disableColumnReorderValue) {
      dataTableOptions.colReorder = false
    }

    if (this.noInitialSortValue) {
      dataTableOptions.order = []
    }

    if (this.hasCacheValue) {
      let cacheSettings = this.cacheValue
      dataTableOptions.deferLoading = cacheSettings.counts
      dataTableOptions.order = cacheSettings.order
      dataTableOptions.search = cacheSettings.search
      dataTableOptions.displayStart = cacheSettings.displayStart
    }

    if (this.hasDeferLoadingValue) {
      dataTableOptions.deferLoading = this.deferLoadingValue;
    }

    // Destroy a datatable if it exists before we initialized it.
    if ($.fn.dataTable.isDataTable($(this.tableTarget))) {
      $(this.tableTarget).DataTable().destroy()
    }
    this.table = $(this.tableTarget).DataTable(dataTableOptions)

    if (this.selectValue) {
      this.setupCheckboxes(this.table)
    }

    $(this.tableTargets).show()

    const buttons = this.table.buttons().container()
    if ($(this.tableTarget).attr("data-datatables-actions") == "secondary") {
      const templateButtons = $(this.secondaryActionsTarget.children)
      templateButtons.appendTo(buttons)

      $(this.secondaryActionsTarget).html("")
      buttons.appendTo(this.secondaryActionsTarget)
    } else {
      const templateButtons = $(this.actionsTarget.children)
      templateButtons.appendTo(buttons)

      $(this.actionsTarget).html("")
      buttons.appendTo(this.actionsTarget)
    }

    if (this.scrollxValue && $(this.tableTarget).attr("data-datatables-no-sticky") != "true") {
      let existingStyle = $('.dataTables_scrollHead').attr('style')
      $('.dataTables_scrollHead').attr('style', "position: -webkit-sticky !important; position: sticky !important; " + existingStyle)
    } else {
      $('.dataTables_scrollHead').css({ top: 0 })
    }

    if ($(this.searchTarget).val().length === 0) {
      $(this.searchTarget).val(this.table.search())
    }

    // if the search field is pre-populated with a value run a search as soon possible
    if (this.preSearchValue && $(this.searchTarget).val().length > 0) {
      $(this.data.element).one('preDraw.dt', () => {
        this.search();
        return false
      });
    }

    this.renderPagination()
    this.adjustColumns()
  }

  adjustColumns() {
    // After adjusting columns, we adjust them 5 more times because
    // JS running in the cells may change the width. This is basically a
    // hack so we do not have to create callback hell for any JS that lives in
    // a cell, or install expensive MutationObservers
    //
    // if any columns have a relative size defined, they'll cause the columns to
    // subtly change size each time table.columns.adjust() is called (e.g. <th
    // class="min">)
    for (let i = 0; i < 5; i++) {
      setTimeout(() => {
        if (this.table) {
          this.table.columns.adjust()
        }
      }, i * 500)
    }
  }

  redraw() {
    this.adjustColumns()
  }

  disconnect() {
    if (this.table === undefined) {
      return;
    }
    this.table.destroy()
  }

  search = debounce(() => {
    if ($('.dataTables_processing:visible').length === 0) {
      this.table.search(this.searchTarget.value).draw();
    }
  }, 400);

  renderPagination() {
    // sometimes `this.table` is unset, e.g. when TurboDrive cache is read
    if (this.table && this.usePagination) {
      let pageInfo = this.table.page.info()
      // only show pagintion if there are multiple pages
      let showPagination = pageInfo.pages > 1
      $('div.dataTables_paginate').toggle(showPagination)
      $('div.dataTables_info').toggle(showPagination)
    }
  }

  setupCheckboxes(table) {
    $('table').on('click', 'th.dataTables_checkbox', (evt) => {
      const $target = $(evt.currentTarget).parent();

      if ($target.hasClass('selected')) {
        table.rows().deselect();
        $target.removeClass('selected');
      } else {
        table.rows().select();
        $target.addClass('selected');
      }
    })
    table.on('select.dt deselect.dt', (evt) => {
      const $target = $('th.dataTables_checkbox').parent();
      const allSelectedCount = table.rows({ selected: true }).count();
      const allRowsCount = table.rows().count();
      const $editButtons = $(this.data.element.querySelectorAll('[data-bulk-editor]'))

      if (allSelectedCount === 0) {
        $target.removeClass('selected partial');
        $editButtons.addClass('table-button--disabled')
      } else if (allSelectedCount !== allRowsCount) {
        $target.removeClass('selected').addClass('partial');
        $editButtons.removeClass('table-button--disabled')
      } else {
        $target.addClass('selected').removeClass('partial');
        $editButtons.removeClass('table-button--disabled')
      }
    });
  }
}
