import tippy from 'tippy.js'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    tippy(this.element, {
      allowHTML: this.element.getAttribute('data-tippy-allow-html') ?? false,
      content: this.element.getAttribute('data-tippy'),
      popperOptions: {
          strategy: 'fixed',
      }
    })
  }
}
