import { Controller } from "@hotwired/stimulus";
import { toggle } from '../../../global/utils'

export default class extends Controller {
  static targets = [ 'infoRow', 'nestedDetailsRow', 'caretRight', 'caretDown']

  toggle() {
    toggle(this.nestedDetailsRowTarget)
    toggle(this.caretRightTarget)
    toggle(this.caretDownTarget)
  }
}
