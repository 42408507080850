import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "menu", "form", "hiddenFields", "checkTargetItem", "search" ]

  connect() {
    this.populateHiddenFields()
  }

  toggle() {
    this.menuTarget.classList.toggle("open")
    this.searchTarget.focus()
  }

  toggleTag(event) {
    event.currentTarget.classList.toggle("added")
    this.populateHiddenFields()
  }

  populateHiddenFields() {
    this.hiddenFieldsTarget.innerHTML = ""
    for (let checkTargetItem of this.checkTargetItemTargets) {
      if (checkTargetItem.classList.contains("added")) {
        let input = document.createElement('input')
        input.setAttribute('multiple', 'multiple')
        input.setAttribute('value', checkTargetItem.dataset.checkTagId)
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', 'check[check_tag_ids][]')

        this.hiddenFieldsTarget.appendChild(input)
      }
    }

    let input = document.createElement('input')
    input.setAttribute('multiple', 'multiple')
    input.setAttribute('value', '')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', 'check[check_tag_ids][]')

    // Always include a blank value to avoid an HTTP 400 error because none of the
    // expected params were sent.
    this.hiddenFieldsTarget.appendChild(input)
  }

  submitForm() {
    Rails.fire(this.formTarget, 'submit')
  }

  search = this.throttle(() => {
    const searchValueArray = this.searchTarget.value.trim().split("");
    const searchRegex = new RegExp(`${searchValueArray.join("(.*)")}`, "i");

    this.checkTargetItemTargets.forEach(option => {
      if (
        option.dataset.filterSearch &&
        !option.dataset.filterSearch.match(searchRegex)
      ) {
        option.style.display = "none";
      } else {
        option.style.display = "block";
      }
    });
  }, 250);

  throttle(func, wait) {
    return () => {
      let boundFunc = func.bind(this, arguments);
      setTimeout(boundFunc, wait);
    };
  }

  hide(event) {
    if (!this.element.contains(event.target) && this.menuTarget.classList.contains("open")) {
      this.menuTarget.classList.remove("open")
      this.submitForm()
    }
  }

  cancelSidebarClosure(event) {
    if (this.menuTarget.classList.contains("open")) {
      event.preventDefault()
    }
  }
}
