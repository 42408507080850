import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["statusInput", "contactInputWrapper"]

  connect() {
    this.maybeShowConditionalInput()
  }

  maybeShowConditionalInput() {
    let showableValues = ["closed_implemented", "closed_rejected"]
    let val = this.statusInputTarget.value

    if (showableValues.includes(val)) {
      this.contactInputWrapperTarget.style.display = 'block';
    } else {
      this.contactInputWrapperTarget.style.display = 'none';
    }
  }
}
