import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["searchable"];

  search = debounce((event) => {
    const searchValue = event.target.value;
    const inputMatcher = new RegExp(`${searchValue}.*`, 'i');

    this.searchableTargets.forEach(el => el.classList.remove("hidden-by-search"));

    if (searchValue != "") {
      this.searchableTargets.forEach(searchable => {
        if (!searchable.dataset.searchText.match(inputMatcher)) {
          searchable.classList.add("hidden-by-search");
        }
      });
    }
  }, 250);
}
