import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selector", "intervalField"]

  connect() {
    this.revealRelevantFields()
  }

  revealRelevantFields() {
    if (this.selectorTarget.value == "on_interval") {
      this.intervalFieldTarget.style.display = 'block'
    } else {
      this.intervalFieldTarget.style.display = 'none'
    }
  }
}
