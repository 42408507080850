import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hiddenProperty", "checkbox" ]

  connect() {
    if (this.hascheckboxTarget) {
      this.toggle()
    }
  }

  toggle() {
    if (this.checkboxTarget.checked) {
      this.hiddenPropertyTargets.forEach(el => el.classList.add("visible"))
    } else {
      this.hiddenPropertyTargets.forEach(el => el.classList.remove("visible"))
    }
  }
}
