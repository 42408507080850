import { Controller } from '@hotwired/stimulus';
import consumer from './../../channels';

export default class extends Controller {
  static targets = ["requests"];

  connect() {
    this.deviceDataExportSub = consumer.subscriptions.create(
      'DeviceDataExportsChannel',
      {
        received: (data) => {
          let requestToUpdate = this.requestsTarget.querySelector(`#data-download-request-${data.export_id}`)
          if (requestToUpdate) {
            requestToUpdate.outerHTML = data.html;
          }
        },
      },
    );
  }

  disconnect() {
    this.deviceDataExportSub.unsubscribe();
  }
}
