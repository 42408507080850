import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';
import { isVisible } from "../../global/utils";

export default class extends Controller {
  static targets = [
    "summary",
    "summaryLabel",
    "modalContent",
    "deviceInputs",
    "deviceInput",
    "previewPane",
    "stagedDevicesBadge",
    "device",
    "group",
    "viewTargets",
    "table",
    "targetedDevices",
    "targetedDevicesCount",
    "search",
    "searchInput",
    "stagedDevices",
    "back",
  ]

  static values = {
    launchUrl: String,
    modelName: { type: String, default: "live_query_campaign" },
    attributeName: { type: String, default: "targeted_device_ids" }
  }

  modal = null
  stagedTargetIds = null;
  stagedTargetGroups = null;
  stashedSearch = "";
  targetOnly = false;

  connect() {
    this.stagedTargetIds = new Set(JSON.parse(this.deviceInputsTarget.dataset.targetIds));
    this.stagedTargetGroups = JSON.parse(this.deviceInputsTarget.dataset.targetGroups);
    this.updateDeviceInputs();
  }

  open(event) {
    event.preventDefault();

    if (this.modal) {
      kModal.show()
      this.loadTargetsTable();
    } else {
      let datatableEl = this.modalContentTarget.querySelector("[zzzdata-controller~=datatables]");
      datatableEl.setAttribute('zzzdata-datatables-remote-url-value', `${datatableEl.getAttribute('zzzdata-datatables-remote-url-value')}?${this.urlSearchParams()}`);
      this.modal = true
      kModal.init({
        content: this.modalContentTarget.innerHTML.replace(/zzzdata/g, "data"),
        ujs: true,
        launchingUrl: this.launchUrlValue,
        appendTo: this.element
      });
    }

    this.stagedTargetIds = new Set(JSON.parse(this.deviceInputsTarget.dataset.targetIds));
    this.updateDeviceInputs();

    this.updateBadge();
    this.bindDataTable();
  }

  hide() {
    kModal.hide();
    this.stagedTargetIds.clear();
  }

  bindDataTable() {
    let datatableEl = this.tableTarget.closest("[data-controller~=datatables]");
    let datatableController = this.application.getControllerForElementAndIdentifier(datatableEl, 'datatables');

    if (datatableController) {
      this.table = datatableController.table;
    }

    this.activeUrl = this.activeUrl || datatableEl.dataset['datatablesRemoteUrlValue'];
  }

  submitTargets() {
    this.updateDeviceInputs(true);
    kModal.hide();

    let event = document.createEvent('Event');
    event.initEvent('change', true, true);
    this.deviceInputsTarget.dispatchEvent(event);
  }

  onDeviceSelected(event) {
    this.previewDevice(event.target.closest('.target-selector__device-list-item'));
  }

  previewDevice(deviceListEl) {
    let url = deviceListEl.getAttribute("data-target-preview-url")
    document.querySelectorAll(".target-selector__device-list tr").forEach(el => el.classList.remove("selected"))
    event.target.closest('tr').classList.add("selected")
    this.previewPaneTarget.innerHTML = `<div style="height:100%; display: flex; align-items: center; justify-content: center;">Loading...</div>`
    fetch(url)
      .then(response => response.json())
      .then(json => {
        this.previewPaneTarget.innerHTML = json.html
      });
  }

  toggleDevice(event) {
    event.stopImmediatePropagation();

    let deviceRowEl = event.target.closest('.target-selector__device-list-item');
    if (this.stagedTargetIds.has(JSON.parse(deviceRowEl.dataset.targetDeviceId))) {
      this.unstageDevice(event);
    } else {
      this.stageDevice(event);
    }
  }

  stageDevice(event) {
    let tableRowEl = event.target.closest('tr');
    let deviceRowEl = event.target.closest('.target-selector__device-list-item');

    this.stagedTargetIds.add(JSON.parse(deviceRowEl.dataset.targetDeviceId));
    deviceRowEl.classList.add("target-selector__device-list-item--selected");
    deviceRowEl.querySelector('.colvis-check').classList.add("active");
    tableRowEl.classList.add("staged");

    this.updateBadge();
  }

  unstageDevice(event) {
    let deviceRowEl = event.target.closest('.target-selector__device-list-item');
    let tableRowEl = event.target.closest('tr');

    this.stagedTargetIds.delete(JSON.parse(deviceRowEl.dataset.targetDeviceId));
    this.updateBadge();
    deviceRowEl.classList.remove("target-selector__device-list-item--selected");
    deviceRowEl.querySelector('.colvis-check').classList.remove("active");
    tableRowEl.classList.remove("staged");

    if (Array.from(document.querySelectorAll('.target-selector__footer-back')).some(isVisible)) {
      this.loadTargetsTable();
    }
  }

  toggleGroup(event) {
    event.stopImmediatePropagation();

    let groupRowEl = event.target.closest('.target-selector__device-list-item');
    if (this.isGroupSelected(groupRowEl.dataset.targetGroupSlug)) {
      this.unstageGroup(event);
    } else {
      this.stageGroup(event);
    }
  }

  stageGroup(event) {
    let tableRowEl = event.target.closest('tr');
    let groupRowEl = event.target.closest('.target-selector__device-list-item');

    this.toggleGroupState(groupRowEl.dataset.targetGroupSlug)
    groupRowEl.classList.add("target-selector__device-list-item--selected");
    groupRowEl.querySelector('.colvis-check').classList.add("active");
    tableRowEl.classList.add("staged");

    this.loadTargetsTable();
    this.updateBadge();
  }

  unstageGroup(event) {
    let tableRowEl = event.target.closest('tr');
    let groupRowEl = event.target.closest('.target-selector__device-list-item');

    this.toggleGroupState(groupRowEl.dataset.targetGroupSlug)
    this.updateBadge();

    groupRowEl.classList.remove("target-selector__device-list-item--selected");
    groupRowEl.querySelector('.colvis-check').classList.remove("active");
    tableRowEl.classList.remove("staged");

    this.loadTargetsTable();
  }

  clearTargets() {
    this.stagedTargetIds.clear();
    this.stagedTargetGroups = {};

    this.updateBadge();
    this.viewAll();
    document.querySelectorAll("tr.staged").forEach(el => el.classList.remove("staged"));
  }

  viewTargets() {
    let datatableEl = this.tableTarget.closest("[data-controller~=datatables]");
    this.activeUrl = datatableEl.dataset['datatablesRemoteUrlTargets'];
    this.loadTargetsTable(true);
    this.searchTarget.classList.add("hidden");
    this.targetedDevicesTarget.classList.remove("hidden");
    this.backTarget.classList.remove("hidden");
    this.stagedDevicesTarget.classList.add("hidden");
    this.table.table().container().classList.add("targets-list");
    this.table.table().container().classList.remove("devices-selector");

    this.onlyTargets = true;
  }

  loadTargetsTable(stashSearch = false) {
    if (!this.table) {
      this.bindDataTable();
    }

    let group_slugs = [];
    for (let slug in this.stagedTargetGroups) {
      if (this.stagedTargetGroups[slug]) {
        group_slugs.push(slug);
      }
    }

    // TODO: Figure out a different way to send these params if there are
    // too many IDs for a valid GET request. Until then, let's trim the request
    let letTrimmedURL = `${this.activeUrl}?${this.urlSearchParams()}`.slice(0, 2048)

    this.table.ajax.url(letTrimmedURL);
    if (stashSearch) {
      this.stashedSearch = this.searchInputTarget.value;
      this.table.search("").draw();
    } else {
      this.table.search(this.stashedSearch).draw();
      this.stashedSearch = "";
      this.table.load();
    }
  }

  urlSearchParams() {
    return encodeURI(`ids=${[...this.stagedTargetIds].join(',')}&group_slugs=${[...this.getSelectedGroupSlugs()]}`);
  }

  viewAll() {
    let datatableEl = this.tableTarget.closest("[data-controller~=datatables]");
    this.activeUrl = datatableEl.dataset['datatablesRemoteUrlValue'];
    this.loadTargetsTable();
    this.searchTarget.classList.remove("hidden");
    this.targetedDevicesTarget.classList.add("hidden");
    this.backTarget.classList.add("hidden");
    this.stagedDevicesTarget.classList.remove("hidden");
    this.table.table().container().classList.remove("targets-list");
    this.table.table().container().classList.add("devices-selector");
    this.onlyTargets = false;
  }

  updateBadge() {
    if (this.hasStagedDevicesBadgeTarget) {
      this.syncTargetsWithServer()
        .then(summary => {
          this.targetedDevicesCountTarget.innerText = summary.count;
          this.stagedDevicesBadgeTarget.innerText = summary.count;

          if (summary.count) {
            this.stagedDevicesBadgeTarget.classList.remove("target-selector__footer-target-count-badge--empty");
            this.stagedDevicesTarget.classList.remove("target-selector__footer-target-count--empty");
          } else {
            this.stagedDevicesBadgeTarget.classList.add("target-selector__footer-target-count-badge--empty");
            this.stagedDevicesTarget.classList.add("target-selector__footer-target-count--empty");
          }
        });
    }
  }

  updateSummary(devicesCount = 0, summaryLabel) {
    if (this.hasSummaryLabelTarget) {
      if (devicesCount) {
        this.summaryTarget.classList.remove("target-selector__summary--warning");
      } else {
        this.summaryTarget.classList.add("target-selector__summary--warning");
      }
      this.summaryLabelTarget.innerHTML = summaryLabel;
    }
  }

  async updateDeviceInputs(fire_change_event=false) {
    await this.syncTargetsWithServer()
      .then(summary => this.updateSummary(summary.count, summary.label));

    const fragment = new DocumentFragment();

    // Remove inputs and add an empty input to clear the target on the form endpoint.
    // Without an empty input the form will create a bad request with improper form data.
    if (this.stagedTargetIds.size > 0) {
      this.stagedTargetIds.forEach(id => {
        const el = document.createElement('input');
        el.setAttribute("multiple", "multiple");
        el.setAttribute("value", id);
        el.setAttribute("data-target", "target-selector.deviceInput");
        el.setAttribute("type", "hidden");
        el.setAttribute("name", `${this.modelNameValue}[${this.attributeNameValue}][]`);
        el.setAttribute("id", `${this.modelNameValue}_${this.attributeNameValue}`);

        fragment.appendChild(el);
      });
    } else {
        const el = document.createElement('input');
        el.setAttribute("multiple", "multiple");
        el.setAttribute("value", "");
        el.setAttribute("data-target", "target-selector.deviceInput");
        el.setAttribute("type", "hidden");
        el.setAttribute("name", `${this.modelNameValue}[${this.attributeNameValue}][]`);
        el.setAttribute("id", `${this.modelNameValue}_${this.attributeNameValue}`);

        fragment.appendChild(el);
    }

    Object.keys(this.stagedTargetGroups).forEach((group) => {
      const el = document.createElement('input');
      el.setAttribute("value", this.stagedTargetGroups[group]);
      el.setAttribute("type", "hidden");
      el.setAttribute("name", `${this.modelNameValue}[${group}]`);
      el.setAttribute("id", `${this.modelNameValue}_targeted_${group}`);

      fragment.appendChild(el);
    });

    this.deviceInputsTarget.dataset.targetIds = JSON.stringify(Array.from(this.stagedTargetIds));
    this.deviceInputsTarget.dataset.targetGroups = JSON.stringify(this.stagedTargetGroups);

    this.deviceInputsTarget.innerHTML = '';
    this.deviceInputsTarget.appendChild(fragment);

    let eventToFire;
    if (fire_change_event) {
      eventToFire = new Event("target-selector-change");
    } else {
      eventToFire = new Event("target-selector-loaded");
    }
    this.element.dispatchEvent(eventToFire);
  }

  isGroupSelected(groupSlug) {
    return !!this.stagedTargetGroups[groupSlug];
  }

  getSelectedGroupSlugs() {
    let group_slugs = [];
    for (let slug in this.stagedTargetGroups) {
      if (this.stagedTargetGroups[slug]) {
        group_slugs.push(slug);
      }
    }

    return group_slugs;
  }

  toggleGroupState(groupSlug) {
    this.stagedTargetGroups[groupSlug] = !this.stagedTargetGroups[groupSlug];
  }

  getTargetsSummary() {
    let datatableEl, countUrl;

    if (this.hasTableTarget) {
      datatableEl = this.tableTarget.closest("[data-controller~=datatables]");
      countUrl = datatableEl.dataset['datatablesRemoteUrlCount'];
    } else {
      datatableEl = this.modalContentTarget.querySelector("[zzzdata-controller~=datatables]");
      countUrl = datatableEl.getAttribute('zzzdata-datatables-remote-url-count');
    }

    return fetch(countUrl, {
      method: 'post',
      body: this.urlSearchParams(),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }

  syncTargetsWithServer() {
    let controller = this;

    return this.getTargetsSummary()
      .then(summary => {
        controller.stagedTargetIds = new Set(summary.target_ids);
        Object.keys(controller.stagedTargetGroups).forEach(v => controller.stagedTargetGroups[v] = false);
        controller.stagedTargetGroups = summary.target_groups.reduce((acc, group) => {
          acc[group] = true;
          return acc;
        }, controller.stagedTargetGroups);

        return summary
      });
  }
}
