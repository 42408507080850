import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {
  static targets = ["table", "actions"]
  static values = {
    apiUrl: String
  }

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout(() => {
      // wonky, but tableTarget doesn't work due too the fact that our
      // datatbales scrolling plugin actually duplicates the initial table
      // element, and the real datatable we want to interact with is this
      // second, duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())
    }, 250);

  }

  addActionButtons(table) {
    let requestButton = table.button('request:name').nodes()

    // need to check for the button existence because the 'connect' for this
    // controller is being called multiple times on turbo:load, despite
    // being called only once on a full page load/refresh.
    if (requestButton.length == 0) {
      table.button().add(null, this.requestButton())
    }
  }

  requestButton() {
    return {
      text: '<svg height="23" class="kolidecon kolidecon-slack" viewBox="0 0 24 24" version="1.1" width="23" aria-hidden="true"><path d="M8.474 5.842a2.421 2.421 0 1 1 2.42-2.42v2.42h-2.42zm9.684 3.632a2.421 2.421 0 1 1 2.42 2.42h-2.42v-2.42zm-3.632 9.684a2.421 2.421 0 1 1-2.42 2.42v-2.42h2.42zm-9.684-3.632a2.421 2.421 0 1 1-2.42-2.42h2.42v2.42zm3.632-8.473a2.421 2.421 0 1 1 0 4.842H2.42a2.42 2.42 0 0 1 0-4.842h6.053zm12.105 6.052a2.421 2.421 0 1 1 0 4.842h-6.053a2.42 2.42 0 0 1 0-4.842h6.053zm-9.684 8.474a2.421 2.421 0 1 1-4.842 0v-6.053a2.42 2.42 0 0 1 4.842 0v6.053zm6.052-12.105a2.421 2.421 0 1 1-4.842 0V3.42a2.42 2.42 0 0 1 4.842 0v6.053z"></path></svg>Request MDM Enrollment',
      name: 'request',
      className: 'table-button__inverted table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        ids.each((idx) => {
          let id = jQuery(dt.column('online:name').nodes()[idx]).find('div').attr('data-mac-id')
          this.requestMdm(id)
        })
      }
    }
  }

  requestMdm(id) {
    let endpoint = this.apiUrlValue + `/${id}.json`
    fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then((response) => {
      if (response.status < 200 || response.status > 299) {
        console.error(response)
      }
      location.reload();
    })
  }
}
