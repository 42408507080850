import { Controller } from "@hotwired/stimulus"
var fetch_retry = require('fetch-retry')(fetch);

export default class extends Controller {
  static targets = [ "container", "spinner" ]

  connect() {
    let url = this.data.get('url')

    if (this.hasSpinnerTarget) {
      setTimeout(() => {
        this.spinnerTarget.style.display = "block"
      }, 1000)
    }

    fetch_retry(url, {
      retryOn: [409],
      retries: 20,
      retryDelay: function(attempt, error, response) {
        return Math.pow(2, attempt) * 1000;
      }
    }).then((response) => {
      return response.text()})
    .then((html) => {
      this.containerTarget.outerHTML = html
    })
  }
}
