import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [
    "fileInput",
    "uploadButton",
    "cancelButton",
    "clearButton",
    "form",
    "loader",
    "errorMessage",
    "image"
  ]

  static values = {
    directUploadUrl: String,
  }

  show(event) {
    event.preventDefault();
    this.showFileInput();
  }

  showFileInput() {
    this.fileInputTarget.style.display = "block";
    this.cancelButtonTarget.style.display = "block";
    this.uploadButtonTarget.style.display = "none";
    this.clearButtonTarget.style.display = "none";
    this.loaderTarget.style.display = "none";
  }

  cancel(event) {
    event.preventDefault();
    this.hideFileInput();
  }

  hideFileInput() {
    this.fileInputTarget.style.display = "none";
    this.cancelButtonTarget.style.display = "none";
    this.uploadButtonTarget.style.display = "block";
    this.clearButtonTarget.style.display = "block";
    this.loaderTarget.style.display = "none";
    this.errorMessageTarget.style.display = "none"
  }

  submit() {
    let file = this.fileInputTarget.files[0]
    this.fileInputTarget.style.display = "none";
    this.cancelButtonTarget.style.display = "none";
    this.uploadButtonTarget.style.display = "none";
    this.clearButtonTarget.style.display = "none";
    this.loaderTarget.style.display = "block";
    this.errorMessageTarget.style.display = "none"
    this.uploadFile(file);
    this.fileInputTarget.value = null
  }

  uploadFile(file) {
    const url = this.directUploadUrlValue;
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        this.hideFileInput();
        this.errorMessageTarget.innerHTML = error.error || "There was an error uploading this file";
        this.errorMessageTarget.style.display = "block";
      } else {
        const blobUrl = `/rails/active_storage/blobs/${blob.signed_id}/${file.name}`;
        const hiddenField = document.createElement("input");
        this.imageTarget.src = blobUrl;
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.fileInputTarget.name;
        this.formTarget.appendChild(hiddenField);
        this.hideFileInput();
      }
    })
  }
}
