import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list"]

  toggle() {
    if (this.element.classList.contains('open')) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    this.listTargets.forEach(target => target.style.display = "block")
    this.element.classList.add('open')
  }

  hide() {
    this.listTargets.forEach(target => target.style.display = "none")
    this.element.classList.remove('open')
  }
}
