import { Controller } from "@hotwired/stimulus";
import { fadeIn, fadeOut } from '../../../global/utils'

export default class extends Controller {
  static targets = [ 'downloads', 'connectionStatus', 'backToOrg', 'backToDownloads' ];

  download() {
    this.backToOrgTarget.style.display = "none";
    this.backToDownloadsTarget.style.display = "";

    fadeOut(this.downloadsTarget).then(() => {
      fadeIn(this.connectionStatusTarget)
    })
  }

  restoreDownloads(event) {
    event.preventDefault();
    this.connectionStatusTarget.style.display = "none";
    this.downloadsTarget.style.display = "";
    this.backToDownloadsTarget.style.display = "none";
    this.backToOrgTarget.style.display = "";
  }
}
