import { animateCSS } from "./animate_css"
import { setAnimationSpeed } from "./set_animation_speed"

export const fadeInAndOut = async (el, speed) => {
  setAnimationSpeed(el, speed);
  el.style.display = 'initial';
  await animateCSS(el, 'fadeIn');
  await animateCSS(el, 'fadeOut');
  el.style.display = 'none';
}
