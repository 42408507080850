import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["runButton", "summary"];

  setRunButtonState() {
    let labelEl = this.summaryTarget.querySelector(".target-selector__summary-label");
    if (labelEl.textContent !== "No Devices Targeted") {
      this.runButtonTarget.removeAttribute("disabled");
    } else {
      this.runButtonTarget.setAttribute("disabled", "true");
    }
  }
}
