import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatbales
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('.dataTables_scrollBody>table.data-table').first();
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    let archiveButton = table.button('archive:name').nodes()
    if(archiveButton.length == 0) {
      table.button().add(null, this.archiveButton())
    }
  }

  archiveButton() {
    return {
      text: "Archive selected",
      name: 'archive',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: async (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        let promises = []
        ids.each((idx)=> {
          let personURL = jQuery(dt.column('name:name').nodes()[idx]).find('a').attr('href')
          let endpoint = personURL + '/archive'
          promises.push(this.archive(endpoint))
        })
        await Promise.all(promises)
        location.reload()
      }
    }
  }

  archive(endpoint) {
    return fetch(endpoint, {
      method: 'PATCH',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    })
  }
}
