import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["useDefault", "customize", "addButton", "fieldset"];

  connect() {
    if(this.hasUseDefaultTarget && this.useDefaultTarget.checked) {
      this.disableButtonAndFields();
    }
  }

  isCustomizing() {
    return !this.hasCustomizeTarget || this.customizeTarget.checked
  }

  removeItem(e) {
    e.preventDefault();
    if(this.isCustomizing() && this.fieldsetTargets.length > 1) {
      e.currentTarget.parentElement.remove();
    }
  }

  addMore(e) {
    e.preventDefault();
    let inputs = this.fieldsetTargets;
    let lastInput = inputs[inputs.length - 1];
    let newNode = lastInput.cloneNode(true);
    this.clearFieldsInFieldset(newNode);
    lastInput.after(newNode);
    return false
  }

  clearFieldsInFieldset(node) {
    node.querySelectorAll("input, select").forEach( (el) => {
      if(el.localName === "select") {
        // Default value is always first, select that...
        el.querySectorAll("option")[0].selected = true;
      } else {
        el.value = null;
      }
    })
  }

  disableButtonAndFields() {
    this.fieldsetTargets.forEach((el) => { this.toggleFieldsInFieldset(el) })
    if ( this.hasAddButtonTarget ) {
      this.addButtonTarget.disabled = true;
    }
  }

  toggleFieldsInFieldset(node) {
    node.querySelectorAll("input, select").forEach( (el) => {
      el.disabled = !el.disabled;
    })
  }

  enableButtonAndFields() {
    this.fieldsetTargets.forEach((el) => {
      this.toggleFieldsInFieldset(el);
    })
    if ( this.hasAddButtonTarget ) {
      this.addButtonTarget.disabled = false
    }
  }
}
