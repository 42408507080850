import { Controller } from "@hotwired/stimulus"
import consumer from './../../channels';

export default class extends Controller {
  static targets = ["refreshButton", "refreshButtonLabel", "retrievedAt"]
  static values = {
    id: String,
  }

  connect() {
    let element = this.element
    let refreshButtonTarget = this.refreshButtonTarget
    let refreshButtonLabelTarget = this.refreshButtonLabelTarget
    let retrievedAtTarget = this.retrievedAtTarget

    consumer.subscriptions.create(
      { channel: "OsqueryDevicePropertyRefreshRequestsChannel", id: this.idValue },
      {
        received(data) {
          if (data.html) {
            element.classList.remove("widget__container--refresh-pending")
            element.classList.add("widget__container--refresh-completed")
            element.setAttribute("data-controller", "replace-outer-content")
            element.setAttribute("data-replace-outer-content-html-value", data.html)

            refreshButtonTarget.setAttribute("data-action", "click->replace-outer-content#replace")
            refreshButtonLabelTarget.textContent = "Load New Results"
            retrievedAtTarget.textContent = "(Retrieved just now)"
          } else {
            refreshButtonTarget.classList.remove("dt-button--refresh-pending")
            refreshButtonTarget.classList.add("dt-button--refresh-completed")
            refreshButtonTarget.setAttribute("data-controller", "devices--property-refresh-table-refresh")
            refreshButtonTarget.setAttribute("data-action", "click->devices--property-refresh-table-refresh#refresh")
            refreshButtonLabelTarget.textContent ="Load New Results"
            retrievedAtTarget.textContent = "(Retrieved just now)"
            document.querySelector('.dataTables_container').classList.add("stale")
          }
        },
      },
    );
  }
}
