import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static interval;
  static values = {
    statusUrl: String,
    htmlUrl: String,
  }
  currentStatus = "waiting";

  connect() {
    this.longPoll();
    this.interval = setInterval(() => {
      this.longPoll();
    }, 1000)
  }

  longPoll() {
    fetch(this.statusUrlValue, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then(response => response.json())
      .then(result => {
        if (result.status != this.currentStatus) {
          this.currentStatus = result.status;
          this.fetchHtml();
        }
    })
  }

  fetchHtml() {
    fetch(this.htmlUrlValue).then((response) => {
      return response.text()
    }).then((html) => {
      this.element.innerHTML = html;
    })
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
