import { Controller } from "@hotwired/stimulus"
import consumer from './../channels';
import throttle from "lodash/throttle";

export default class extends Controller {
  static targets = [ "search", "result", "choice", "button" ]

  static values = {
    searchUrl: String,
    saveUrl: String,
    channelId: Number
  }

  onInput = throttle((event) => {
    this.fetchResults();
  }, 500)

  fetchResults() {
    let encodedQuery = encodeURIComponent(this.searchTarget.value);
    let url = `${this.searchUrlValue}?q=${encodedQuery}`
    fetch(url)
      .then(response => response.text())
      .then(text => {
        Turbo.renderStreamMessage(text)
      })
  }

  chooseResult({params}) {
    fetch(`${params.confirmUrl}?identity_id=${params.id}`)
      .then(response => response.text())
      .then(text => {
        this.searchTarget.value = params.selectionText;
        this.searchTarget.disabled = true;
        this.choiceTarget.value = params.id

        Turbo.renderStreamMessage(text)
        this.buttonTarget.disabled = false
      })
  }

  confirmResult(e) {
    e.preventDefault();

    e.target.disabled = true;
    this.listenForUpdates()
    e.target.form.requestSubmit();
  }

  listenForUpdates() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: "PeopleMergingChannel",
        id: this.channelIdValue,
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  _connected() {
    // noop
  }

  _disconnected() {
    // noop
  }

  _received(data) {
    window.location.reload()
  }
}
