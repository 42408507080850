import { Controller } from "@hotwired/stimulus";
import consumer from './../../channels';

export default class extends Controller {
  static targets = [];

  connect() {
    this.initialIdentityImportSub = consumer.subscriptions.create(
      'InitialIdentityImportChannel',
      {
        received: (data) => {
          window.location.reload(true)
        },
      },
    );
  }

  disconnect(){
    this.initialIdentityImportSub.unsubscribe();
  }
}
