import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["badge"]
  static values = {
    lastSeen: String,
    orgId: String
  }

  connect() {
    this.updateCount()
  }

  async updateCount() {
    const count = await this.getCount()
    count > 0 ? this.showBadge(count) : this.hideBadge()
  }

  async getCount() {
    const path = `/${this.orgIdValue}/api/internal/changelog_count/${this.lastSeenValue}`
    return fetch(path).then(response => response.json())
  }

  showBadge(count) {
    this.badgeTarget.innerHTML = parseInt(count)
    this.badgeTarget.style.display = 'block'
  }

  hideBadge() {
    this.badgeTarget.style.display = 'none'
  }

  internalApiPath() {
    return `/${this.orgIdValue}/api/internal/changelog_count/${this.lastSeenValue}`
  }
}
