import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static values = {
    url: String,
    initialData: Array,
  };

  static targets = [
    "input",
  ];

  connect() {
    this.initializeTagify();
  }

  initializeTagify() {
    if (!this.urlValue) {
      return; // Don't try to load tagify if no remote URL is specified
    }

    try {
      this.tagify = new Tagify(this.inputTarget, {
        whitelist: this.initialDataValue,
        enforceWhitelist: true,
        createInvalidTags: false,
        skipInvalid: true, // do not add invalid entries
        dropdown: {
          searchKeys: ["name"],
          maxItems: 20,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false,
        },
        templates: {
          tag: (tagData) => tagData.tag_html,
          dropdownItem: (tagData) => tagData.dropdown_html,
          dropdownHeader: (_tagData) => ``,
        },
      });
    } catch (error) {
      console.error(`Unable to load groups`);
    }

    this.loadTagifyWhitelist();
  }

  loadTagifyWhitelist() {
    fetch(this.urlValue, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tagify.whitelist = data;
      });
  }
}
