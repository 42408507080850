import { Controller } from "@hotwired/stimulus";


// this assumes that you'll set the 'disabledClass' and 'disabled' attr on the element properly.
// we could think about looking for the 'checkbox' input inside the
// kolide-toggle, and setting the property and class based on whether or not it
// is checked...
export default class extends Controller {
  static targets = ["disableableField"];
  static values = {
    disabledClass: {type: String, default: "disabled"},
  }

  toggle(){
    this.disableableFieldTarget.classList.toggle(this.disabledClassValue)
    this.disableableFieldTarget.toggleAttribute("disabled")
  }
}
