import { Controller } from "@hotwired/stimulus";
import { UAParser } from "ua-parser-js";


export default class extends Controller {
  static targets = ["waiting", "brave", "image"];

  static values = {
    redirectPath: String,
    timeoutOverride: {type: Number, default: 0},
    retryCount: {type: Number, default: 0},
    waiting: {type: Boolean, default: false}
  }

  get isBraveUA() {
    if (!window.Promise) {
      return false;
    }
    if (
      typeof navigator.brave == "undefined" ||
      typeof navigator.brave.isBrave != "function"
    ) {
      return new Promise(function (resolve) {
        resolve(false);
      });
    }
    return navigator.brave
      .isBrave()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return false;
      });
  }

  connect() {
    // Check if the browser is Brave.
    this.isBraveUA.then((result) => {
      if (result) {
        this.braveBrowser = { retries: 0 };
      } else {
        this.braveBrowser = undefined;
      }
    });

    // if we've retried twice, let's just go to the agent detection loop...
    // if we've made it to the "waiting" phase, it will just reload the page
    // and ignore the redirect path
    let baseRedirect = this.retryCountValue < 1 ? window.location.pathname : this.redirectPathValue;
    let url = this.redirectUrlWithParams(baseRedirect);

    // if we've retred, and we are in a brave browser, show the brave browser message
    if (this.braveBrowser && this.retryCountValue > 0) {
      this.waitingTarget.classList.add("hidden");
      this.braveTarget.classList.remove("hidden");
      return console.info("Brave browser detected");
    }

    if (this.timeoutOverrideValue > 0) {
      this.redirect(url, this.timeoutOverrideValue);
    } else {
      this.redirect(url, 3000);
    }
  }

  redirect(url, timeout) {
    if (!this.redirecting) {
      this.redirecting = true;
      setTimeout(() => {
        window.location = url;
      }, timeout);
    }
  }

  redirectUrlWithParams(baseUrl) {
    let retryCount = this.retryCountValue + 1;
    let timestamp = Math.floor(new Date().getTime() / 1000);
    let url = baseUrl + "?timestamp=" + timestamp

    // we don't track retry's on this page...but we can if we decide to!
    // It seems like it'd be smarter to track how _long_ they've been waiting,
    // and then bail to a different page if it's too long.
    if (!this.waitingValue) {
      url += "&retry=" + retryCount;
    }
    return url;
  }

  imageLoaded(event) {
    this.redirect(window.location.pathname, 250);
  }
}
