import tippy from 'tippy.js'
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ 'multiFields', 'multiField', 'option', 'submitButton', 'submitButtonContainer' ];

  connect() {
    this.setSelectionStates();
  }

  setSelectionStates() {
    this.optionTargets.forEach(target => {
      target.classList.remove("selected");
    });
    this.multiFieldTargets.forEach(fieldTarget => {
      this.optionTargets.forEach(optionTarget => {
        if (optionTarget.dataset.checkSetupCollectionId == fieldTarget.value) {
          optionTarget.classList.add("selected");
        }
      });
    });

    if (this.multiFieldTargets.length > 0) {
      this.enableSubmitButton();
    } else {
      this.disableSubmitButton();
    }
  }

  toggleOption(event) {
    if (event.currentTarget.classList.contains("selected")) {
      this.removeValue(event.currentTarget.dataset.checkSetupCollectionId)
    } else {
      this.addValue(event.currentTarget.dataset.checkSetupCollectionId)
    }
    this.setSelectionStates()
  }

  addValue(value) {
    let multiTemplate = `<input data-tutorial--checks-target="multiField" type="hidden" value="${value}" name="organization[signup_setup_check_collections][]" multiple="true">`
    this.multiFieldsTarget.innerHTML += multiTemplate
  }

  removeValue(value) {
    this.multiFieldTargets.forEach(target => {
      if (target.value == value) {
        target.remove()
      }
    });
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false
    this.submitButtonContainerTarget._tippy && this.submitButtonContainerTarget._tippy.destroy();
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = "disabled"
    this.submitButtonContainerTarget._tippy && this.submitButtonContainerTarget._tippy.destroy();
    tippy(this.submitButtonContainerTarget, {
      content: this.submitButtonContainerTarget.getAttribute("data-tippy-text"),
      popperOptions: {
        strategy: "fixed",
      },
    });
  }
}
