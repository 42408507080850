// Turbo Drive does not send a referrer like turbolinks used to
// this simulates that so that we can maintain some backwards compatibility
// with our CSP nonce hack we used for the classic Turbolinks
// see config/initializers/content_security_policy.rb for more info
document.addEventListener('turbo:before-fetch-request', (event) => {
  event.detail.fetchOptions.headers['Turbo-Referrer'] = window.location.href
  event.detail.fetchOptions.headers['X-Turbo-Nonce'] = document.querySelector("meta[name='csp-nonce']").getAttribute('content')
})

document.addEventListener("turbo:before-cache", function() {
  let scriptTagsToAddNonces = document.querySelectorAll("script[nonce]");
  for (var element of scriptTagsToAddNonces) {
    element.setAttribute('nonce', element.nonce);
  }
});
