import { Controller } from "@hotwired/stimulus";
import { debounce } from 'lodash';

export default class InputRequired extends Controller {
  static targets = ['disableableButton', 'requiredInputField'];
  static validRegex = new RegExp(/^[^`!@#\$%\^&*+_=\<\>\\\/]+$/);

  connect() {
    const value = this.requiredInputFieldTarget.value;
    this.validate(value);
  }

  checkInput = debounce((e) => {
    const value = e.target.value;
    this.validate(value);
  }, 100)

  validate(value) {
    if(InputRequired.validRegex.test(value)) {
      this.disableableButtonTarget.disabled = false;
    } else {
      this.disableableButtonTarget.disabled = true;
    }
  }
}
