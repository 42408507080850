import { Controller } from '@hotwired/stimulus'
import pluralize from 'pluralize'
import { isVisible } from '../../../global/utils'

export default class extends Controller {
  static targets = ["countable", "counter"];
  static values = {
    label: String
  }

  updateCount() {
    let size = this.countableTargets.filter(isVisible).length
    this.counterTarget.innerHTML = `${size} ${pluralize(this.labelValue, size)}`
  }
}
