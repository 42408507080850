import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {
  static values = {
    unassignmentUrl: String,
  }

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout(() => {
      // wonky, but tableTarget doesn't work due too the fact that our datatables
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('.dataTables_scrollBody>table.data-table').first();
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    let unassignButton = table.button('unassign:name').nodes()
    if (unassignButton.length == 0) {
      table.button().add(0, this.unassignButton())
    }
  }

  unassignButton() {
    return {
      text: '<svg class="kolidecon kolidecon-badge-bad assignees__unassign-badge" viewBox="0 0 24 24" version="1.1" width="24" height="24" aria-hidden="true"><path d="M23 12l-2.44 2.78.34 3.68-3.61.82-1.89 3.18L12 21l-3.4 1.47-1.89-3.18-3.61-.82.34-3.69L1 12l2.44-2.79-.34-3.68 3.61-.81L8.6 1.54 12 3l3.4-1.46 1.89 3.18 3.61.82-.34 3.68L23 12zm-12.413-.001l-3.294 3.294a1 1 0 0 0 1.414 1.414l3.294-3.293 3.292 3.293a1 1 0 1 0 1.414-1.414l-3.292-3.294 3.292-3.292a1 1 0 1 0-1.414-1.414L12 10.585 8.71 7.293a1 1 0 0 0-1.415 1.414l3.29 3.292z"></path></svg>Unassign selected',
      name: 'unassign',
      className: 'table-button table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: async (e, dt, button, config) => {
        let ids = dt.rows({ selected: true }).indexes()
        let promises = []
        ids.each((idx) => {
          let deviceID = jQuery(dt.column('online:name').nodes()[idx]).find('[data-device-id]')[0].dataset.deviceId
          promises.push(this.unassign(deviceID))
        })

        await Promise.all(promises)
        location.reload()
      }
    }
  }

  unassign(deviceID) {
    let regex = /[0-9]+\/assignments/
    let url = this.unassignmentUrlValue.replace(regex, `${deviceID}/assignments`)
    return fetch(url, {
      method: 'DELETE',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }
}
