import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["spinner", "searchInput", "searchForm", "results", "original", "searchCheckbox"]
  static originalFormValues;

  connect() {
    if (this.hasSearchFormTarget) {
      this.originalFormValues = this.constructComparableFormData(this.searchFormTarget)
    }
  }

  searchList = debounce((event) => {
    let currentFormValues = this.constructComparableFormData(this.searchFormTarget)
    if(currentFormValues != this.originalFormValues){
      this.spinnerTarget.style.display = "flex";
      this.searchFormTarget.requestSubmit();
    } else {
      this.originalTarget.style.display = "";
      this.resultsTarget.style.display = "none";
      this.resultsTarget.innerHTML = "";
      if (this.searchCheckboxTarget) {
        // Even though the state can be easily restored with the original entries
        // we still need to submit the form to remember the checkbox states
        this.searchFormTarget.requestSubmit();
      }
      let event = new Event("checks:search-cleared")
      window.dispatchEvent(event)
    }
  }, 250)

  searchFinish() {
    this.spinnerTarget.style.display = "none";
    this.originalTarget.style.display = "none";
    this.resultsTarget.style.display = "";
  }

  constructComparableFormData(form) {
    let formData = new FormData(form)
    formData.delete("authenticity_token")

    return JSON.stringify([...formData.entries()])
  }
}
