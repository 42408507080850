import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "combinatorSelect"]

  removeField(event) {
    event.target.closest('.search__condition').remove()
    this.formTarget.submit()
    event.preventDefault()
  }

  openCombinatorSelect(event) {
    event.preventDefault()
    let value = this.combinatorSelectTarget.value
    if (value == "and") {
      this.combinatorSelectTarget.value = 'or'
    } else {
      this.combinatorSelectTarget.value = 'and'
    }
    this.submit()
  }

  addField(event) {
    let time = new Date().getTime()
    let regexp = new RegExp(event.target.dataset.id, 'g')
    event.target.parent.insertAdjacentElement('beforebegin', event.target.dataset.fields.replace(regexp, time))
    event.preventDefault()
  }

  submit(event) {
    this.formTarget.submit()
  }
}
