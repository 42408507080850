import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "content", "tabsList"];
  static values = {
    param: String,
    defaultSelectionLast: { type: Boolean, default: false },
    preserveHeight: { type: Boolean, default: true },
    disableUrls: { type: Boolean, default: false },
  };
  static tabs = [];
  static observer;

  connect() {
    this.tabs = this.linkTargets.map(tab => tab.dataset.tabId)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tabId = urlParams.get(this.paramValue);
    let activeTab;

    this.linkTargets.forEach((el) => {
      if (el.classList.contains("active")) {
        activeTab = el;
      }
    });

    if (activeTab) {
      this.activateTab(activeTab.dataset.tabId);
    } else if (this.disableUrlsValue) {
      this.chooseActiveTabBasedOnOrder();
    } else if (this.tabs.includes(tabId)) {
      this.activateTab(tabId)
    } else {
      this.chooseActiveTabBasedOnOrder();
    }

    if (this.hasTabsListTarget) {
      this.installMutationObserver();
    }
  }

  chooseActiveTabBasedOnOrder() {
    if (this.defaultSelectionLastValue) {
      this.activateTab(this.tabs[this.tabs.length - 1])
    } else {
      this.activateTab(this.tabs[0])
    }
  }

  installMutationObserver() {
    this.observer = new MutationObserver((mutationList, _observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList' && mutation.removedNodes[0]) {
          this.tabs = this.linkTargets.map(tab => tab.dataset.tabId)
          this.activateTab(this.tabs[this.tabs.length - 1])
        }
      }
    });

    this.observer.observe(this.tabsListTarget, { childList: true, attributes: false, subtree: false });
  }

  activateTab(tabId) {
    const height = this.element.offsetHeight || 0
    const minHeight = parseInt(this.element.style.minHeight) || 0
    let activeTab;

    if (this.preserveHeightValue && minHeight < height) {
      this.element.style.minHeight = `${height}px`
    }

    this.linkTargets.forEach((el) => el.classList.remove('active'));
    this.linkTargets.forEach((el) => {
      if (el.dataset.tabId == tabId) {
        el.classList.add('active')
      }
    })

    this.contentTargets.forEach((el) => el.classList.remove('active'));
    this.contentTargets.forEach((el) => {
      if (el.dataset.tabId == tabId) {
        el.classList.add('active')
      }
    })

    this.linkTargets.forEach((el) => {
      if (el.classList.contains("active")) {
        activeTab = el;
      }
    });

    const event = new CustomEvent('tabActivated', { detail: activeTab });
    this.element.dispatchEvent(event);
  }

  change(event) {
    event.preventDefault();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tabId = event.currentTarget.dataset.tabId;

    if (this.tabs.includes(tabId)) {
      this.activateTab(tabId);
      if (!this.disableUrlsValue) {
        urlParams.set(this.paramValue, tabId);
        history.replaceState("", "", "?" + urlParams.toString());
      }
    }
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
