import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paymentForm", "cardErrors"];

  connect() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    if(window.isTest == true) {
      script.src = "https://js.stripe.com/v3?advancedFraudSignals=false";
    } else {
      script.src = "https://js.stripe.com/v3";
    }
    script.onload = this.loadElements.bind(this);
    document.head.appendChild(script);

    // strip the leading and trailing whitespace
    this.cardErrorsTarget.innerText = this.cardErrorsTarget.innerText.trim();
  }

  loadElements() {
    const stripe = Stripe(this.paymentFormTarget.dataset.stripeKey);
    const elements = stripe.elements();

    // Create an instance of the card Element.
    const card = elements.create("card");

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount("#card-element");

    card.addEventListener("change", ({ error }) => {
      if (error) {
        this.cardErrorsTarget.textContent = error.message;
      } else {
        this.cardErrorsTarget.textContent = "";
        this.paymentFormTarget.querySelector('[type="submit"]').disabled = false;
      }
    });

    this.paymentFormTarget.addEventListener("submit", event => {
      event.preventDefault();

      stripe.createToken(card).then(({ token, error }) => {
        if (error) {
          // Inform the customer that there was an error.
          this.cardErrorsTarget.textContent = error.message;
        } else {
          // Send the token to your server.
          this.stripeTokenHandler(token);
        }
      });
    });
  }

  stripeTokenHandler(token) {
    // Insert the token ID into the form so it gets submitted to the server
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "stripe_token");
    hiddenInput.setAttribute("value", token.id);
    this.paymentFormTarget.appendChild(hiddenInput);

    // Submit the form
    this.paymentFormTarget.submit();
  }
}
