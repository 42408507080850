import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "form", "dropzone"];

  connect() {
    const onDrag = () => this.dropzoneTarget.classList.add('dragover');
    const endDrag = ()  => this.dropzoneTarget.classList.remove('dragover');
    const onDrop = dragEvent  => {
      this.inputTarget.files = dragEvent.dataTransfer.files;
      this.inputTarget.dispatchEvent(new Event('change'))
    }
    const stopPropagation = (e)  => {
      e.preventDefault();
      e.stopPropagation();
    }

    this.dropzoneTarget.addEventListener('drag', stopPropagation )
    this.dropzoneTarget.addEventListener('dragstart', stopPropagation)
    this.dropzoneTarget.addEventListener('dragend', stopPropagation)
    this.dropzoneTarget.addEventListener('dragover', stopPropagation)
    this.dropzoneTarget.addEventListener('dragenter', stopPropagation)
    this.dropzoneTarget.addEventListener('dragleave', stopPropagation)
    this.dropzoneTarget.addEventListener('drop', stopPropagation)

    this.dropzoneTarget.addEventListener('dragover', onDrag)
    this.dropzoneTarget.addEventListener('dragenter', onDrag)

    this.dropzoneTarget.addEventListener('dragleave', endDrag)
    this.dropzoneTarget.addEventListener('dragend', endDrag)
    this.dropzoneTarget.addEventListener('drop', endDrag)

    this.dropzoneTarget.addEventListener('drop', onDrop)
  }
}
