import { Controller } from "@hotwired/stimulus";
import jQuery from 'jquery'

export default class extends Controller {
  static targets = ["row"]
  static values = {
    url: String,
  }

  connect() {
    this.refreshInterval = setInterval(() => {
      fetch(this.urlValue)
        .then((response) => {
          return response.text()
        })
        .then((html) => {
          let tr = `tr[data-live-query-id='${this.rowTarget.getAttribute('data-live-query-id')}']`
          let tableClass = ".live-queries__data-table"
          jQuery(tableClass).DataTable().rows(tr).remove()
          this.rowTarget.outerHTML = html
          jQuery(tableClass).DataTable().row.add(jQuery(html))
        })
    }, 10000); // 10 seconds
  }

  disconnect() {
    clearInterval(this.refreshInterval)
  }
}
