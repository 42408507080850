import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "allPackages", "matchedPackage"];
  static values = {
    waitingUrl: String,
  };

  showAllPackages(event) {
    event.preventDefault();

    this.linkTarget.classList.add("hidden");
    this.matchedPackageTarget.classList.add("hidden");
    this.allPackagesTarget.classList.remove("hidden");
  }

  navigateToWaiting() {
    setTimeout(() => {
      let url = `${this.waitingUrlValue}?wait=true`;
      // Do not use Turbo.visit here as we need new CSP headers to do
      // agent detection on address 127.0.0.1.
      window.location.href = url;
    }, 3000);
  }
}
