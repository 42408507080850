import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "trigger"];

  toggle() {
    if (this.triggerTarget.checked) {
      this.inputTarget.disabled = true
    } else {
      this.inputTarget.disabled = false
    }
  }
}
