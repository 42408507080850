import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { empty } from '../../global/utils'

export default class extends Controller {
  static targets = [
    "passwordInput",
    "meter",
    "info",
    "strengthText",
    "warning",
    "warningMessage",
    "suggestions",
    "suggestionsList"
  ]
  static values = {
    dictionaries: Object
  }

  infoTemplate = `
    <div class="k-password-meter__info animate__animated animate__fadeIn animate__faster" data-password-meter-target="info">
      <div class="k-password-meter__strength">
        PASSWORD STRENGTH: <span class="k-password-meter__strength-text" data-password-meter-target="strengthText"></span>
      </div>
      <span class="k-password-meter__warning" data-password-meter-target="warning">
        <div>
          <span class="k-password-meter__warning-title">
            Warning:
          </span>
          <div class="k-password-meter__warning-description" data-password-meter-target="warningMessage"></div>
        </div>
      </span>
      <span class="k-password-meter__suggestion" data-password-meter-target="suggestions">
        <div>
          <span class="k-password-meter__warning-title">
            Suggestion:
          </span>
          <div class="k-password-meter__suggestion-description" data-password-meter-target="suggestionsList"></div>
        </div>
      </span>
    </div>
  `;

  connect() {
    this.appendMeter();
    this.appendPasswordInfo();

    document.addEventListener(
      "scroll",
      this.updatePasswordInfoPosition.bind(this),
      {
        passive: true
      }
    );

    window.addEventListener(
      "resize",
      this.updatePasswordInfoPosition.bind(this),
      {
        passive: true
      }
    );
  }

  appendMeter() {
    // creating a meter tag. Not using the built-in meter tag because of IE 11 support.
    const element = document.createElement('div')
    element.classList.add("k-password-meter")
    element.setAttribute("data-password-meter-target", "meter")
    element.insertAdjacentHTML('beforeend', "<div></div><div></div><div></div><div></div>")

    this.passwordInputTarget.insertAdjacentElement('afterend', element)
  }

  appendPasswordInfo() {
    const inputBoundingBox = this.passwordInputTarget.getBoundingClientRect();

    const infoElement = document.createElement('template')
    infoElement.insertAdjacentHTML('beforeend', this.infoTemplate)

    const info = infoElement.children[0]
    info.style.top = inputBoundingBox.top
    info.style.left = inputBoundingBox.left + inputBoundingBox.width
    this.passwordInputTarget.insertAdjacentElement('afterend', info)
  }

  updateSuggestions(suggestions = []) {
    if (suggestions.length > 0) {
      const suggestionsListEl = document.createElement("ul");

      suggestions.forEach(suggestion => {
        const li = document.createElement('li')
        li.insertAdjacentText('beforeend', suggestion)
        suggestionsListEl.insertAdjacentElement('beforeend', li)
      });

      empty(this.suggestionsListTarget)
      this.suggestionsListTarget.appendChild(suggestionsListEl)
      this.suggestionsTarget.classList.remove("hidden");
    } else {
      this.suggestionsTarget.classList.add("hidden");
    }
  }

  updateWarning(warning = false) {
    if (warning && warning !== "") {
      this.warningMessageTarget.innerHTML = warning
      this.warningTarget.classList.remove("hidden");
    } else {
      this.warningTarget.classList.add("hidden");
    }
  }

  updateStrengthText(score) {
    empty(this.strengthTextTarget)
    this.strengthTextTarget.setAttribute('data-password-score', score)
    this.strengthTextTarget.innerHTML = this.scoreToText(score)
  }

  scoreToText(score) {
    switch (score) {
      case 0:
      case 1:
        return "Very Weak";
      case 2:
        return "Weak";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "Unknown";
    }
  }

  updatePasswordInfoPosition = event => {
    const inputBoundingBox = this.passwordInputTarget.getBoundingClientRect();
    this.infoTarget.style.top = inputBoundingBox.top
    this.infoTarget.style.left = inputBoundingBox.left + inputBoundingBox.width
  };

  inputBlur() {
    if (typeof zxcvbn !== 'function') return

    let dictionaries = this.dictionaryValue || []
    const passwordStrengthResult = zxcvbn(this.passwordInputTarget.value, dictionaries);

    if (
      passwordStrengthResult.score < 3 &&
      this.passwordInputTarget.value.length > 0
    ) {
      this.updateSuggestions(passwordStrengthResult.feedback.suggestions)
      this.updateWarning(passwordStrengthResult.feedback.warning)
      this.updateStrengthText(passwordStrengthResult.score);

      this.infoTarget.classList.add("show")
    } else {
      this.infoTarget.classList.remove("show");
    }
  }

  inputChange = debounce(() => {
    if (typeof zxcvbn !== 'function') return

    if (this.passwordInputTarget.value.length > 0) {
      let dictionaries = this.dictionaryValue || []
      const passwordStrengthResult = zxcvbn(this.passwordInputTarget.value, dictionaries);
      this.meterTarget.setAttribute(
        "data-password-score",
        passwordStrengthResult.score
      );
    } else {
      this.meterTarget.removeAttribute("data-password-score");
      this.inputBlur();
    }
  }, 100);
}
