import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if(this.isLoaded) {
      this.loaded();
    }
  }

  loaded() {
    // this means it's a successful load...
    if (this.element.naturalWidth > 0) {
      const event = new Event("agent-detection-image-loaded")

      window.dispatchEvent(event);
    }
  }

  get isLoaded() {
    return this.element.complete;
  }
}
