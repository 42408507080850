import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "removeInactiveDevicesCheckbox",
    "removeInactiveDeviceThresholdDaysInput",
    "removeInactiveEphemeralDevicesCheckbox",
    "removeInactiveEphemeralDeviceThresholdDaysInput",
    "duplicateWarning",
    "dedupeRadio",
    "submit"
  ]
  static values = {
    oldDuplicateDevicesCount: Number
  }

  connect() {
    this.setInactiveStateInput();
    if (this.hasRemoveInactiveEphemeralDevicesCheckboxTarget) {
      this.setEphemeralInactiveStateInput();
    }
    this.reattachErrorsToCheckbox();
    this.checkForWarning();
  }

  checkForWarning() {
    let value = this.dedupeRadioTargets.find(r => r.checked).value
    let devicesToBeRemovedCount = this.oldDuplicateDevicesCountValue
    if (value == "remove_old" && devicesToBeRemovedCount > 0) {
      this.duplicateWarningTarget.style.display = ''
      this.submitTarget.dataset.confirm = `Your new deduplication settings will result in ${devicesToBeRemovedCount} ${devicesToBeRemovedCount == 1 ? "device" : "devices"} being removed from Kolide. Proceed?`
    } else {
      this.duplicateWarningTarget.style.display = 'none'
      delete this.submitTarget.dataset.confirm
    }
  }

  reattachErrorsToCheckbox() {
    // Add a class that will override the default behavior for checkboxes which prevents error messages from being shown
    let inactiveErrors = this.removeInactiveDeviceThresholdDaysInputTarget.parentNode.querySelectorAll('.error')
    this.removeInactiveDevicesCheckboxTarget.parentNode.parentNode.classList.add("show-errors")

    if (inactiveErrors.length > 0) {
      this.removeInactiveDevicesCheckboxTarget.parentNode.parentNode.classList.add('field_with_errors')
      inactiveErrors.forEach(error => {
        this.removeInactiveDevicesCheckboxTarget.parentNode.parentNode.appendChild(error)
      })
    }

    if (this.hasRemoveInactiveEphemeralDevicesCheckboxTarget) {
      let inactiveEphemeralErrors = this.removeInactiveEphemeralDeviceThresholdDaysInputTarget.parentNode.querySelectorAll('.error')
      this.removeInactiveEphemeralDevicesCheckboxTarget.parentNode.parentNode.classList.add("show-errors")
      if (inactiveEphemeralErrors.length > 0) {
        this.removeInactiveEphemeralDevicesCheckboxTarget.parentNode.parentNode.classList.add('field_with_errors')
        inactiveEphemeralErrors.forEach(error => {
          this.removeInactiveEphemeralDevicesCheckboxTarget.parentNode.parentNode.appendChild(error)
        })
      }
    }
  }

  setInactiveStateInput() {
    if (this.removeInactiveDevicesCheckboxTarget.checked) {
      this.removeInactiveDeviceThresholdDaysInputTarget.disabled = false
    } else {
      this.removeInactiveDeviceThresholdDaysInputTarget.disabled = true
    }
  }

  setEphemeralInactiveStateInput() {
    if (this.removeInactiveEphemeralDevicesCheckboxTarget.checked) {
      this.removeInactiveEphemeralDeviceThresholdDaysInputTarget.disabled = false
    } else {
      this.removeInactiveEphemeralDeviceThresholdDaysInputTarget.disabled = true
    }
  }
}
