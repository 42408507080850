
import { Controller } from "@hotwired/stimulus";
import jQuery from 'jquery'

export default class extends Controller {
  static targets = ["multiSelect"]

  connect() {
    this.multiSelectTargets.forEach((s) => { jQuery(s).select2() })
  }
}
