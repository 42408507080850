import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radioButton", "showable"];

  connect() {
    let selectedButton = this.radioButtonTargets.find(btn => btn.checked)

    if (selectedButton) {
      this.toggle(selectedButton)
    }
  }

  change(event) {
    this.toggle(event.target)
  }

  toggle(btn) {
    this.showableTargets.forEach(target => {
      if (btn.value === target.dataset.buttonValue) {
        target.style.display = "block"
        target.style.visibility = "visible"
      } else {
        target.style.display = "none"
        target.style.visibility = "hidden"
      }
    })
  }
}
