import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["searchable", "minScore", "maxScore", "scoreButton"]

  updateScoreLink() {
    let url = new URL(this.scoreButtonTarget.href)
    url.searchParams.set('score_bottom', this.minScoreTarget.value)
    url.searchParams.set('score_top', this.maxScoreTarget.value)
    this.scoreButtonTarget.href = url
  }

  search = debounce((event) => {
    const searchValue = event.target.value
    const inputMatcher = new RegExp(`${searchValue}.*`, 'i');

    this.searchableTargets.forEach(el => el.classList.remove("hidden-by-search"))
    this.searchableTargets.forEach(el => el.classList.remove("found"))

    if (searchValue != "") {
      this.searchableTargets.forEach(searchable => {
        if (searchable.dataset.searchText.match(inputMatcher)) {
          searchable.classList.add("found")
        }
      })

      this.searchableTargets.forEach(searchable => {
        if (!searchable.classList.contains("found")) {
          searchable.classList.add("hidden-by-search")
        }
      })
    }
  }, 250)
}
