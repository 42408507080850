import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
  }

  recheck(event) {
    event.preventDefault();
    if (window.opener) {
      window.opener.document.querySelector("#recheck-button").click();
      window.close();
    } else {
      document.addEventListener('turbo:load', () => {
        document.querySelector("#recheck-button").click();
      }, { once: true });
      Turbo.visit(this.urlValue);
    }
  }
}
