import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "radioButton", "teamsContainer"]

  connect() {
    let selectedButton = this.radioButtonTargets.find(btn => btn.checked)

    if (selectedButton) {
      this.addInput(selectedButton)
    }
  }

  handleChange(event) {
    this.addInput(event.target)
  }

  addInput(btn) {
    let parentEl

    switch (btn.value) {
      case "off":
        this.inputTarget.style.display = "none"
        this.inputTarget.style.visibility = "hidden"
        return
      case "everyone":
        parentEl = btn.parentElement
        break
      case "by_team":
        parentEl = this.teamsContainerTarget
        break
    }

    parentEl.appendChild(this.inputTarget)
    this.inputTarget.style.display = "flex"
    this.inputTarget.style.visibility = "visible"
  }
}
