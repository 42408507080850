import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "identifierSelect", "input"];

  change(event) {
    const placeholders = {
      "issuer_name": "%Apple%",
      "homebrew_package": "sqlite",
      "common_name": "www.apple.com",
      "expiry_threshold": 30,
      "extension_name": "1Password",
      "extension_id": "fhhbdiojplfjncoa",
      "process_name": "1Password",
      "process_path": "Applications/1Password.app/1Password"
    };

    let identifier = event.target.value
    let label =  placeholders[identifier];

    this.inputTarget.placeholder = label
  }
}
