import { Controller } from "@hotwired/stimulus"
import Mustache from 'mustache';

export default class extends Controller {
  static targets = [ 'multiFields', 'multiField', 'button' ]
  static values = { template: String }
  connect() {
    this.setSelectionStates()
  }

  setSelectionStates() {
    this.buttonTargets.forEach((buttonTarget) =>  buttonTarget.classList.remove("selected"))
    this.multiFieldTargets.forEach((el) => {
      this.buttonTargets.forEach((el2) => {
        if (el2.dataset.weekday == el.value) {
          el2.classList.add("selected")
        }
      })
    })
  }

  toggleDay(event) {
    if (event.currentTarget.classList.contains("selected")) {
      this.removeValue(event.currentTarget.dataset.weekday)
    } else {
      this.addValue(event.currentTarget.dataset.weekday)
    }
    this.setSelectionStates()
  }

  addValue(value) {
    let template = Mustache.render(this.templateValue, {"value": value})
    this.multiFieldsTarget.insertAdjacentHTML('beforeend', template)
    // Needed if all options were deselected
    this.removeValue("")
  }

  removeValue(value) {
    let emptyTemplate = Mustache.render(this.templateValue, {"value": ""})
    this.multiFieldTargets.forEach((el) => {
      if (el.value == value) {
        el.remove()
        if (this.multiFieldTargets.length == 0) {
          this.multiFieldsTarget.innerHTML = emptyTemplate
        }
      }
    })
  }
}
