import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
  }
  connect() {
    if (this.urlValue) {
      this.element.addEventListener("error", (event) => {
        event.target.src = this.urlValue
        event.target.onerror = null
      })
    }
  }
}
