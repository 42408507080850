import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["keyField", "valueField"]
  static values = {
    json: Object
  }

  connect() {
    this.issueValue = this.jsonValue
    this.populate()
  }

  populate() {
    this.valueFieldTarget.value = this.issueValue[this.keyFieldTarget.value]
  }
}
