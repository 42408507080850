import { Controller } from "@hotwired/stimulus"
import consumer from './../../channels';

export default class extends Controller {
  static values = {
    infoUrl: String,
    deviceId: String,
  }
  connect() {
    consumer.subscriptions.create(
      { channel: "OsqueryDeviceLostModeCheckInsChannel", device_id: this.deviceIdValue },
      {
        received: (data) => {
          if (data.fullRefresh) {
            window.location.reload()
          } else {
            let info = document.querySelector(`[data-devices--lost-mode-check-ins-device-id-value="${data.deviceID}"]`);
            if (info && this.hasInfoUrlValue) {
              fetch(this.infoUrlValue).then((response) => {
                return response.text();
              }).then((html) => {
                info.outerHTML = html
              })
            }
          }
        },
      },
    );
  }
}
