import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "container", "closeButton"]
  static values = {
    exitUrl: String,
  }

  closeSidebar(event) {
    if (this.closeButtonTarget.contains(event.target) ||
      (this.closeButtonTargets[1] && this.closeButtonTargets[1].contains(event.target)) ||
      (this.containerTarget.contains(event.target) &&
        !this.sidebarTarget.contains(event.target))) {
      event.preventDefault()
      if (kSidebar.config.ujs) {
        kSidebar.hide()
      } else {
        if (window.dispatchEvent(new CustomEvent('beforeSidebarHide', { cancelable: true }))) {
          Turbo.visit(this.exitUrlValue)
        }
      }
    }
  }

  keyUpHandler(event) {
    switch (event.key) {
      case "Esc":
      case "Escape":
        if (kSidebar.config.disableEscKeyClose) {
          return;
        } else if (kSidebar.config.ujs) {
          kSidebar.hide();
        } else {
          if (window.dispatchEvent(new CustomEvent('beforeSidebarHide', { cancelable: true }))) {
            Turbo.visit(this.exitUrlValue)
          }
        }
      default:
        return;
    }
  }
}
