import tippy from "tippy.js";
import { Controller } from "@hotwired/stimulus";
import { getParents } from "../../../global/utils";

export default class extends Controller {
  static targets = ["authRadioButton", "samlForm", "submit"];
  connect() {
    let samlOption = this.authRadioButtonTargets.find(
      (e) => e.value === "saml"
    );
    let samlValidated = this.element.dataset.samlValidated == "true";
    let samlEnforced = this.element.dataset.samlEnforced == "true";

    if (samlValidated && samlEnforced) {
      this.authRadioButtonTargets
        .filter((e) => e.value !== "saml")
        .forEach((e) => {
          e.disabled = true;
          let label = getParents(e, "label")[0];
          label.classList.add("disabled");

          let hoverText =
            "Please contact support to change from SAML authentication";
          this.setTooltip(label, hoverText);
        });
    } else if (!samlValidated) {
      samlOption.disabled = true;
      let label = getParents(samlOption, "label")[0];
      label.classList.add("disabled");
    }
  }

  setTooltip(element, hoverText) {
    element.dataset.tippy = hoverText;
    element._tippy && element._tippy.destroy();
    tippy(element, {
      allowHTML: element.getAttribute("data-tippy-allow-html") ?? false,
      content: element.getAttribute("data-tippy"),
      popperOptions: {
        strategy: "fixed",
      },
    });
  }
}
