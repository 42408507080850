import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import { throttle } from "lodash";

export default class extends Controller {
  static targets = [
    'preview',
    'exampleData',
    'exampleDataButton',
  ]

  static values = {
    url: String
  }

  isValidJson(str) {
    try {
      let json = JSON.parse(str);
      return json && typeof json === "object"
    } catch {
      return false
    }
  }

  connect() {
    // set the first set of example data as the default
    if (this.hasExampleDataTargets) {
      this.setSelectedExampleData(this.exampleDataTargets[0].dataset.tabId)
    }
    Array.from(this.element.querySelectorAll("textarea")).forEach((target) => {
      target.addEventListener("keyup", this.updatePreview)
    })
  }

  updatePreview = throttle((e) =>{
    let formData = new FormData(this.element);

    if (this.isValidJson(this.selectedExampleDataTextArea?.value)) {
      formData.append("example_data", this.selectedExampleDataTextArea.value)
    }

    let csrftoken = document.getElementsByName("csrf-token")[0].content
    let response = fetch(this.urlValue,
      {
        method: "PATCH",
        body: formData,
        headers: {
          "Accept": "text/vnd.turbo-stream.html",
          "X-CSRF-Token": csrftoken,
        }
      }
    )

    response
      .then(resp => resp.text())
      .then(text => Turbo.renderStreamMessage(text))
  }, 500);

  selectExampleData(event) {
    const name = event.currentTarget.dataset.tabId;
    this.setSelectedExampleData(name);
    this.updatePreview();
  }

  setSelectedExampleData(name) {
    this.selectedExampleDataTextArea = this.exampleDataTextAreas.find((target) => target.dataset.exampleDataId == name);
    this.exampleDataTextAreas.forEach((target) => {
      target.disabled = true
    });
    this.selectedExampleDataTextArea.disabled = false;
  }

  get exampleDataTextAreas() {
    return this.exampleDataTargets.map((target) => target.querySelector("textArea[data-example-data-id]"))
  }
}
