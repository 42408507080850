import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggle(event) {
    const nextSibling = event.target.nextElementSibling;

    if (nextSibling) {
      nextSibling.classList.toggle('live_query__docs-accordion-panel--active');
      event.target.classList.toggle('live_query__docs-accordion--active');
    }
  }
}
