import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import { current } from "./../../global/helpers"
import jQuery from "jquery"

export default class extends Controller {
  static values = {
    endpoint: String
  };

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout(() => {
      // wonky, but tableTarget doesn't work due to the fact that our datatables
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery("table[id^=DataTables_Table]").first()
      this.addActionButton(table.DataTable())
    }, 250);
  }

  addActionButton(table) {
    // need to check for the button existence because the 'connect' for this
    // controller is being called multiple times on turbo:load, despite
    // being called only once on a full page load/refresh.
    let recheckButton = table.button("recheck:name").nodes()

    if(recheckButton.length == 0 && !current.flags.deviceTrustEnabled) {
      table.button().add(0, this.recheckButton())
    }
  }

  recheckButton(){
    let checkIcon = `
      <svg height="18" class="kolidecon kolidecon-check-circle" viewBox="0 0 24 24" version="1.1" width="18" aria-hidden="true">
        <path fill-rule="evenodd" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm5.722 5.124a.8.8 0 0 0-.997.113l-6.288 6.427-1.877-1.753a.944.944 0 0 0-1.23-.043.869.869 0 0 0-.175 1.176l2.43 3.513c.065.094.147.177.24.245.458.33 1.104.238 1.444-.205l6.61-8.617a.586.586 0 0 0-.157-.856z"></path>
      </svg>
    `
    return {
      text: `${checkIcon} Run Check Now`,
      name: "recheck",
      className: "table-button table-button--disabled",
      tag: "span",
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        let selectedRows = dt.rows({ selected: true }).indexes()
        let checkIds = []
        selectedRows.each(idx => {
          checkIds.push(dt.column("check_name:name").nodes()[idx].querySelector("a").dataset.checkId);
        })
        this.recheck(checkIds);
      }
    }
  }

  recheck(checkIds) {
    fetch(this.endpointValue, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify({ check_ids: checkIds }),
    }).then(() => {
      location.reload();
    })
  }
}
