import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  checkActive() {
    setTimeout(() => this.setActive(this.element), 10)
  }

  setActive(element) {
    const selectedPages = JSON.parse(document.body.getAttribute("data-page-selected-links"))
    const links = this.element.getAttribute("data-selected-links").split(" ")
    let found = links.some(r=> selectedPages.includes(r))
    found ? element.classList.add("active") : element.classList.remove("active")
  }
}
