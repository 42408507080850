import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';
import jQuery from 'jquery'

export default class extends Controller {

  connect() {
    // wait to ensure the datatable gets initiated first
    setTimeout( ()=>{
      // wonky, but tableTarget doesn't work due too the fact that our datatbales
      // scrolling plugin actually duplicates the initial table element, and the
      // real datatable we want to interact with is this second duplicated table.
      let table = jQuery('table[id^=DataTables_Table]').first()
      this.addActionButtons(table.DataTable())
    }, 250);
  }

  addActionButtons(table) {
    let deleteButton = table.button('delete:name').nodes()
    if(deleteButton.length == 0) {
      table.button().add(null, this.deleteButton())
    }
  }

  deleteButton() {
    return {
      text: "Delete Selected",
      name: 'delete',
      className: 'table-button table-button--alert table-button--disabled',
      tag: 'span',
      attr: {
        "data-bulk-editor": true
      },
      action: (e, dt, button, config) => {
        if (window.confirm("Are you sure you want to delete the selected queries?")) {
          let nodes = dt.rows({ selected: true }).nodes()
          nodes.each((node)=> {
            let removeUrl = node.dataset.url
            this.remove(removeUrl)
          })
        }
      }
    }
  }

  remove(removeUrl) {
    let endpoint = removeUrl

    fetch(endpoint, {
      method: 'DELETE',
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then(() => {
      location.reload();
    })
  }
}
