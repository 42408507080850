import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal", "container"]
  static values = {
    exitUrl: String,
  }

  closeModal(event) {
    if (this.containerTarget.contains(event.target) && !this.modalTarget.contains(event.target)) {
      event.preventDefault()
      if (kModal.config.ujs) {
        kModal.hide()
      } else {
        Turbo.visit(this.exitUrlValue)
      }
    }
  }

  keyUpHandler(event) {
    switch (event.key) {
      case "Esc":
      case "Escape":
        if (kModal.config.ujs) {
          kModal.hide()
        } else {
          Turbo.visit(this.exitUrlValue)
        }
      default:
        return;
    }
  }
}
