import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [
    "strategyRadio",
    "prependForm",
    "prependTextArea",
    "appendForm",
    "appendTextArea",
    "supplementedForm",
    "fullyCustomizedForm",
    "customizedTextArea",
    "testLink",
    "testLinkInput",
  ];

  static values = {
    textTemplate: String,
  };

  connect() {
    if (this.hasStrategyRadioTarget) {
      this.showRelevantStrategyForm()
    }

    this.updateTestLink()
  }

  showRelevantStrategyForm() {
    let value = this.strategyRadioTargets.find(r => r.checked).value

    if (value == "supplemented") {
      this.supplementedFormTarget.style.display = "block"
      this.fullyCustomizedFormTarget.style.display = "none"
      this.prependFormTarget.style.display = "block"
      this.appendFormTarget.style.display = "block"
    } else if (value == "fully_customized") {
      this.fullyCustomizedFormTarget.style.display = "block"
      if(this.hasSupplementedFormTarget) {
        this.supplementedFormTarget.style.display = "none"
        this.prependFormTarget.style.display = "none"
        this.appendFormTarget.style.display = "none"
      }
      this.customizedTextAreaTarget.focus()
    } else {
      this.fullyCustomizedFormTarget.style.display = "none"
      if(this.hasSupplementedFormTarget) {
        this.supplementedFormTarget.style.display = "block"
        this.prependFormTarget.style.display = "none"
        this.appendFormTarget.style.display = "none"
      }
    }
  }

  updateTestLink() {
    if (this.hasTestLinkTarget) {
      let radioValue = this.hasStrategyRadioTarget ? this.strategyRadioTargets.find(r => r.checked).value : null;
      let template;

      if (radioValue == "supplemented") {
        let prependText = this.prependTextAreaTarget.value.length > 0 ? `${this.prependTextAreaTarget.value}\n\n` : ""
        let appendText = this.appendTextAreaTarget.value.length > 0 ? `\n\n${this.appendTextAreaTarget.value}` : ""
        template = prependText + this.textTemplateValue + appendText
      } else {
        template = this.customizedTextAreaTarget.value
      }

      this.testLinkInputTarget.setAttribute("value", template);
    }
  }
}
