import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["container", "form", "field", "details", "name",
    "predicate", "value", "formAttribute", "formPredicate", "formValue",
    "formPredicateText", "formPredicateNumber", "formPredicateBoolean",
    "formPredicateDateTime", "formPredicateEnum", "formPredicateArray",
    "formValueText", "formPredicateTextHasMany", "formValueNumber",
    "formValueBoolean", "formValueEnum", "formValueDateTime",
    "formValueArray"]
  static values = {
    initialized: Boolean,
    translations: Object,
    skipCollapse: Boolean,
  }

  connect() {
    if (!this.initializedValue) {
      this.processAttributeTypes()
      this.filterPredicates()
      this.filterValueInputs()
      this.collapseOptGroups()
      this.sortOptgroups()
      flatpickr(this.formValueDateTimeTarget, { dateFormat: 'Y-m-d', allowInput: true })
      if (this.isPopulated()) {
        this.displayFilterRuleSummary()
      } else {
        $(this.formAttributeTarget).find('option:contains("Choose an attribute")').prop('selected', true)
        this.filterPredicates()
        $(this.containerTarget).addClass('no-data')
      }
      $(this.containerTarget).addClass('visible')
      this.initializedValue = true
    }
  }

  toggle(event) {
    if (
      this.element.contains(event.target) &&
      !this.formTarget.contains(event.target)
    ) {
      $(this.formTarget).toggle()
    }
    event.preventDefault()
  }

  hide(event) {
    if (!this.element.contains(event.target) && !$('.flatpickr-calendar')[0].contains(event.target)) {
      $(this.formTarget).hide()
    }
  }

  displayFilterRuleSummary() {
    let label
    if (this.translationsValue[this.attributeValue]) {
      label = this.translationsValue[this.attributeValue].split("::")[0]
    } else {
      label = this.attributeValue
    }

    if (this.isValuelessPredicate(this.predicateValue)) {
      $(this.nameTarget).text(label)
      $(this.predicateTarget).text(this.predicateValue).css({ fontWeight: 900 })
      $(this.valueTarget).hide()
      $(this.detailsTarget).show()
    } else {
      $(this.nameTarget).text(label)
      $(this.predicateTarget).text(this.predicateValue)
      $(this.valueTarget).text(this.searchValue.split('::').pop())
      $(this.detailsTarget).show()
    }
  }

  isPopulated() {
    this.attributeValue = $(this.formAttributeTarget).val()
    this.predicateValue = $(this.formPredicateTarget).parent().find('select:enabled').find('option:selected').text()
    this.searchValue = $(this.formValueTarget).val()
    return (this.attributeValue != "" && this.predicateValue != "" && this.searchValue != "")
  }

  filterPredicates() {
    const typeMap = {
      'enum': this.formPredicateEnumTarget,
      'array': this.formPredicateArrayTarget,
      'string': this.formPredicateTextTarget,
      'text': this.formPredicateTextTarget,
      'text::hasmany': this.formPredicateTextHasManyTarget,
      'integer': this.formPredicateNumberTarget,
      'float': this.formPredicateNumberTarget,
      'bigint': this.formPredicateNumberTarget,
      'boolean': this.formPredicateBooleanTarget,
      'datetime': this.formPredicateDateTimeTarget,
      'date': this.formPredicateDateTimeTarget
    }
    let selectedValue = $(this.formAttributeTarget).find('option:selected').attr('data-attr-type')
    if ($(this.formAttributeTarget).find('option:selected').attr('data-attr-has-many') == "HasMany") {
      selectedValue = `${selectedValue}::hasmany`
    }
    let predicateSelector = typeMap[selectedValue]

    $(this.formPredicateEnumTarget).prop("disabled", true).hide()
    $(this.formPredicateArrayTarget).prop("disabled", true).hide()
    $(this.formPredicateTextTarget).prop("disabled", true).hide()
    $(this.formPredicateTextHasManyTarget).prop("disabled", true).hide()
    $(this.formPredicateNumberTarget).prop("disabled", true).hide()
    $(this.formPredicateBooleanTarget).prop("disabled", true).hide()
    $(this.formPredicateDateTimeTarget).prop("disabled", true).hide()
    $(predicateSelector).prop("disabled", false).show()

    this.filterValueInputs()
  }

  filterValueInputs() {
    let isEnum = $(this.formAttributeTarget).find('option:selected').attr('data-attr-type') == "enum"
    let selectedValue = $(this.formPredicateTarget).parent().find('select:enabled').find('option:selected').text()
    $(this.formValueTargets).prop("disabled", true).hide()

    if (this.isValuelessPredicate(selectedValue)) {
      $(this.formValueBooleanTarget).prop("disabled", false).hide().val(1)
    } else if (isEnum) {
      let enumOpts = JSON.parse($(this.formAttributeTarget).find('option:selected').attr('data-attr-enums'))
      // Clear out existing options if set as this is a shared dropdown
      if ($(this.formValueEnumTarget).find('option').length != 0) {
        $(this.formValueEnumTarget).html("");
      }
      $.each(enumOpts, (text, val) => {
        // We want to use a special format for integer based enums so that when
        // the filter is summarized we can show the actual text value. We don't
        // need that if the value is a true postgres enum as they are
        // self-descriptive
        let optionVal = parseInt(val) ? `${val}::${text}` : val
        $(this.formValueEnumTarget).append($('<option></option>').val(optionVal).html(text))
      })
      this.isPopulated()
      $(this.formValueEnumTarget).prop("disabled", false).show().focus().val(this.searchValue)
    } else if (this.isDatePickerPredicate(selectedValue)) {
      $(this.formValueDateTimeTarget).prop("disabled", false).show().focus()
    } else if (this.isNumberPredicate(selectedValue)) {
      $(this.formValueNumberTarget).prop("disabled", false).show().focus()
    } else if (this.isRelativeTimePredicate(selectedValue)) {
      $(this.formValueNumberTarget).prop("disabled", false).show().focus()
    } else if (this.isTextPredicate(selectedValue)) {
      $(this.formValueTextTarget).prop("disabled", false).show().focus()
    }
  }

  processAttributeTypes() {
    $(this.formAttributeTarget).find('option').each((_index, option) => {
      let rawDataType, dataType, label, formattedLabel, secondary, secondaryLabel, value
      value = $(option).attr('value')

      if ($(option).text() != "") {
        [rawDataType, label] = $(option).text().split('|')

        if (!$.isEmptyObject(this.translationsValue)) {
          if (this.translationsValue[value]) {
            [label, secondaryLabel] = this.translationsValue[value].split('::')
          } else if ($(option).attr('selected')) {
            label = label;
          } else {
            $(option).remove()
            return true
          }
        }

        if (rawDataType) {
          [dataType, secondary] = rawDataType.split("::")
        }

        $(option).attr('data-attr-type', dataType.trim())

        if (dataType.trim() == "boolean" && label.slice(-1) != "?") {
          formattedLabel = `${label}?`
        } else {
          formattedLabel = label
        }

        if (dataType.trim() == "enum") {
          $(option).attr('data-attr-enums', secondary)
        }

        if (dataType.trim() == "text" && secondaryLabel) {
          $(option).attr('data-attr-has-many', secondaryLabel)
        }

        if (dataType.trim() == "array") {
          $(option).attr('data-attr-array-subtype', secondary)
        }

        $(option).text(`${formattedLabel} (${dataType.trim()})`)
      } else {
        $(option).text("Choose an attribute").prop("disabled", true).attr("label", "Choose an attribute")
      }
    })
  }

  collapseOptGroups() {
    if (!$.isEmptyObject(this.translationsValue) && !this.skipCollapseValue) {
      $(this.formAttributeTarget).find("optgroup").children().each((_index, elm) => {
        $(elm).parent().after(elm)
      })
      $(this.formAttributeTarget).find("optgroup").remove()
    }
  }

  sortOptgroups() {
    let originalValue = $(this.formAttributeTarget).val()
    $(this.formAttributeTarget).find("optgroup").each((index, elmt) => {
      let result = $(elmt).find("option").toArray().sort((option1, option2) => option1.innerHTML.localeCompare(option2.innerHTML))
      $(result).appendTo(elmt)
    })

    $(this.formAttributeTarget).val(originalValue)
  }

  isValuelessPredicate(predicate) {
    const valuelessPredicates = ['is not null', 'is null', 'is true', 'is false', 'is present']
    return valuelessPredicates.includes(predicate)
  }

  isNumberPredicate(predicate) {
    const numberPredicates = ['equals', 'not equal to', 'less than',
      'less than or equal to', 'greater than', 'greater than or equal to']
    return numberPredicates.includes(predicate)
  }

  isRelativeTimePredicate(predicate) {
    const relativeTimePredicates = ['is older than in days ago',
      'is newer than in days ago', 'is older than in hours ago',
      'is newer than in hours ago']
    return relativeTimePredicates.includes(predicate)
  }

  isTextPredicate(predicate) {
    const textPredicates = ["matches", "doesn't match", "contains", "doesn't contain",
      "starts with", "doesn't start with", "ends with", "doesn't end with",
      "is present", "is null", "has all items in list", "has any items in list"]
    return textPredicates.includes(predicate)
  }

  isDatePickerPredicate(predicate) {
    const datePredicates = ['is before', 'is after']
    return datePredicates.includes(predicate)
  }
}
