import { Controller } from "@hotwired/stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static values = { name: String, url: String, defaultMessage: String };
  static targets = [
    "switch",
    "options",
    "restrictions",
    "message",
    "notAllowed",
    "checkSelection",
    "agentMessage",
    "revertLink"
  ];

  connect() {
    if (!this.urlValue) {
      return; // Don't try to load tagify if no remote URL is specified
    }

    try {
      this.tagify = new Tagify(this.checkSelectionTarget, {
        id: this.nameValue,
        whitelist: [],
        maxTags: 10,
        dropdown: {
          maxItems: 20,
          classname: "tags-look",
          enabled: 0,
          closeOnSelect: false,
        },
        templates: {
          tag: (tagData) => {
            return `<tag title='${tagData.value}' contenteditable="false" spellcheck="false" tabindex="-1" class="tagify__tag tagify--noAnim" check_id="${tagData.check_id}" value='${tagData.value}'>
                      <x title="" class="tagify__tag__removeBtn" role="button" aria-label="remove tag"></x>
                      <div>
                        <img src="${tagData.url}" alt='${tagData.value}' height="24" width="24" />
                        <span class="tagify__tag-text">${tagData.value}</span>
                      </div>
                    </tag>`;
          },
          dropdownItem: (tagData) => {
            return `<div check_id="${tagData.check_id}" value='${tagData.value}' class="tagify__dropdown__item">
                      <div>
                        <img src="${tagData.url}" height="24" width="24" />
                        <span class="tagify__dropdown__item-text">${tagData.value}</span>
                      </div>
                    </div>`;
          },
        },
      });
    } catch (error) {
      console.error(`Unable to load check options for ${this.nameValue}`);
    }

    this.load();
  }

  load() {
    fetch(`${this.urlValue}?${new URLSearchParams({ for: this.nameValue })}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.tagify.whitelist = data;
      });
  }

  toggle(event) {
    const changeEvent = new Event('change');
    this.switchTarget.checked = !this.switchTarget.checked;
    this.switchTarget.dispatchEvent(changeEvent);
    this.optionsTarget.classList.toggle("hidden");
    this.notAllowedTarget.classList.toggle("hidden");
  }

  toggleRestrictions(event) {
    this.restrictionsTarget.classList.toggle("hidden");
  }

  toggleMessage(event) {
    this.messageTarget.classList.toggle("hidden");
  }

  resetMessage(event) {
    this.agentMessageTarget.value = this.defaultMessageValue;
    this.messageChanged(event);
  }

  previewMessage(event) {
    // Remove any existing params off the URL from previous previews
    var baseURL = event.srcElement.href.split("?")[0]

    // Change the URL, adding the parameter we need to preview
    event.srcElement.href = baseURL + "?preview="+encodeURIComponent(this.agentMessageTarget.value);
  }

  messageChanged(event) {
    if(this.agentMessageTarget.value == this.defaultMessageValue) {
      this.revertLinkTarget.classList.remove("section-check-selection--content-title-actions-action-revert");
      this.revertLinkTarget.classList.add("section-check-selection--content-title-actions-action-revert-disabled");
    } else {
      this.revertLinkTarget.classList.add("section-check-selection--content-title-actions-action-revert");
      this.revertLinkTarget.classList.remove("section-check-selection--content-title-actions-action-revert-disabled");
    }

  }
}
