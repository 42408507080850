import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  open(event) {
    event.preventDefault();

    if (window.HubSpotConversations) {
      let widget = window.HubSpotConversations.widget;
      widget.open();
    } else {
      window.location.href = "mailto:support@kolide.co";
    }
  }
}
