import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["header", "intersection"]

  connect() {
    this.observe();
  }

  observe() {
    const klass = "end-user-issue-details__header--floating"
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting === true) {
        this.headerTarget.classList.remove(klass)
      } else {
        this.headerTarget.classList.add(klass)
      }
    }, { rootMargin: "-18px 0px 0px 0px", threshold: 1 });

    intersectionObserver.observe(this.intersectionTarget);
  }
}
