import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.reattachErrorsToInput()
  }

  reattachErrorsToInput() {
    let errors = this.inputTarget.parentNode.querySelectorAll(".error")
    let errorNode = this.inputTarget.parentNode.parentNode.parentNode;

    // Add a class that will override the default behavior for checkboxes which prevents error messages from being shown
    errorNode.classList.add("show-errors")

    if (errors.length > 0) {
      errorNode.classList.add("field_with_errors", "input")
      errors.forEach(error => {
        errorNode.appendChild(error)
      })
    }
  }
}
