import { Controller } from "@hotwired/stimulus";
import { isVisible } from '../utils'

const isEmpty = el => el.value.trim() === ""
const isVisibleAndEmpty = el => isVisible(el) && isEmpty(el)

export default class extends Controller {
  static targets = ["form"];
  static values = {
    delay: { type: Number, default: 0 },
  }

  connect() {
    const nodes = this.formTarget.querySelectorAll('input, textarea')
    const firstEmptyField = Array.from(nodes).filter(isVisibleAndEmpty)[0]

    setTimeout(() => {
      firstEmptyField && firstEmptyField.focus()
    }, this.delayValue)
  }
}
