import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "checkCustomHelpResponseRadio",
    "checkCustomHelpResponseBox",
    "responseInput",
    "responseTestLink"
  ];

  connect() {
    this.updateResponseTestLink();

    if (this.hasCheckCustomHelpResponseRadioTarget) {
      this.checkCustomHelpResponseVisibility();
    }
  }

  checkCustomHelpResponseVisibility() {
    let value = this.checkCustomHelpResponseRadioTargets.find(r => r.checked).value
    if (value == "true") {
      this.checkCustomHelpResponseBoxTarget.style.display = "block"
    } else {
      this.checkCustomHelpResponseBoxTarget.style.display = "none"
    }
  }

  updateResponseTestLink() {
    if (this.hasResponseTestLinkTarget) {
      let value = this.responseInputTarget.value
      let href = new URL(this.responseTestLinkTarget.href)
      href.searchParams.set('body', value)
      this.responseTestLinkTarget.href = href.toString()
    }
  }
}
