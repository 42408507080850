import { Controller } from "@hotwired/stimulus"
import renderjson from "renderjson"
import { toggle } from '../../global/utils'

export default class extends Controller {
  static targets = ["container", "toggler"]
  static values = {
    json: Object,
  }

  initialize() {
    if (this.hasContainerTarget) {
      this.containerTarget.appendChild(renderjson.set_show_to_level(1)(this.jsonValue))
    }
  }

  toggle(event) {
    event.preventDefault()
    this.togglerTarget.classList.toggle("active")
    toggle(this.containerTarget)
  }
}
