import Shepherd from 'shepherd.js';
import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: false,
      confirmCancel: true,
      confirmCancelMessage: "Want to take the tour another time? (You can find it again by clicking your avatar in the upper-right of the app)",
      defaultStepOptions: {
        popperOptions: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 30],
              },
            },
          ],
        },
        modalOverlayOpeningPadding: 10,
        modalOverlayOpeningRadius: 5,
        cancelIcon: {
          enabled: true
        },
        scrollTo: { behavior: 'smooth', block: 'center' }
      }
    });

    ['start', 'close', 'cancel', 'complete'].forEach(event => tour.on(event, () => {
      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        },
      })
   }));

    tour.addStep({
      id: 'welcome',
      title: 'Welcome to Kolide!',
      text: `<p>Since it's your first time using the app, let's take two minutes to run you through the navigation.</p>`,
      classes: "tour tour--nav",
      attachTo: {
        element: '.app-nav',
        on: 'bottom'
      },
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 30],
            },
          },
        ],
      },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'devices',
      title: 'Devices',
      text: `<p>Here you can review of all your enrolled devices. Click into any of them to get a detailed breakdown of their installed software, configured system settings, and more!</p> <p class="tour__tip">Tip: Did you know <a href="/x/settings/my/downloads" target="_blank">you can add</a> macOS, Windows, and Linux devices to Kolide?</p>`,
      classes: "tour tour--devices",
      attachTo: {
        element: '.app-nav__item-link--devices',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'people',
      title: 'People',
      text: `<p>Here you can invite new team members and automatically import your end-users from Slack and Google.</p> <p class="tour__tip">Tip: <a href="/x/settings/admin/k2_identity_providers" target="_blank">Adding people via Identity Providers</a> will improve Kolide's automatic user to device association.</p>`,
      classes: "tour tour--people",
      attachTo: {
        element: '.app-nav__item-link--people',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'checks',
      title: 'Checks',
      text: `<p>Checks give you a health report of your security and compliance goals across your entire fleet.</p> <p class="tour__tip">Tip: Use the <a href="/x/checks/catalog" target="_blank">Check Catalog</a> to discover and add new Checks not enabled by default.</p>`,
      classes: 'tour tour--checks',
      attachTo: {
        element: '.app-nav__item-link--checks',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'issues',
      title: 'Issues',
      text: `<p>View and investigate a list of all problems Kolide has detected across your devices.</p> <p class="tour__tip">Tip: <a href="/x/checks/tags" target="_blank">You can tag Checks</a> to make it easier to find high priority issues.</p>`,
      classes: 'tour tour--issues',
      attachTo: {
        element: '.app-nav__item-link--issues',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'onboarding',
      title: 'Slack Onboarding',
      text: `<p>Use Slack to onboard new users into Kolide to enable self remediation.</p> <p class="tour__tip">Tip: You can read more about this style of remediation in our <a href="https://honest.security" target="_blank">Honest Security guide</a>.`,
      classes: 'tour tour--onboarding',
      attachTo: {
        element: '.app-nav__item-link--onboarding',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'tools',
      title: 'Tools',
      text: `<p>Advanced users can extend the data collection capabilities of Kolide using Live Query, The Log Pipeline, and the Reporting Database.</p> <p class="tour__tip">Tip: You can build your own <a href="https://kolidek2.readme.io/reference/get_reporting-queries" target="_blank">API Endpoints in the Reporting Database!</p>`,
      classes: 'tour tour--tools',
      attachTo: {
        element: '.app-nav__item-link--tools',
        on: 'bottom-start'
      },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });

    tour.start();
  }
}
