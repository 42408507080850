import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hide" ];

  hide() {
    if (this.hasHideTarget) {
      this.hideTarget.style.display = "none";
    } else {
      this.element.style.display = "none";
    }
  }
}
