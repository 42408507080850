import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "warningBox",
    "infoBox",
  ];

  connect() {
    this.setBoxVisibility();
  }

  setBoxVisibility(event = null) {
    let selectedOption = this.inputTargets.find(radio => radio.checked).value
    if (selectedOption == "none") {
      // First input target is always "none"
      this.inputTarget.parentNode.parentNode.after(this.warningBoxTarget);
      this.warningBoxTarget.style.display = "block";

      if (this.hasInfoBoxTarget) {
        this.infoBoxTarget.style.display = "none";
      }
    } else if (selectedOption == "tofu") {
      if (this.hasInfoBoxTarget && event) {
        event.target.parentNode.parentNode.after(this.infoBoxTarget);
        this.infoBoxTarget.style.display = "block";
      }
      this.warningBoxTarget.style.display = "none";
    }
  }

  modeChange(event) {
    this.setBoxVisibility(event);
  }
}
