import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "showText",
    "hideText",
    "table"
  ];
  static visible;

  connect() {
    if (this.hasTableTarget && this.hasShowTextTarget && this.hasHideTextTarget) {
      this.hide();
    }
  }

  toggle() {
    if (this.visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.visible = true;
    this.tableTarget.style.display = "block";
    this.hideTextTarget.style.display = "block";
    this.showTextTarget.style.display = "none";
  }

  hide() {
    this.visible = false;
    this.showTextTarget.style.display = "block";
    this.tableTarget.style.display = "none";
    this.hideTextTarget.style.display = "none";
  }
}
