import { Controller } from "@hotwired/stimulus";
import Rails from '@rails/ujs';

export default class extends Controller {
  showSlackConfigModal(event){
    event.StopPropogation()
    let url = event.target.attributes.href
    Rails.handle_remote(e)
  }
}
