import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "toggleButton"]
  static values = {
    alternateText: String,
    primaryText: String,
  }

  toggleVisibility() {
    if (this.inputTarget.type == 'password') {
      this.inputTarget.type = 'text';
    } else {
      this.inputTarget.type = 'password';
    }
    this.updateToggleButtonText()
  }

  updateToggleButtonText() {
    let alternateText = this.alternateTextValue
    let primaryText = this.primaryTextValue

    if (this.toggleButtonTarget.textContent == this.primaryTextValue) {
      this.toggleButtonTarget.textContent = alternateText
    } else {
      this.toggleButtonTarget.textContent = primaryText
    }
  }
}
